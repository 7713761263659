import React, { useState } from 'react';
import styled from '@emotion/styled';
import { CDBDatePicker } from 'cdbreact-pro';

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin: 10px;
  }
`;

export const DatePickerExample1 = () => {
  const [value, onChange] = useState(new Date());

  return (
    <Container>
      <CDBDatePicker onChange={onChange} value={value} color="primary" />
    </Container>
  );
};
