import styled from '@emotion/styled';

export const StyledHeading = styled('h1')`
  font-size: 32px;
  line-height: 1.5;
  font-weight: 700;
  border-left: 2px solid #3329e0;
  padding: 0 16px;
  flex: 1;
  margin-top: 0;
  padding-top: 0;
  color: ${props => props.theme.colors.heading};
`;

export const Edit = styled('div')`
  padding: 1rem 1.5rem;
  text-align: right;

  a:not(.footer-link) {
    font-size: 14px;
    font-weight: 500;
    line-height: 1em;
    text-decoration: none;
    color: #555;
    border: 1px solid rgb(211, 220, 228);
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.2s ease-out 0s;
    text-decoration: none;
    color: rgb(36, 42, 49);
    // background-color: rgb(255, 255, 255);
    box-shadow: rgba(116, 129, 141, 0.1) 0px 1px 1px 0px;
    height: 30px;
    padding: 5px 16px;
    &:hover {
      // background-color: rgb(245, 247, 249);
    }
  }
`;

export const StyledMainWrapper = styled.div`
  max-width: 900px;
  color: ${props => props.theme.colors.text};

  ul:not(.suggestions, .fWgsSs),
  ol {
    -webkit-padding-start: 40px;
    -moz-padding-start: 40px;
    -o-padding-start: 40px;
    margin: 24px 0px;
    padding: 0px 0px 0px 2em;

    li {
      font-size: 16px;
      line-height: 1.8;
      font-weight: 400;
      list-style: disc;
    }
  }

  a:not(.bFRUJA a) {
    transition: color 0.15s;
    color: ${props => props.theme.colors.link};
  }

  a:has(img[alt="NPM"]), 
  a:has(img[alt="JavaScript Style Guide"]),
  a:has(img[alt="JavaScript Style Guide"]) +  img[alt="npm"]{
    display: inline-flex;
    vertical-align: top;
  }

  code {
    padding: 1px;
    font-size: 12px;
    
    // border: 1px solid #ede7f3;
    // border-radius: 4px;
    // background: ${props => props.theme.colors.background};
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;

export const GitHubBtn = styled.div`
  .edit-on-github {
    border-radius: 6px;
    padding: 4px 10px;
    background: transparent !important;
    border-radius: 0.375rem;
    font-weight: 300;
    font-size: 12px;
    border: 1px solid ${props => props.theme.codeBlocks.editOnGithub.color};
    color: ${props => props.theme.codeBlocks.editOnGithub.color} !important;
    margin: 0 5px;
    a {
      text-decoration: none;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
`;

export const StyledMainWrapperBlog = styled.div`
  ul:not(.suggestions, .fWgsSs),
  ol {
    -webkit-padding-start: 40px;
    -moz-padding-start: 40px;
    -o-padding-start: 40px;
    margin: 24px 0px;
    padding: 0px 0px 0px 2em;

    li {
      font-size: 16px;
      line-height: 1.8;
      font-weight: 400;
      list-style: disc;
    }
  }

  a:not(.bFRUJA a) {
    transition: color 0.15s;
    color: blue;
  }

  code {
    padding: 1px;
    font-size: 12px;

    // color: #fff;
    // max-width: 750px;
    // border: 1px solid #ede7f3;
    // border-radius: 4px;
    // background: #001933;
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;
