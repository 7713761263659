import React from 'react';

export const JsBadges1 = () => {
  return (
    <button class="btn btn-dark">
      Notifications
      <span class="badge bg-light text-dark ms-2">4</span>
      <span class="badge bg-primary">Primary</span>
      <span class="badge bg-secondary">Secondary</span>
      <span class="badge bg-success">Success</span>
      <span class="badge bg-danger">Danger</span>
      <span class="badge bg-warning text-dark">Warning</span>
      <span class="badge bg-info">Info</span>
      <span class="badge bg-light text-dark">Light</span>
      <span class="badge bg-dark text-light">Dark</span>
    </button>
  );
};

export const JsBadges2 = () => {
  return (
    <div>
      <span class="badge badge-pill bg-primary">Primary</span>
      <span class="badge badge-pill bg-secondary">Secondary</span>
      <span class="badge badge-pill bg-success">Success</span>
      <span class="badge badge-pill bg-danger">Danger</span>
      <span class="badge badge-pill bg-warning text-dark">Warning</span>
      <span class="badge badge-pill bg-info">Info</span>
      <span class="badge badge-pill bg-light text-dark">Light</span>
      <span class="badge badge-pill bg-dark text-light">Dark</span>
    </div>
  );
};

export const JsBadges3 = () => {
  return (
    <div>
      <span class="badge badge-lg bg-primary">Large</span>
      <span class="badge bg-secondary">Normal</span>
      <span class="badge badge-sm bg-success">Small</span>
    </div>
  );
};

export const JsBadges4 = () => {
  return (
    <div>
      <span class="badge badge-flat bg-dark">Flat Badge Dark</span>
      <span class="badge badge-flat bg-primary">Flat Badge Primary</span>
      <span class="badge badge-flat bg-secondary">Flat Badge Secondary</span>
      <span class="badge badge-flat bg-success">Flat Badge Success</span>
      <span class="badge badge-flat bg-danger">Flat Badge Danger</span>
      <span class="badge badge-flat bg-warning">Flat Badge Warning</span>
      <span class="badge badge-flat bg-info">Flat Badge Info</span>
    </div>
  );
};

export const JsBadges5 = () => {
  return (
    <div>
      <span class="badge bg-primary">
        <i class="fa fa-facebook"></i>
      </span>
      <span class="badge bg-secondary">
        <i class="fa fa-snapchat-ghost"></i>
      </span>
      <span class="badge bg-success">
        <i class="fa fa-anchor"></i>
      </span>
      <span class="badge bg-danger">
        <i class="fa fa-twitter"></i>
      </span>
      <span class="badge bg-warning text-dark">
        <i class="fa fa-bomb"></i>
      </span>
      <span class="badge bg-info">
        <i class="fa fa-heart"></i>
      </span>
      <span class="badge bg-light text-dark">
        <i class="fa fa-btc"></i>
      </span>
      <span class="badge bg-dark text-light">
        <i class="fa fa-apple"></i>
      </span>
    </div>
  );
};
