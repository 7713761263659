import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
`;

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarSubMenu,
  CDBSidebarFooter,
  CDBSidebarCTA,
  CDBBadge,
  CDBIcon,
} from 'cdbreact-pro';

export const SidebarExample1 = () => {
  return (
    <Container>
      <CDBSidebar>
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>Contrast</CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Components</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="credit-card" iconType="solid">
              Metrics
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 5px',
              fontStyle: 'italics',
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};

export const SidebarExample2 = () => {
  return (
    <Container>
      <CDBSidebar textColor="#333" backgroundColor="#f0f0f0">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
          Contrast Light Mode
        </CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Components</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="chart-line" iconType="solid">
              Metrics
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 5px',
              fontStyle: 'italics',
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};

export const SidebarExample3 = () => {
  return (
    <Container>
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<CDBIcon icon="bars" size="lg" />}>With Badges</CDBSidebarHeader>

        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem
              suffix={
                <CDBBadge color="danger" size="small" borderType="pill">
                  pro
                </CDBBadge>
              }
              icon="th-large"
            >
              Dashboard
            </CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note" suffix={<CDBBadge>4</CDBBadge>}>
              Components
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 5px',
              fontStyle: 'italics',
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};

export const SidebarExample4 = () => {
  return (
    <Container>
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<CDBIcon icon="bars" size="lg" />}>
          Contrast Multi Level
        </CDBSidebarHeader>

        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem
              suffix={
                <CDBBadge color="danger" size="small" borderType="pill">
                  new
                </CDBBadge>
              }
              icon="th-large"
            >
              Dashboard
            </CDBSidebarMenuItem>
            <CDBSidebarMenuItem
              icon="sticky-note"
              suffix={
                <CDBBadge color="danger" size="small" borderType="pill">
                  new
                </CDBBadge>
              }
            >
              Components
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
          <CDBSidebarMenu>
            <CDBSidebarSubMenu title="Sidemenu" icon="th">
              <CDBSidebarMenuItem> submenu 1</CDBSidebarMenuItem>
              <CDBSidebarMenuItem> submenu 2</CDBSidebarMenuItem>
              <CDBSidebarMenuItem> submenu 3</CDBSidebarMenuItem>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu
              title="Sidemenu2"
              icon="book"
              suffix={
                <CDBBadge color="danger" size="small" borderType="pill">
                  new
                </CDBBadge>
              }
            >
              <CDBSidebarMenuItem>submenu 1</CDBSidebarMenuItem>
              <CDBSidebarMenuItem>submenu 2</CDBSidebarMenuItem>
              <CDBSidebarMenuItem>submenu 3</CDBSidebarMenuItem>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="MultiLevel with Icon" icon="table">
              <CDBSidebarMenuItem>submenu 1 </CDBSidebarMenuItem>
              <CDBSidebarMenuItem>submenu 2 </CDBSidebarMenuItem>
              <CDBSidebarSubMenu title="submenu 3">
                <CDBSidebarMenuItem>submenu 3.1 </CDBSidebarMenuItem>
                <CDBSidebarMenuItem>submenu 3.2 </CDBSidebarMenuItem>
                <CDBSidebarSubMenu title="subnt">
                  <CDBSidebarMenuItem>submenu 3.3.1 </CDBSidebarMenuItem>
                  <CDBSidebarMenuItem>submenu 3.3.2 </CDBSidebarMenuItem>
                  <CDBSidebarMenuItem>submenu 3.3.3 </CDBSidebarMenuItem>
                </CDBSidebarSubMenu>
              </CDBSidebarSubMenu>
            </CDBSidebarSubMenu>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 5px',
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};

export const SidebarExample5 = () => {
  return (
    <Container>
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>With CTA</CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Components</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="credit-card" iconType="solid" textFontSize="14px">
              Sales
            </CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="gamepad" iconType="solid" textFontSize="14px">
              Games
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarCTA
          mode="dark"
          text="This sidebar contains a CTA component. You can add anything you want here"
        ></CDBSidebarCTA>
        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 5px',
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};

export const SidebarExample6 = () => {
  return (
    <Container>
      <CDBSidebar textColor="#333" backgroundColor="#f0f0f0">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
          <div className="container" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={'https://seeklogo.com/images/B/butterfly-logo-0A00378822-seeklogo.com.png'}
              alt=""
              style={{ width: '30px' }}
            />
            <h6 className="ms-2">BABYCARE ™</h6>
          </div>
        </CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Components</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="chart-line" iconType="solid">
              Metrics
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </Container>
  );
};
export const SidebarExample7 = () => {
  return (
    <Container>
      <CDBSidebar textColor="#333" backgroundColor="#f4f4f4">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>BABYCARE ™</CDBSidebarHeader>

        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large" textFontSize="14px">
              Dashboard
            </CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note" textFontSize="14px">
              Explore
            </CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="credit-card" iconType="solid" textFontSize="14px">
              Care Products
            </CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="gamepad" iconType="solid" textFontSize="14px">
              Fun and Games
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
          <CDBSidebarMenu>
            <CDBSidebarSubMenu title="Store" icon="shopping-bag">
              <CDBSidebarMenuItem>Food </CDBSidebarMenuItem>
              <CDBSidebarMenuItem>Clothes </CDBSidebarMenuItem>
              <CDBSidebarSubMenu title="Accessories">
                <CDBSidebarMenuItem>Fitbit</CDBSidebarMenuItem>
                <CDBSidebarMenuItem>Cardio</CDBSidebarMenuItem>
                <CDBSidebarSubMenu title="Tickets">
                  <CDBSidebarMenuItem>Counselling</CDBSidebarMenuItem>
                  <CDBSidebarMenuItem>Postnatal</CDBSidebarMenuItem>
                  <CDBSidebarMenuItem>Yoga</CDBSidebarMenuItem>
                </CDBSidebarSubMenu>
              </CDBSidebarSubMenu>
            </CDBSidebarSubMenu>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarCTA theme="overlay" image={<Image />} text="Sign up Pro Here"></CDBSidebarCTA>
        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 5px',
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};

const Image = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g id="freepik--background-simple--inject-39">
        <path
          d="M323.56,74.85c-45.83-41.37-110-56.45-169.13-38.52-13.79,4.19-27.26,10.15-39.17,18.54C60.82,93.26,92.18,136.26,70.59,163S5.69,245.39,32.91,292.85s78.16,44,104,82.35,83.05,106.08,138.88,90,78.16-62.11,135.39-78.86,92.12-81,71.18-154.93-64.9-61.42-99.09-97.71C367.48,117,345.49,94.64,323.56,74.85Z"
          style={{ fill: '#92E3A9' }}
        ></path>
        <path
          d="M323.56,74.85c-45.83-41.37-110-56.45-169.13-38.52-13.79,4.19-27.26,10.15-39.17,18.54C60.82,93.26,92.18,136.26,70.59,163S5.69,245.39,32.91,292.85s78.16,44,104,82.35,83.05,106.08,138.88,90,78.16-62.11,135.39-78.86,92.12-81,71.18-154.93-64.9-61.42-99.09-97.71C367.48,117,345.49,94.64,323.56,74.85Z"
          style={{ fill: '#fff;opacity:0.7000000000000001' }}
        ></path>
      </g>
      <g id="freepik--Moon--inject-39">
        <path
          d="M354.46,64.52a63.15,63.15,0,0,0-19.35,3A63.15,63.15,0,0,1,322,181.81,63.14,63.14,0,1,0,354.46,64.52Z"
          style={{ fill: '#fff' }}
        ></path>
        <path
          d="M354.46,191.3a63.57,63.57,0,0,1-32.74-9.06.52.52,0,0,1-.24-.49.54.54,0,0,1,.35-.42A62.65,62.65,0,0,0,334.85,68a.49.49,0,0,1,.11-.9,63.64,63.64,0,1,1,19.5,124.22Zm-31.28-9.36A62.64,62.64,0,1,0,336.31,67.7a63.65,63.65,0,0,1-13.13,114.24Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M373.32,117.58c0,2.28-.78,4.12-1.75,4.12s-1.75-1.84-1.75-4.12.69-1.37,1.66-1.37S373.32,115.3,373.32,117.58Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M371.57,122.2c-1.46,0-2.25-2.38-2.25-4.62,0-1.06.14-1.58.5-1.86a1.24,1.24,0,0,1,1.13-.1,2.32,2.32,0,0,0,1.08,0,1.39,1.39,0,0,1,1.26.12c.37.29.53.84.53,1.83C373.82,119.82,373,122.2,371.57,122.2Zm-1.18-5.67s-.07.24-.07,1c0,2.18.75,3.62,1.25,3.62s1.25-1.44,1.25-3.62c0-.89-.15-1-.15-1a2.54,2.54,0,0,0-.42.07,3.63,3.63,0,0,1-1.54,0c-.11,0-.25-.06-.31-.06Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M388.53,118.15c0,2.27-.78,4.12-1.75,4.12s-1.75-1.85-1.75-4.12.3-1.48,1.24-1.24C387.78,117.28,388.53,116.78,388.53,118.15Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M386.78,122.77c-1.46,0-2.25-2.38-2.25-4.62,0-1.18.06-1.72.45-2a1,1,0,0,1,1,.07,1.93,1.93,0,0,0,.45.17,6.08,6.08,0,0,0,1.06.16c.74.07,1.58.15,1.58,1.57C389,120.44,388.25,122.77,386.78,122.77Zm-1.21-5.59a8.9,8.9,0,0,0,0,1c0,2.17.75,3.62,1.25,3.62s1.24-1.41,1.25-3.63h0c0-.5,0-.5-.67-.56a7.73,7.73,0,0,1-1.21-.19A3,3,0,0,1,385.57,117.18Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M399.82,121c-3.3,23.51-25.87,19.28-25.87,19.28s1.88,1.41-1.88,10.35"
          style={{ fill: '#fff' }}
        ></path>
        <path
          d="M372.07,151.1a.65.65,0,0,1-.19,0,.5.5,0,0,1-.27-.66c3.44-8.16,2-9.76,2-9.77a.47.47,0,0,1-.14-.59.5.5,0,0,1,.56-.28c.1,0,9.71,1.73,17.07-3.56,4.48-3.23,7.24-8.38,8.21-15.3a.51.51,0,0,1,.57-.43.5.5,0,0,1,.42.57c-1,7.21-3.91,12.58-8.63,16-6.41,4.61-14.2,4.19-16.88,3.87.31,1.29.19,4.07-2.27,9.91A.51.51,0,0,1,372.07,151.1Z"
          style={{ fill: '#263238' }}
        ></path>
        <line x1="396.05" y1="119.56" x2="404.05" y2="121.44" style={{ fill: '#fff' }}></line>
        <path
          d="M404.05,121.94h-.11l-8-1.89a.5.5,0,1,1,.23-1l8,1.88a.5.5,0,0,1-.11,1Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M366,111.59h-.1a.51.51,0,0,1-.4-.59c0-.07,1.56-7.43,8-7.92a.5.5,0,1,1,.08,1c-5.66.43-7,6.85-7.07,7.12A.5.5,0,0,1,366,111.59Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M395.11,111.59a.49.49,0,0,1-.4-.21c-5.52-7.63-12.57-5.93-12.64-5.92a.5.5,0,0,1-.25-1c.08,0,7.79-1.87,13.7,6.31a.51.51,0,0,1-.11.7A.53.53,0,0,1,395.11,111.59Z"
          style={{ fill: '#263238' }}
        ></path>
        <polyline
          points="379.12 118.62 378.18 133.2 382.42 128.49"
          style={{ fill: '#fff' }}
        ></polyline>
        <path
          d="M378.18,133.7a.65.65,0,0,1-.19,0,.51.51,0,0,1-.31-.5l.95-14.58a.51.51,0,0,1,.53-.46.49.49,0,0,1,.46.53l-.85,13.14,3.27-3.63a.5.5,0,1,1,.75.67l-4.24,4.7A.48.48,0,0,1,378.18,133.7Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M385.24,68.76s-15.05-4.7-29.16-10.34-27.75.47-28.69,3.76-2.82,14.58-7.05,20.69.94,5.65,4.23,3.77S336.8,72.53,336.8,72.53s5.17-2.82,9.4,8.93Z"
          style={{ fill: '#92E3A9' }}
        ></path>
        <path
          d="M320.53,88.39a2.4,2.4,0,0,1-1.84-.66c-.88-1-.46-2.69,1.23-5.14,3.32-4.8,5.18-12.84,6.28-17.64.29-1.24.51-2.23.71-2.91.33-1.16,1.88-2.5,4.23-3.65,5.43-2.67,15.27-4.38,25.12-.44,13.93,5.58,29,10.29,29.13,10.34a.5.5,0,0,1,.35.47.5.5,0,0,1-.35.48l-39,12.7a.5.5,0,0,1-.63-.31c-2.16-6-4.52-8-6.12-8.63a3.37,3.37,0,0,0-2.49-.07c-.94,1.27-9.08,12.3-12.3,14.14A9.67,9.67,0,0,1,320.53,88.39Zm23.56-31.76a29.28,29.28,0,0,0-12.51,2.65c-2.44,1.2-3.51,2.36-3.71,3s-.41,1.62-.69,2.85c-1.13,4.88-3,13-6.43,18-1.32,1.9-1.81,3.36-1.32,3.9s2.91.28,4.89-.86c3.16-1.8,12-13.85,12.07-14a.46.46,0,0,1,.17-.14,4.19,4.19,0,0,1,3.37,0c2.55,1,4.76,3.92,6.58,8.78l37.1-12.07c-4.15-1.33-16.31-5.32-27.72-9.89A31.69,31.69,0,0,0,344.09,56.63Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M386.15,65.48a40.5,40.5,0,0,0-36.26,9.89c-3.11,2.83-5.79,5.67-5.79,7,0,7.38,11.64,12.14,14.46,12.61S365,85.23,381,81.46c15.74-3.7,18.72.8,18.81.94.51.8,1.77-.72,1.93-1,1.4-2.35-2.46-7-3.88-8.7A21.91,21.91,0,0,0,386.15,65.48Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M358.75,95.5l-.27,0c-2.63-.44-14.88-5.25-14.88-13.11,0-.83.62-2.5,5.95-7.37a41,41,0,0,1,36.71-10h0a22.52,22.52,0,0,1,12,7.39c2.57,3.08,5.33,6.92,3.93,9.28,0,0-1,1.46-1.95,1.47a1,1,0,0,1-.83-.46s-3-4.32-18.27-.72a35.2,35.2,0,0,0-18.58,10.82C361,94.39,359.87,95.5,358.75,95.5ZM377.46,65a40.49,40.49,0,0,0-27.24,10.74c-5.07,4.63-5.62,6.18-5.62,6.63,0,7.12,11.66,11.72,14,12.12.7.11,1.74-.95,3.18-2.42A36.14,36.14,0,0,1,380.89,81c15.76-3.71,19,.64,19.35,1.15h0c.21,0,.9-.69,1.08-1,.8-1.36-.49-4.1-3.85-8.13A21.42,21.42,0,0,0,386,66h0A37.67,37.67,0,0,0,377.46,65Z"
          style={{ fill: '#263238' }}
        ></path>
        <circle cx="319.8" cy="92.22" r="7.38" style={{ fill: '#263238' }}></circle>
        <path
          d="M319.8,100.1a7.89,7.89,0,1,1,7.88-7.88A7.9,7.9,0,0,1,319.8,100.1Zm0-14.77a6.89,6.89,0,1,0,6.88,6.89A6.9,6.9,0,0,0,319.8,85.33Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M366.9,116.68a.49.49,0,0,1-.5-.49.5.5,0,0,1,.48-.51l8.52-.2a.51.51,0,0,1,.52.48.52.52,0,0,1-.49.52l-8.52.2Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M392.45,118.34h-.09L383,116.67a.48.48,0,0,1-.4-.57.5.5,0,0,1,.58-.41l9.35,1.66a.5.5,0,0,1-.09,1Z"
          style={{ fill: '#263238' }}
        ></path>
      </g>
      <g id="freepik--Cloud--inject-39">
        <path
          d="M382.11,295.87c-1.07-.11-2.15-.21-3.22-.28-7.1-20.64-27.21-36.8-52.39-39.59-21.21-2.34-41,5.34-53.51,18.93a45,45,0,0,0-13.77-18.69,51.72,51.72,0,0,0-27.1-11.2,52.82,52.82,0,0,0-21,1.86c-1.4.16-2.83.38-4.25.65-18.34,3.56-32.42,16-37.69,31.22A67.81,67.81,0,0,0,159.7,277c-33.65-3.73-63.64,17.81-67,48.1a50.3,50.3,0,0,0,1.93,20.32,40.52,40.52,0,0,0-9,19.14c-3.91,21.79,10.25,42.57,31.63,46.41a38.59,38.59,0,0,0,20.25-1.8,70.57,70.57,0,0,0,117.2,22,51.23,51.23,0,0,0,21.48,9.25,51.8,51.8,0,0,0,42.62-11.54,69,69,0,0,0,14-1.24,66,66,0,0,0,31.29-15.31,38.53,38.53,0,0,0,12.07,4.32c20.93,3.76,41-10.13,45.52-31.17a41.48,41.48,0,0,0,15.06-26.05c.09-.65.18-1.31.25-2C440.33,327.16,415.76,299.59,382.11,295.87Z"
          style={{ fill: '#fff' }}
        ></path>
        <path
          d="M203,454.13a71,71,0,0,1-65.77-44.3,39.23,39.23,0,0,1-45.68-15,40.69,40.69,0,0,1-6.41-30.31,41.13,41.13,0,0,1,9-19.17,50.51,50.51,0,0,1-1.88-20.26c1.64-14.79,9.57-28,22.34-37.15a65,65,0,0,1,45.21-11.39,68.44,68.44,0,0,1,9.1,1.64c5.64-15.75,20.12-27.65,37.91-31.11,1.4-.27,2.84-.49,4.3-.65a53.44,53.44,0,0,1,48.47,9.44A45.86,45.86,0,0,1,273.17,274c13.15-13.84,33-20.74,53.38-18.5,24.56,2.71,45.21,18.25,52.7,39.61,1,.06,1.95.15,2.92.25h0C416,299.12,440.85,327,437.48,357.5c-.07.67-.16,1.33-.26,2a41.86,41.86,0,0,1-15.1,26.26c-4.67,21.41-24.87,35.19-46.06,31.38a38.81,38.81,0,0,1-11.92-4.21,66.37,66.37,0,0,1-31.25,15.2,69.8,69.8,0,0,1-13.9,1.25A52.34,52.34,0,0,1,276.1,440.9a51.49,51.49,0,0,1-21.33-9.08A71.66,71.66,0,0,1,203,454.13ZM137.51,408.7a.51.51,0,0,1,.47.31,70.07,70.07,0,0,0,116.37,21.8.5.5,0,0,1,.66-.06,50.42,50.42,0,0,0,21.27,9.17,51.3,51.3,0,0,0,42.2-11.44.59.59,0,0,1,.33-.11,68.24,68.24,0,0,0,13.89-1.24,65.4,65.4,0,0,0,31.05-15.19.5.5,0,0,1,.57-.06,38.18,38.18,0,0,0,11.91,4.27c20.72,3.72,40.46-9.81,44.95-30.79a.46.46,0,0,1,.17-.28,40.83,40.83,0,0,0,14.88-25.74c.1-.64.19-1.29.26-1.95,3.31-30-21.11-57.33-54.43-61-1.07-.12-2.14-.21-3.21-.28a.51.51,0,0,1-.44-.34c-7.27-21.15-27.67-36.56-52-39.25-20.31-2.24-40.15,4.77-53.08,18.77a.47.47,0,0,1-.46.15.49.49,0,0,1-.37-.31,44.64,44.64,0,0,0-13.62-18.48,51.07,51.07,0,0,0-26.84-11.09,51.89,51.89,0,0,0-20.8,1.84c-1.52.18-2.94.4-4.31.66-17.6,3.42-31.9,15.26-37.3,30.89a.5.5,0,0,1-.6.32,67,67,0,0,0-9.41-1.72,64,64,0,0,0-44.52,11.2c-12.53,9-20.32,21.95-21.92,36.45a49.51,49.51,0,0,0,1.91,20.12.5.5,0,0,1-.09.47,40.18,40.18,0,0,0-8.91,18.91,39.73,39.73,0,0,0,6.25,29.57,38.24,38.24,0,0,0,45,14.48Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M125.68,318.06a.48.48,0,0,1-.41-.21.5.5,0,0,1,.12-.7c7.11-5,16.67-7.12,26.23-5.85a.51.51,0,0,1,.43.56.5.5,0,0,1-.56.43c-9.31-1.24-18.61.83-25.53,5.68A.47.47,0,0,1,125.68,318.06Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M118.18,325.77a.43.43,0,0,1-.27-.08.49.49,0,0,1-.15-.69,28,28,0,0,1,3-3.88.5.5,0,0,1,.73.69,26.63,26.63,0,0,0-2.92,3.73A.51.51,0,0,1,118.18,325.77Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M312.59,334.76a.45.45,0,0,1-.27-.08.5.5,0,0,1-.14-.69c10.66-15.95,32.68-24.07,56.09-20.67a.5.5,0,0,1-.14,1c-23-3.34-44.67,4.6-55.12,20.23A.48.48,0,0,1,312.59,334.76Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M277.7,347.71a.48.48,0,0,1-.26-.07c-1.46-.88-3-1.73-4.47-2.53a.5.5,0,1,1,.47-.88c1.52.8,3,1.66,4.52,2.56a.49.49,0,0,1,.16.68A.47.47,0,0,1,277.7,347.71Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M201.71,345.55a.51.51,0,0,1-.42-.23.5.5,0,0,1,.14-.69c18.78-12.42,44.1-13.36,67.72-2.52a.52.52,0,0,1,.25.67.5.5,0,0,1-.67.24c-23.31-10.7-48.26-9.79-66.75,2.44A.46.46,0,0,1,201.71,345.55Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M342.57,310.36a.53.53,0,0,1-.27-.08.51.51,0,0,1-.15-.7C348.73,299.4,362,294,376,295.88a.5.5,0,0,1,.43.56.51.51,0,0,1-.56.44c-13.61-1.81-26.51,3.39-32.89,13.25A.49.49,0,0,1,342.57,310.36Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M152.12,413.3c-11.91,0-22.69-5.22-28.41-14.06a.5.5,0,0,1,.15-.69.49.49,0,0,1,.69.15c6.38,9.85,19.29,15.05,32.89,13.25a.49.49,0,0,1,.56.43.5.5,0,0,1-.43.56A42,42,0,0,1,152.12,413.3Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M383.1,404.53a.51.51,0,0,1-.41-.2.5.5,0,0,1,.12-.7c1-.7,1.95-1.45,2.89-2.22a.5.5,0,0,1,.7.08.49.49,0,0,1-.07.7c-.95.78-1.94,1.54-2.94,2.25A.52.52,0,0,1,383.1,404.53Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M354.62,414.34a35.42,35.42,0,0,1-15.18-3.27.5.5,0,1,1,.43-.9c11.26,5.31,25.75,3.89,38.76-3.82a.51.51,0,0,1,.69.18.49.49,0,0,1-.18.68A48.57,48.57,0,0,1,354.62,414.34Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M280.77,423.07a.5.5,0,0,1-.45-.28.51.51,0,0,1,.22-.67c2-1,3.94-2.08,5.83-3.23a.5.5,0,0,1,.52.86C285,420.91,283,422,281,423A.59.59,0,0,1,280.77,423.07Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M245,431.9a62.46,62.46,0,0,1-34.66-10,.5.5,0,1,1,.55-.83c17.91,11.85,42.2,13.06,65,3.23a.5.5,0,1,1,.39.92A79.15,79.15,0,0,1,245,431.9Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M399.73,395.28a.5.5,0,0,1,0-1c11.72-.6,22.67-9.19,27.9-21.88a.5.5,0,0,1,.93.38c-5.38,13.05-16.67,21.88-28.78,22.5Z"
          style={{ fill: '#263238' }}
        ></path>
      </g>
      <g id="freepik--Character--inject-39">
        <path
          d="M137.21,307.61s10.59-23.84,47.63-21.41c0,0,5.82,13.81,9.32,19.46s13.32,13.13,13.32,13.13-10,3.89-14.94,4.33-37.58-10.74-45.75-10.8S137.21,307.61,137.21,307.61Z"
          style={{ fill: '#fff' }}
        ></path>
        <path
          d="M192.23,323.64c-2.81,0-10.82-2.25-20-4.84-9.93-2.79-21.19-5.95-25.4-6-8.44-.07-10-4.86-10.06-5.07a.6.6,0,0,1,0-.35c.11-.24,11.17-24.13,48.12-21.7a.51.51,0,0,1,.43.31c.06.14,5.85,13.83,9.28,19.39s13.1,12.92,13.2,13a.49.49,0,0,1,.19.46.48.48,0,0,1-.31.4c-.41.16-10.12,3.92-15.07,4.36Zm-54.48-16c.35.81,2.18,4.14,9,4.19,4.36,0,15.19,3.07,25.67,6,9.15,2.56,17.78,5,20,4.79,4-.36,11.47-3.06,14-4-2.34-1.84-9.75-7.87-12.74-12.71-3.2-5.18-8.37-17.24-9.23-19.25C150.2,284.54,138.84,305.46,137.75,307.63Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M161.48,303a195.85,195.85,0,0,1,21.05,3.88c10.58,2.63,20.52,9.1,22.58,14.29s3.49,12.22-.5,14.37-19.71-9-19.71-9a54.14,54.14,0,0,1-33.22.2C134,321,132.33,306.45,144.2,301.21"
          style={{ fill: '#fff' }}
        ></path>
        <path
          d="M203.33,336.33c-5.16,0-16.35-7.66-18.51-9.17a55.4,55.4,0,0,1-33.3.12c-9.18-3-15.08-8.87-15.4-15.29-.24-4.75,2.71-8.95,7.88-11.24a.5.5,0,1,1,.4.91c-4.78,2.12-7.5,6-7.28,10.28.23,4.7,4.22,10.95,14.71,14.39a54.11,54.11,0,0,0,32.89-.2.5.5,0,0,1,.46.06c4.31,3,16.14,10.59,19.19,8.94,3.48-1.87,2.42-8.33.27-13.75-2-5.17-12-11.45-22.23-14a194.49,194.49,0,0,0-21-3.87A.5.5,0,0,1,161,303a.49.49,0,0,1,.56-.43,198.14,198.14,0,0,1,21.11,3.88c10.48,2.61,20.76,9.15,22.92,14.59,1.12,2.83,4.38,12.25-.72,15A3.22,3.22,0,0,1,203.33,336.33Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M344.79,274.39a14.07,14.07,0,0,1,5.29,3.51c1.79,2.22,3.94,8.4,3.94,8.4s6,5,8.35,11,1.52,10.21.17,10.73a13.49,13.49,0,0,1-2.17,2.38l-2.21,2c-.74.67-2,2-3.39,2.1l-1.39.13s-1.56,2.52-3.7,3.12-2.85-.74-3-2.32a8.64,8.64,0,0,0-2.26-5.17,21.24,21.24,0,0,0-4.27-3.2s-10.26,18.64-29.75,25.37-29.13,3.61-35-6,3-30.92,11.44-45.41S312.28,260.4,344.79,274.39Z"
          style={{ fill: '#fff' }}
        ></path>
        <path
          d="M292.69,336.54c-8.05,0-13.66-3.24-17.68-9.87-5.77-9.5,2.12-29.95,11.43-45.92,9.9-17,29.59-19.28,58.53-6.82a14.24,14.24,0,0,1,5.5,3.65c1.7,2.12,3.65,7.5,4,8.42.85.73,6.18,5.46,8.39,11.11,1.89,4.85,1.71,8.5,1,10.16a2.35,2.35,0,0,1-1,1.15,12,12,0,0,1-2.21,2.4L358.25,313a6.45,6.45,0,0,1-3.44,2l-1.14.1a7.94,7.94,0,0,1-3.85,3.13,2.87,2.87,0,0,1-2.45-.24,3.26,3.26,0,0,1-1.18-2.52,8.16,8.16,0,0,0-2.12-4.87,19.08,19.08,0,0,0-3.73-2.85c-1.73,2.89-12,19-29.77,25.15C303.55,335.31,297.66,336.54,292.69,336.54Zm20.64-69.49c-11.8,0-20.51,4.74-26,14.2-8.74,15-17,35.76-11.44,44.9,6.21,10.24,16.5,12,34.38,5.79,19.08-6.58,29.38-24.95,29.49-25.13a.45.45,0,0,1,.3-.24.5.5,0,0,1,.38,0,22.41,22.41,0,0,1,4.39,3.3,9.22,9.22,0,0,1,2.39,5.46,2.45,2.45,0,0,0,.75,1.79,1.91,1.91,0,0,0,1.61.09c1.93-.53,3.39-2.87,3.41-2.9a.48.48,0,0,1,.38-.23l1.38-.13a5.64,5.64,0,0,0,2.85-1.74L360,310l.14-.08a12.38,12.38,0,0,0,2-2.23.52.52,0,0,1,.22-.17,1.23,1.23,0,0,0,.55-.69c.7-1.59.67-5.11-1-9.39-2.26-5.77-8.15-10.74-8.21-10.79a.48.48,0,0,1-.15-.22c0-.06-2.14-6.12-3.86-8.25a13.59,13.59,0,0,0-5.07-3.35h0Q326.46,267,313.33,267.05Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M297.37,323a.49.49,0,0,1-.49-.42.5.5,0,0,1,.4-.58c21.46-3.88,32.69-22.65,32.8-22.84a.51.51,0,0,1,.69-.18.49.49,0,0,1,.18.68c-.12.2-11.59,19.37-33.49,23.33Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M340.16,307.55a.51.51,0,0,1-.32-.12.49.49,0,0,1-.06-.7A57.18,57.18,0,0,1,344,302.1a.5.5,0,0,1,.7.08.5.5,0,0,1-.07.7,55.86,55.86,0,0,0-4.1,4.49A.5.5,0,0,1,340.16,307.55Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M353.38,315.12a.59.59,0,0,1-.25-.07c-2.29-1.32-2.7-5.56-2.72-5.74a.5.5,0,0,1,1-.09s.38,3.9,2.22,5a.5.5,0,0,1-.25.94Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M357.5,313.11a.44.44,0,0,1-.16,0c-2.4-.79-4.07-5.27-4.14-5.46a.5.5,0,0,1,.3-.64.5.5,0,0,1,.64.29c.43,1.18,1.9,4.33,3.51,4.86a.5.5,0,0,1-.15,1Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M360.37,310.9h-.09c-3-.55-3.52-5.45-3.54-5.65a.5.5,0,0,1,.45-.55.51.51,0,0,1,.55.45s.44,4.35,2.72,4.76a.5.5,0,0,1,.4.58A.49.49,0,0,1,360.37,310.9Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M362.54,308.52h-.08c-3.07-.48-3.67-5.47-3.7-5.68a.5.5,0,0,1,1-.11s.54,4.44,2.86,4.81a.5.5,0,0,1,.42.57A.51.51,0,0,1,362.54,308.52Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M330.52,299.36S337,285,318.84,273.54s-27.79,5.29-32.3,12.66S284,291,284,291l-12.39-9.43s7.71-13.83,7-27.9S271.82,231,271.82,231s13.06-14.11,43.35-7.08,35.75,34.59,32.49,47C344.82,281.79,330.52,299.36,330.52,299.36Z"
          style={{ fill: '#92E3A9' }}
        ></path>
        <path
          d="M330.52,299.86a.56.56,0,0,1-.27-.08.5.5,0,0,1-.19-.63c.26-.57,6.06-14.06-11.49-25.19-5.29-3.35-10.29-4.51-14.87-3.44-8.17,1.9-13.32,10.36-16.4,15.41l-.33.53c-1.45,2.37-2.18,3.63-2.55,4.3a.32.32,0,0,1,.06.18.48.48,0,0,1-.09.37c-.28.36-.65.85-1.1.54-.22-.15-.36-.24-.11-.83l-11.89-9a.51.51,0,0,1-.13-.64c.08-.13,7.63-13.87,7-27.63s-6.67-22.32-6.73-22.4a.51.51,0,0,1,0-.63c.14-.15,13.61-14.25,43.83-7.24,12.29,2.85,21.88,9.48,27.73,19.17,6.07,10.05,6.95,21.54,5.14,28.48-2.84,10.82-16.66,27.87-17.25,28.59A.46.46,0,0,1,330.52,299.86Zm-23.45-30.73c3.81,0,7.85,1.34,12,4,13.75,8.71,13.57,19.18,12.6,23.91,3.8-4.92,13.25-17.73,15.47-26.21,1.76-6.73.89-17.9-5-27.71-5.71-9.45-15.08-15.92-27.1-18.7-27.3-6.34-40.55,4.75-42.58,6.66a47.53,47.53,0,0,1,6.67,22.58c.6,12.75-5.68,25.44-6.9,27.79l11.39,8.67c.46-.84,1.24-2.13,2.49-4.17l.32-.53c3.16-5.19,8.46-13.87,17-15.87A15.94,15.94,0,0,1,307.07,269.13Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M308,260.14a.5.5,0,0,1-.11-1,31.79,31.79,0,0,1,3.64-.58.5.5,0,0,1,.55.45.5.5,0,0,1-.45.55,29.73,29.73,0,0,0-3.52.56Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M290.55,280.37h0a.5.5,0,0,1-.48-.52c0-.14.7-14.65,14.44-19.71a.5.5,0,0,1,.34.94c-13.11,4.83-13.78,18.67-13.78,18.81A.5.5,0,0,1,290.55,280.37Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M297,258a.49.49,0,0,1-.36-.84,28,28,0,0,1,11.6-7.24.5.5,0,1,1,.32.94,27.25,27.25,0,0,0-11.2,7A.51.51,0,0,1,297,258Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M289.51,275.49h0A.52.52,0,0,1,289,275a29.07,29.07,0,0,1,5.88-15.77.5.5,0,1,1,.79.61A28.54,28.54,0,0,0,290,275,.5.5,0,0,1,289.51,275.49Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M189.46,249.39s-1.88,7.64-.84,13.47,2.26,10.88-.88,16.05-9.6,7.82-3.45,18.86S196,316.29,218,317.92s38.55-9.66,40.55-13.19,7.58-15.88,10.4-19,8.51-11.33,6.22-25.29S260,234.8,234.7,234,198.83,239,189.46,249.39Z"
          style={{ fill: '#fff' }}
        ></path>
        <path
          d="M222.3,318.57q-2.12,0-4.29-.15c-22.12-1.63-27.89-9.14-34.16-20.41-5-8.94-1.86-12.68,1.16-16.29a25.93,25.93,0,0,0,2.31-3.07c2.82-4.65,2-9.21,1-14.48l-.22-1.22c-1-5.87.78-13.36.86-13.68a.53.53,0,0,1,.11-.21c9.47-10.49,20-16.4,45.63-15.57,23,.73,38.35,10.79,41,26.91,2.35,14.33-3.65,22.75-6.34,25.7-1.91,2.1-5.24,8.8-7.68,13.69-1.09,2.2-2,4.1-2.66,5.19C257.07,308.42,242.23,318.57,222.3,318.57Zm-32.38-68.94c-.23,1-1.75,7.88-.81,13.15l.22,1.21c1,5.25,1.86,10.21-1.16,15.18a26.52,26.52,0,0,1-2.39,3.19c-2.93,3.51-5.7,6.82-1.05,15.17,6.11,11,11.73,18.3,33.35,19.89s38.06-9.38,40.07-12.93c.61-1.07,1.55-3,2.64-5.15,2.59-5.22,5.82-11.71,7.83-13.91,2.59-2.85,8.38-11,6.1-24.87-2.56-15.61-17.52-25.35-40-26.07C209.5,233.68,199.17,239.43,189.92,249.63Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M210.49,300a4.85,4.85,0,0,1-1.79-.34c-2.84-1.12-2.94-4.44-2.6-6a.52.52,0,0,1,.6-.39.5.5,0,0,1,.38.6c0,.15-.78,3.8,2,4.89s5.71-1.71,5.73-1.74a.5.5,0,0,1,.71,0,.48.48,0,0,1,0,.7A8,8,0,0,1,210.49,300Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M203.15,309.88a.5.5,0,0,1-.44-.27c-2.57-4.85,2.07-7.79,2.11-7.82a.5.5,0,0,1,.69.17.5.5,0,0,1-.16.68c-.16.1-3.88,2.49-1.76,6.5a.5.5,0,0,1-.21.68A.46.46,0,0,1,203.15,309.88Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M210.18,314.47a5.09,5.09,0,0,1-1.67-.27.5.5,0,0,1,.38-.93h0c.11.05,2.71,1.05,5-1.73a.49.49,0,0,1,.7-.06.5.5,0,0,1,.07.7A5.77,5.77,0,0,1,210.18,314.47Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M196.06,286.67a8,8,0,0,1-4.67-6.12c-1,2.89.57,6.3,3.76,7.87s6.7.76,8.41-1.69A8.07,8.07,0,0,1,196.06,286.67Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M230.92,300a8,8,0,0,1-5.83-5c-.39,3,1.87,6.05,5.31,6.91s6.71-.65,7.87-3.4A8,8,0,0,1,230.92,300Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M191.86,273.36a12.8,12.8,0,0,1,10.43,2.85c4.77,4.13,4.08-.21,3-2.09S193.08,268.39,191.86,273.36Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M205.26,278.44c-1,0-2.32-1-3.3-1.85a12.42,12.42,0,0,0-10-2.75.51.51,0,0,1-.47-.13.51.51,0,0,1-.12-.47c.37-1.52,1.58-2.47,3.5-2.74,3.88-.55,9.93,1.8,10.84,3.37.56,1,1.42,3.4.34,4.29A1.17,1.17,0,0,1,205.26,278.44ZM194,272.67a13.11,13.11,0,0,1,8.63,3.16c2.26,2,2.8,1.57,2.81,1.56.33-.27.1-1.85-.58-3-.58-1-6.11-3.41-9.83-2.88a3.19,3.19,0,0,0-2.35,1.24A12.1,12.1,0,0,1,194,272.67Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M241.29,288.73a15.31,15.31,0,0,0-9-7.55c-6.31-1.77-2.49-3.74-.33-3.9S244.35,284.68,241.29,288.73Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M241.29,289.23h0a.48.48,0,0,1-.4-.28,14.88,14.88,0,0,0-8.69-7.27c-2.69-.76-3.94-1.65-3.83-2.74.13-1.29,2.22-2.05,3.59-2.15,1.84-.1,7.62,3.86,9.68,7.64,1.24,2.27.74,3.76.1,4.61A.54.54,0,0,1,241.29,289.23ZM232,277.78h0c-1.2.09-2.62.75-2.67,1.25,0,.29.59,1,3.1,1.67a15.57,15.57,0,0,1,8.85,6.95,3.78,3.78,0,0,0-.56-2.76C238.74,281.27,233.32,277.78,232,277.78Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M274.35,228.59c-9.47-12.5-28.15-22.66-48.3-17.2s-30.19,15.31-30.19,15.31-8.32,7.13-11.33,12.41-8.8,8.53-13.11,6.18S170,238,170,238s-6.53,1.66-7.18,11.28,6.09,15.39,17.65,17.38,24.38-2.35,24.38-2.35,6.82,6.68,22.17,5.14,28.26-11.5,28.26-11.5-6.9,9.43-4.12,16.62,17,13.94,17,13.94,8.46-3.79,11.73-19.72S283.82,241.1,274.35,228.59Zm-50.77,34.63c-10.46,1.85-14.9-.33-14.9-.33s2.68-3.89,15.2-6.53,18.46,1.07,18.46,1.07A82.67,82.67,0,0,1,223.58,263.22Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M268.21,289a.54.54,0,0,1-.22,0c-.59-.28-14.48-6.93-17.29-14.23-2-5.31.9-11.66,2.8-14.93-4.3,2.86-14.71,9-26.42,10.14-14.06,1.41-21-3.92-22.34-5.07-2,.62-13.74,4.1-24.34,2.27-12.29-2.12-18.71-8.48-18.07-17.91.67-9.87,7.27-11.65,7.56-11.72a.5.5,0,0,1,.52.18.52.52,0,0,1,0,.56,6.51,6.51,0,0,0-.74,4.32,3.78,3.78,0,0,0,2,2.3c4.25,2.3,9.76-1.32,12.43-6,3-5.3,11.1-12.24,11.44-12.54.08-.07,10.34-10,30.39-15.42,20.43-5.53,39.25,4.75,48.83,17.39,9.27,12.23,9.15,23.7,5.68,40.58-3.29,16-11.66,19.92-12,20.08A.45.45,0,0,1,268.21,289Zm-12.92-31.56a.49.49,0,0,1,.34.14.5.5,0,0,1,.06.66c-.07.09-6.7,9.29-4.06,16.14,2.49,6.44,14.77,12.68,16.57,13.56,1.28-.7,8.35-5.17,11.25-19.26,3.41-16.59,3.54-27.85-5.5-39.78h0c-9.37-12.37-27.78-22.43-47.77-17-19.77,5.35-29.87,15.09-30,15.19s-8.31,7.15-11.25,12.29-8.93,9-13.77,6.38a4.7,4.7,0,0,1-2.45-2.9,6.61,6.61,0,0,1,.3-3.92c-1.8.92-5.25,3.55-5.71,10.38-.77,11.34,8.95,15.43,17.24,16.86,11.29,2,24-2.29,24.13-2.33a.49.49,0,0,1,.51.12c.07.06,6.87,6.48,21.77,5s27.87-11.3,28-11.4A.51.51,0,0,1,255.29,257.43Zm-39.52,7c-4.93,0-7.18-1.06-7.31-1.12a.55.55,0,0,1-.27-.33.5.5,0,0,1,.08-.41c.11-.16,2.94-4.08,15.5-6.73s18.58,1,18.83,1.13a.51.51,0,0,1,.24.46.5.5,0,0,1-.29.42,83.28,83.28,0,0,1-18.88,5.83h0A45.32,45.32,0,0,1,215.77,264.46Zm-6.21-1.78c1.57.49,6,1.45,13.94,0a83.5,83.5,0,0,0,17.65-5.31c-2.09-.87-7.76-2.55-17.17-.56S210.76,261.57,209.56,262.68Zm14,.54h0Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M208.34,316.5s-.32-3.62.3-5.19-.71-2.82-2.37-2.67-4.87,3.17-5,4.7l-.17,1.54s-4.26-1.9-6.26-.5-1.36,5.13-1.36,5.13a11.13,11.13,0,0,0-2.12,5.2c-.2,2.75,1.21,3.23,1.21,3.23a3,3,0,0,0-.14,3.5l1.1,2.18s2.83,6.12,7,5.29S202,335,202,335s7,1.95,10.32,1.65a17.22,17.22,0,0,0,6.75-2.58s20.06,7,36,5.56,27.37-14.6,30.39-24.89,1.74-26.26-2-32.61-7.62-2.34-7.62-2.34l-6.81,6s-1.85,2.67-2.77,4.27-7.57,13.67-7.63,14.69-9.91,8-15.51,9.86S221.8,319.23,208.34,316.5Z"
          style={{ fill: '#fff' }}
        ></path>
        <path
          d="M250.56,340.31c-14,0-29.4-5-31.46-5.71a17.14,17.14,0,0,1-6.77,2.53c-2.56.23-7.11-.8-9.28-1.35a2.63,2.63,0,0,1,.17,1.86c-.3.88-1.18,1.47-2.63,1.76-4.5.9-7.43-5.31-7.55-5.57l-1.1-2.16a3.71,3.71,0,0,1-.11-3.59c-.5-.44-1.16-1.41-1-3.41a11.26,11.26,0,0,1,2.08-5.29c-.11-.87-.36-4,1.6-5.41,1.73-1.21,4.8-.29,6.13.2l.09-.88c.19-1.76,3.63-5,5.5-5.15a2.94,2.94,0,0,1,2.61,1.05,2.32,2.32,0,0,1,.26,2.3,14,14,0,0,0-.29,4.59c13.44,2.63,28.75-.18,34.11-2,5.54-1.85,14.72-8.4,15.18-9.48.06-1.21,7.61-14.71,7.69-14.84.92-1.6,2.71-4.2,2.79-4.31l.08-.09,6.81-6a4.88,4.88,0,0,1,3.71-1.34c1.72.21,3.29,1.49,4.67,3.81,4,6.7,5,23,2.07,33-3.28,11.19-15.2,23.85-30.83,25.25C253.61,340.25,252.09,340.31,250.56,340.31ZM219,333.55a.51.51,0,0,1,.17,0c.2.07,20.14,6.94,35.81,5.54,15.18-1.37,26.76-13.67,29.95-24.54,2.86-9.75,1.89-25.7-2-32.21-1.21-2-2.53-3.15-3.91-3.32a3.93,3.93,0,0,0-2.92,1.07l-6.8,6c-.25.37-1.88,2.74-2.71,4.19-1.25,2.19-7.4,13.42-7.56,14.49-.09,1.47-10.5,8.49-15.85,10.28s-21.27,4.71-35,1.93a.51.51,0,0,1-.39-.45c0-.15-.33-3.75.32-5.41a1.32,1.32,0,0,0-.13-1.34,1.9,1.9,0,0,0-1.72-.65c-1.41.12-4.45,2.95-4.59,4.26l-.17,1.53a.51.51,0,0,1-.25.38.48.48,0,0,1-.45,0c-1.12-.49-4.33-1.55-5.78-.54-1.71,1.19-1.16,4.6-1.15,4.64a.51.51,0,0,1-.1.39,10.67,10.67,0,0,0-2,4.93c-.17,2.27.84,2.7.88,2.72a.53.53,0,0,1,.31.36.52.52,0,0,1-.12.45,2.58,2.58,0,0,0-.06,2.94l1.11,2.17c0,.08,2.72,5.77,6.46,5,.73-.15,1.65-.46,1.87-1.1s-.41-1.69-.68-2a.48.48,0,0,1-.05-.59.5.5,0,0,1,.56-.22c.07,0,7,1.93,10.14,1.64a17.19,17.19,0,0,0,6.52-2.5A.44.44,0,0,1,219,333.55Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M272.48,324.52a3.61,3.61,0,0,1-3.43-1.93,2.64,2.64,0,0,1-.1-2.12c1.08-3.12,7.15-6,7.41-6.16a.5.5,0,0,1,.67.24.5.5,0,0,1-.24.66c-.06,0-6,2.86-6.9,5.59a1.68,1.68,0,0,0,.05,1.34c.57,1.13,1.9,1.61,3.56,1.27,1.94-.38,4.07-1.87,4.2-3.85.06-.89-.33-.95-.52-1-1.35-.2-4.13,1.65-4.5,2.38h0a3.75,3.75,0,0,0,2-.53.5.5,0,1,1,.47.88,4.44,4.44,0,0,1-2.76.6,1.09,1.09,0,0,1-.68-.56c-.29-.58.28-1.19.47-1.39,1.07-1.13,4-2.94,5.58-2.25a1.72,1.72,0,0,1,.92,1.91c-.16,2.48-2.7,4.31-5,4.77A6.44,6.44,0,0,1,272.48,324.52Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M193.44,320a.52.52,0,0,1-.35-.14.5.5,0,0,1,0-.71c.91-.94,3.21-2.4,5.46-1.17a.5.5,0,1,1-.48.88c-2.14-1.17-4.17.9-4.25,1A.51.51,0,0,1,193.44,320Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M192.53,328.44a.38.38,0,0,1-.21-.05.5.5,0,0,1-.25-.66c.07-.15,1.68-3.6,3.86-4a.5.5,0,0,1,.19,1c-1.4.26-2.74,2.58-3.14,3.45A.5.5,0,0,1,192.53,328.44Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M195.53,336.89a.5.5,0,0,1-.47-.33c0-.05-1.95-5.47,2.82-7.62a.5.5,0,0,1,.41.91c-3.92,1.77-2.36,6.18-2.29,6.37a.5.5,0,0,1-.47.67Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M266.11,287.16s7.92-8.15,10.84.7-15.14,17.3-17.8,14.65-.78-3.57-.78-3.57"
          style={{ fill: '#fff' }}
        ></path>
        <path
          d="M260.85,303.51a2.85,2.85,0,0,1-2.05-.64c-1.18-1.17-1.7-2.21-1.55-3.12a1.76,1.76,0,0,1,.9-1.26.5.5,0,0,1,.44.9.77.77,0,0,0-.36.54c-.05.32.06,1,1.27,2.23,1,1,5.45,0,10-2.83,2.09-1.29,8.78-5.94,7-11.32-.71-2.13-1.76-3.37-3.13-3.69-3.05-.7-6.84,3.15-6.88,3.19a.49.49,0,0,1-.7,0,.48.48,0,0,1,0-.7c.17-.18,4.22-4.3,7.81-3.47,1.74.4,3,1.86,3.86,4.35,1.35,4.1-1.43,8.76-7.44,12.48C266.92,302.08,263.25,303.51,260.85,303.51Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M262.24,298.64a.58.58,0,0,1-.18,0,.5.5,0,0,1-.28-.65c3.21-8,12.42-7,12.52-7a.51.51,0,0,1,.44.56.52.52,0,0,1-.56.44c-.35,0-8.55-.88-11.47,6.38A.51.51,0,0,1,262.24,298.64Z"
          style={{ fill: '#263238' }}
        ></path>
      </g>
      <g id="freepik--Letters--inject-39">
        <path
          d="M141.61,257.74v-1.36l4-10.88h-3.74v-1.36h5.75V245l-4.21,11.41h4.21v1.36Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M152.28,233.78v-2l5.83-15.87h-5.47v-2H161v1.2l-6.14,16.65H161v2Z"
          style={{ fill: '#263238' }}
        ></path>
        <path
          d="M174.8,217v-2.61l7.65-20.84h-7.17V191h11v1.57l-8.07,21.87h8.07V217Z"
          style={{ fill: '#263238' }}
        ></path>
      </g>
      <g id="freepik--Stars--inject-39">
        <polygon
          points="151.18 78.32 157.53 91.17 171.72 93.24 161.45 103.25 163.88 117.38 151.18 110.7 138.49 117.38 140.92 103.25 130.65 93.24 144.84 91.17 151.18 78.32"
          style={{ fill: '#fff' }}
        ></polygon>
        <path
          d="M163.87,117.88a.49.49,0,0,1-.23-.06l-12.46-6.55-12.46,6.55a.5.5,0,0,1-.72-.53l2.38-13.87L130.3,93.59a.49.49,0,0,1-.13-.51.51.51,0,0,1,.41-.34l13.93-2,6.22-12.62a.52.52,0,0,1,.9,0l6.23,12.62,13.93,2a.5.5,0,0,1,.28.85L162,103.42l2.38,13.87a.51.51,0,0,1-.2.49A.54.54,0,0,1,163.87,117.88Zm-12.69-7.68a.53.53,0,0,1,.24.06l11.79,6.2L161,103.33a.48.48,0,0,1,.14-.44l9.54-9.3-13.18-1.92a.49.49,0,0,1-.38-.27l-5.9-12-5.89,12a.53.53,0,0,1-.38.27l-13.19,1.92,9.54,9.3a.49.49,0,0,1,.15.44l-2.25,13.13,11.79-6.2A.46.46,0,0,1,151.18,110.2Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="280.84 90.61 281.93 99.41 289.8 103.5 281.76 107.26 280.31 116 274.25 109.53 265.48 110.84 269.77 103.08 265.81 95.15 274.52 96.83 280.84 90.61"
          style={{ fill: '#92E3A9' }}
        ></polygon>
        <path
          d="M280.31,116.5a.49.49,0,0,1-.37-.16l-5.87-6.28-8.51,1.27a.51.51,0,0,1-.49-.2.49.49,0,0,1,0-.53l4.16-7.53-3.85-7.7a.51.51,0,0,1,.05-.52.49.49,0,0,1,.5-.19l8.44,1.63,6.14-6a.5.5,0,0,1,.51-.11.49.49,0,0,1,.33.41l1.06,8.54,7.64,4a.49.49,0,0,1,.27.45.51.51,0,0,1-.29.44l-7.8,3.65-1.41,8.48a.5.5,0,0,1-.35.4A.36.36,0,0,1,280.31,116.5ZM274.25,109a.52.52,0,0,1,.37.16l5.36,5.74,1.29-7.75a.51.51,0,0,1,.28-.37l7.12-3.32-7-3.63a.47.47,0,0,1-.27-.38l-1-7.79-5.6,5.51a.53.53,0,0,1-.45.13l-7.71-1.49,3.51,7a.5.5,0,0,1,0,.46l-3.8,6.88,7.77-1.17Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="116.98 105.31 118.07 114.11 125.94 118.2 117.91 121.95 116.45 130.7 110.39 124.22 101.62 125.54 105.91 117.78 101.95 109.84 110.66 111.53 116.98 105.31"
          style={{ fill: '#92E3A9' }}
        ></polygon>
        <path
          d="M116.45,131.2a.52.52,0,0,1-.37-.16l-5.87-6.28L101.7,126a.5.5,0,0,1-.49-.21.49.49,0,0,1,0-.52l4.16-7.53-3.84-7.7a.51.51,0,0,1,0-.53.53.53,0,0,1,.5-.19L110.5,111l6.13-6a.48.48,0,0,1,.51-.11.51.51,0,0,1,.34.41l1,8.54,7.64,4a.5.5,0,0,1,0,.9l-7.79,3.65-1.42,8.48a.51.51,0,0,1-.35.4Zm-6.06-7.48a.52.52,0,0,1,.37.16l5.36,5.74,1.29-7.75a.51.51,0,0,1,.28-.37l7.12-3.33-7-3.62a.49.49,0,0,1-.27-.38l-1-7.79-5.6,5.5a.51.51,0,0,1-.45.14l-7.71-1.49,3.51,7a.5.5,0,0,1,0,.46l-3.8,6.87,7.77-1.16Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="87.55 222.15 88.64 230.95 96.51 235.04 88.47 238.8 87.02 247.54 80.96 241.07 72.19 242.38 76.48 234.62 72.52 226.69 81.23 228.37 87.55 222.15"
          style={{ fill: '#92E3A9' }}
        ></polygon>
        <path
          d="M87,248a.51.51,0,0,1-.37-.15l-5.87-6.29-8.51,1.28a.52.52,0,0,1-.49-.21.49.49,0,0,1,0-.53l4.16-7.53-3.84-7.7a.49.49,0,0,1,0-.52.51.51,0,0,1,.5-.19l8.45,1.63,6.13-6a.48.48,0,0,1,.51-.12.51.51,0,0,1,.34.41l1,8.54,7.64,4a.5.5,0,0,1,.27.45.54.54,0,0,1-.29.45l-7.8,3.64-1.41,8.49a.5.5,0,0,1-.35.39A.36.36,0,0,1,87,248ZM81,240.57a.49.49,0,0,1,.37.16l5.36,5.73L88,238.72a.49.49,0,0,1,.28-.37L95.38,235l-7-3.62a.5.5,0,0,1-.27-.39l-1-7.79-5.6,5.51a.53.53,0,0,1-.45.13l-7.71-1.49,3.51,7a.5.5,0,0,1,0,.46l-3.8,6.88,7.77-1.17Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="250.28 175.32 250.72 178.91 253.93 180.58 250.66 182.11 250.06 185.68 247.59 183.03 244.02 183.57 245.77 180.41 244.15 177.17 247.7 177.86 250.28 175.32"
          style={{ fill: '#fff' }}
        ></polygon>
        <path
          d="M250.06,186.18a.5.5,0,0,1-.36-.16l-2.29-2.45-3.32.49a.48.48,0,0,1-.48-.21.49.49,0,0,1,0-.52l1.62-2.94-1.49-3a.49.49,0,0,1,0-.52.51.51,0,0,1,.5-.19l3.29.64,2.39-2.36a.5.5,0,0,1,.51-.11.51.51,0,0,1,.34.41l.41,3.33,3,1.54a.5.5,0,0,1,.27.46.48.48,0,0,1-.29.44l-3,1.42-.55,3.31a.5.5,0,0,1-.35.39Zm-2.47-3.65a.52.52,0,0,1,.37.16l1.78,1.9.42-2.56a.5.5,0,0,1,.29-.37l2.35-1.11-2.31-1.2a.5.5,0,0,1-.26-.38l-.32-2.58-1.86,1.82a.48.48,0,0,1-.44.14l-2.56-.5,1.16,2.33a.52.52,0,0,1,0,.47L245,182.93l2.57-.39Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="394.91 33.33 396 42.13 403.86 46.22 395.83 49.98 394.37 58.72 388.32 52.24 379.55 53.56 383.84 45.8 379.88 37.87 388.59 39.55 394.91 33.33"
          style={{ fill: '#92E3A9' }}
        ></polygon>
        <path
          d="M394.37,59.22a.46.46,0,0,1-.36-.16l-5.88-6.28-8.5,1.27a.5.5,0,0,1-.49-.21.49.49,0,0,1,0-.52l4.17-7.53-3.85-7.7a.53.53,0,0,1,.05-.53.49.49,0,0,1,.49-.18L388.42,39l6.14-6a.5.5,0,0,1,.51-.11.49.49,0,0,1,.33.41l1.06,8.54,7.63,4a.47.47,0,0,1,.27.45.49.49,0,0,1-.28.44l-7.8,3.65-1.41,8.48a.5.5,0,0,1-.35.4A.39.39,0,0,1,394.37,59.22Zm-6.05-7.48a.48.48,0,0,1,.36.16l5.37,5.74,1.29-7.75a.51.51,0,0,1,.28-.37l7.11-3.32-7-3.63a.49.49,0,0,1-.27-.38l-1-7.79-5.6,5.51a.53.53,0,0,1-.45.13l-7.71-1.49,3.51,7a.5.5,0,0,1,0,.46l-3.8,6.88,7.77-1.17Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="394.01 202.59 395.1 211.39 402.97 215.48 394.94 219.24 393.48 227.99 387.43 221.51 378.66 222.82 382.95 215.06 378.99 207.13 387.69 208.81 394.01 202.59"
          style={{ fill: '#fff' }}
        ></polygon>
        <path
          d="M393.48,228.49a.52.52,0,0,1-.37-.16L387.24,222l-8.51,1.28a.53.53,0,0,1-.48-.21.51.51,0,0,1,0-.53l4.16-7.53-3.84-7.7a.47.47,0,0,1,0-.52.49.49,0,0,1,.49-.19l8.45,1.63,6.13-6a.5.5,0,0,1,.52-.12.49.49,0,0,1,.33.41l1.06,8.54,7.63,4a.5.5,0,0,1,.27.45.51.51,0,0,1-.29.45l-7.79,3.64L394,228.07a.47.47,0,0,1-.35.39A.32.32,0,0,1,393.48,228.49ZM387.43,221a.46.46,0,0,1,.36.16l5.36,5.73,1.3-7.74a.48.48,0,0,1,.28-.37l7.11-3.33-7-3.62a.52.52,0,0,1-.26-.38l-1-7.8-5.6,5.51a.48.48,0,0,1-.44.13l-7.71-1.49,3.5,7a.49.49,0,0,1,0,.47l-3.79,6.87,7.76-1.17Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="456.13 107.48 456.57 111.07 459.78 112.74 456.5 114.27 455.91 117.83 453.44 115.19 449.86 115.73 451.61 112.56 450 109.33 453.55 110.02 456.13 107.48"
          style={{ fill: '#fff' }}
        ></polygon>
        <path
          d="M455.91,118.33a.51.51,0,0,1-.37-.15l-2.29-2.45-3.31.49a.5.5,0,0,1-.49-.21.47.47,0,0,1,0-.52l1.62-2.94-1.5-3a.5.5,0,0,1,.54-.71l3.29.64,2.39-2.36a.52.52,0,0,1,.52-.11.49.49,0,0,1,.33.41l.41,3.33,3,1.54a.5.5,0,0,1,0,.9l-3,1.42-.55,3.31a.48.48,0,0,1-.35.39A.32.32,0,0,1,455.91,118.33Zm-2.47-3.64a.48.48,0,0,1,.36.16l1.78,1.9.43-2.56a.51.51,0,0,1,.28-.38l2.36-1.1-2.31-1.2a.49.49,0,0,1-.27-.38l-.32-2.58-1.85,1.82a.5.5,0,0,1-.45.14l-2.55-.5,1.16,2.33a.52.52,0,0,1,0,.47l-1.26,2.27,2.58-.38Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="249.17 53.48 249.62 57.07 252.83 58.73 249.55 60.27 248.96 63.83 246.49 61.19 242.91 61.73 244.66 58.56 243.05 55.33 246.6 56.01 249.17 53.48"
          style={{ fill: '#fff' }}
        ></polygon>
        <path
          d="M249,64.33a.49.49,0,0,1-.37-.16l-2.29-2.45-3.31.5a.5.5,0,0,1-.49-.21.49.49,0,0,1,0-.52l1.63-2.94-1.5-3a.5.5,0,0,1,.54-.71l3.29.63,2.39-2.35a.5.5,0,0,1,.52-.11.49.49,0,0,1,.33.41l.41,3.32,3,1.55a.5.5,0,0,1,.27.45.52.52,0,0,1-.29.45l-3,1.42-.55,3.3a.5.5,0,0,1-.35.4A.34.34,0,0,1,249,64.33Zm-2.47-3.64a.48.48,0,0,1,.36.16l1.78,1.9.43-2.57a.51.51,0,0,1,.28-.37l2.36-1.1-2.31-1.2a.5.5,0,0,1-.27-.38l-.32-2.58L247,56.37a.47.47,0,0,1-.45.13L244,56l1.16,2.33a.5.5,0,0,1,0,.46l-1.26,2.28,2.57-.38Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="102.68 169.88 103.13 173.47 106.33 175.13 103.06 176.66 102.47 180.23 100 177.59 96.42 178.13 98.17 174.96 96.56 171.73 100.11 172.41 102.68 169.88"
          style={{ fill: '#fff' }}
        ></polygon>
        <path
          d="M102.47,180.73a.52.52,0,0,1-.37-.16l-2.29-2.45-3.31.5a.5.5,0,0,1-.49-.21.51.51,0,0,1,0-.53L97.61,175l-1.5-3a.49.49,0,0,1,0-.53.48.48,0,0,1,.49-.18l3.29.63,2.39-2.35a.53.53,0,0,1,.52-.12.53.53,0,0,1,.33.41l.41,3.33,3,1.55a.52.52,0,0,1,.27.45.54.54,0,0,1-.29.45l-3,1.42-.55,3.3a.51.51,0,0,1-.35.4ZM100,177.09a.5.5,0,0,1,.36.16l1.78,1.9.43-2.57a.48.48,0,0,1,.28-.37l2.36-1.1-2.31-1.2a.5.5,0,0,1-.27-.38l-.32-2.59-1.85,1.83a.53.53,0,0,1-.45.13l-2.55-.49,1.16,2.33a.5.5,0,0,1,0,.46l-1.26,2.28,2.57-.39Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="257.55 454 258 457.59 261.2 459.26 257.93 460.79 257.33 464.36 254.87 461.72 251.29 462.25 253.04 459.09 251.43 455.85 254.97 456.54 257.55 454"
          style={{ fill: '#fff' }}
        ></polygon>
        <path
          d="M257.34,464.86a.52.52,0,0,1-.37-.16l-2.29-2.45-3.31.5a.52.52,0,0,1-.49-.21.51.51,0,0,1,0-.53l1.63-2.93-1.5-3a.49.49,0,0,1,.05-.53.5.5,0,0,1,.49-.19l3.29.64,2.39-2.35a.5.5,0,0,1,.52-.12.49.49,0,0,1,.33.41l.41,3.33,3,1.55a.49.49,0,0,1,0,.89l-3,1.42-.55,3.31a.51.51,0,0,1-.35.4Zm-2.47-3.64a.49.49,0,0,1,.36.15l1.78,1.91.43-2.57a.48.48,0,0,1,.28-.37l2.36-1.1-2.31-1.2a.5.5,0,0,1-.27-.39l-.32-2.58-1.85,1.83a.53.53,0,0,1-.45.13l-2.55-.49,1.16,2.32a.52.52,0,0,1,0,.47l-1.26,2.28,2.57-.39Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="428.72 269.59 432 271.1 435.11 269.25 434.69 272.84 437.41 275.22 433.87 275.93 432.44 279.25 430.67 276.1 427.07 275.77 429.52 273.11 428.72 269.59"
          style={{ fill: '#92E3A9' }}
        ></polygon>
        <path
          d="M432.44,279.75a.48.48,0,0,1-.43-.25l-1.65-2.93-3.33-.3a.5.5,0,0,1-.33-.84L429,273l-.74-3.27a.52.52,0,0,1,.17-.5.51.51,0,0,1,.53-.07l3,1.4,2.88-1.71a.49.49,0,0,1,.53,0,.49.49,0,0,1,.23.47l-.39,3.33,2.52,2.21a.54.54,0,0,1,.16.51.51.51,0,0,1-.39.36l-3.29.66-1.32,3.08a.5.5,0,0,1-.43.3Zm-4.31-4.38,2.59.23a.51.51,0,0,1,.39.26l1.27,2.26,1-2.39a.49.49,0,0,1,.36-.29l2.55-.51-2-1.72a.54.54,0,0,1-.17-.43l.31-2.59-2.24,1.34a.5.5,0,0,1-.46,0l-2.37-1.09L430,273a.49.49,0,0,1-.12.45Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="69.87 295.56 73.15 297.07 76.26 295.22 75.84 298.81 78.56 301.19 75.01 301.9 73.59 305.23 71.82 302.07 68.22 301.75 70.67 299.09 69.87 295.56"
          style={{ fill: '#fff' }}
        ></polygon>
        <path
          d="M73.59,305.73a.51.51,0,0,1-.44-.26l-1.64-2.92-3.34-.31a.49.49,0,0,1-.32-.83l2.27-2.47-.74-3.27a.5.5,0,0,1,.7-.56l3,1.4L76,294.79a.51.51,0,0,1,.76.49l-.39,3.33,2.52,2.21a.49.49,0,0,1,.15.5.51.51,0,0,1-.38.36l-3.29.66-1.32,3.08a.48.48,0,0,1-.43.3Zm-4.32-4.39,2.59.24a.46.46,0,0,1,.39.25l1.28,2.27,1-2.39a.49.49,0,0,1,.37-.3l2.55-.51-2-1.71a.52.52,0,0,1-.17-.44l.3-2.58-2.23,1.33a.52.52,0,0,1-.47,0l-2.36-1.08.58,2.54a.53.53,0,0,1-.12.45Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="89.65 418.33 96.53 423.93 105.04 421.43 101.85 429.7 106.86 437.02 98 436.54 92.59 443.56 90.31 434.99 81.96 432.01 89.4 427.2 89.65 418.33"
          style={{ fill: '#92E3A9' }}
        ></polygon>
        <path
          d="M92.59,444.06h-.1a.51.51,0,0,1-.39-.36l-2.21-8.31-8.1-2.9a.49.49,0,0,1-.33-.41.52.52,0,0,1,.23-.48l7.22-4.67.24-8.6a.5.5,0,0,1,.3-.44A.49.49,0,0,1,90,418l6.68,5.42L104.9,421a.49.49,0,0,1,.51.14.5.5,0,0,1,.1.52l-3.1,8,4.86,7.11a.49.49,0,0,1,0,.52.47.47,0,0,1-.46.26l-8.59-.47L93,443.87A.49.49,0,0,1,92.59,444.06Zm-9.51-12.18,7.4,2.64a.52.52,0,0,1,.31.34l2,7.59,4.8-6.22A.46.46,0,0,1,98,436l7.84.42L101.44,430a.48.48,0,0,1-.06-.46l2.83-7.33-7.53,2.22a.51.51,0,0,1-.46-.1l-6.09-4.95-.23,7.85a.49.49,0,0,1-.23.41Zm6.32-4.68h0Z"
          style={{ fill: '#263238' }}
        ></path>
        <polygon
          points="375.61 428.63 377.08 440.53 387.73 446.07 376.86 451.15 374.89 462.98 366.7 454.22 354.83 456 360.64 445.5 355.28 434.77 367.06 437.04 375.61 428.63"
          style={{ fill: '#fff' }}
        ></polygon>
        <path
          d="M374.89,463.48a.52.52,0,0,1-.37-.16l-8-8.57-11.6,1.74a.5.5,0,0,1-.49-.21.53.53,0,0,1,0-.53l5.67-10.26L354.83,435a.53.53,0,0,1,.05-.53.5.5,0,0,1,.5-.18l11.51,2.22,8.37-8.23a.48.48,0,0,1,.51-.11.51.51,0,0,1,.34.41l1.44,11.64L388,445.62a.5.5,0,0,1,0,.9l-10.63,5-1.93,11.57a.5.5,0,0,1-.35.4Zm-8.19-9.76a.45.45,0,0,1,.36.16l7.5,8,1.81-10.83a.49.49,0,0,1,.28-.37l10-4.66L376.85,441a.52.52,0,0,1-.26-.39l-1.35-10.89-7.83,7.7a.52.52,0,0,1-.45.13l-10.78-2.08,4.91,9.82a.52.52,0,0,1,0,.47l-5.32,9.61,10.86-1.63Z"
          style={{ fill: '#263238' }}
        ></path>
      </g>
    </svg>
  );
};

export const BootstrapSidebarExample1 = () => {
  return (
    <Container>
      <CDBSidebar>
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>Contrast</CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Components</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="bold" iconType="solid">
              Bootstrap
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div className="sidebar-btn-wrapper" style={{ padding: '20px 5px' }}>
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};

export const BootstrapSidebarExample2 = () => {
  return (
    <Container>
      <CDBSidebar textColor="#333" backgroundColor="#f0f0f0">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>Contrast Light</CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Components</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="bold" iconType="solid">
              Bootstrap
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div className="sidebar-btn-wrapper" style={{ padding: '20px 5px' }}>
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};

export const BootstrapSidebarExample3 = () => {
  return (
    <Container>
      <CDBSidebar>
        <CDBSidebarHeader prefix={<CDBIcon icon="bars" size="lg" />}>Multi Level</CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Components</CDBSidebarMenuItem>
          </CDBSidebarMenu>
          <CDBSidebarMenu>
            <CDBSidebarSubMenu title="Components" icon="th">
              <CDBSidebarMenuItem>Accordion</CDBSidebarMenuItem>
              <CDBSidebarMenuItem>Button</CDBSidebarMenuItem>
              <CDBSidebarMenuItem>Sidebar</CDBSidebarMenuItem>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Layouts" icon="book">
              <CDBSidebarMenuItem>Containers</CDBSidebarMenuItem>
              <CDBSidebarMenuItem>Themes</CDBSidebarMenuItem>
              <CDBSidebarMenuItem>Colors</CDBSidebarMenuItem>
            </CDBSidebarSubMenu>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div className="sidebar-btn-wrapper" style={{ padding: '20px 5px' }}>
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};

export const BootstrapSidebarExample4 = () => {
  return (
    <Container>
      <CDBSidebar>
        <CDBSidebarHeader prefix={<CDBIcon icon="bars" size="lg" />}>Multi Level</CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Components</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="bold">Bootstrap</CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarCTA
          mode="dark"
          text="This sidebar contains a CTA component. You can add anything you want here"
        ></CDBSidebarCTA>
        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div className="sidebar-btn-wrapper" style={{ padding: '20px 5px' }}>
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </Container>
  );
};
