import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
// import Link from './link';
import config from '../../config';
import { Sidebar, ListItem, ListItem2 } from './styles/Sidebar';
// import image from './images/Artboard02.png';
import './adstyle.css';
import adImage from './images/windframe-poster-image.png';


const SidebarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
              }
              tableOfContents
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      let finalNavItems;

      if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
        allMdx.edges.forEach((item) => {
          let innerItems;

          let secondInnerItems = [];

          if (item !== undefined) {
            if (
              '/docs' + item.node.fields.slug === location.pathname ||
              config.gatsby.pathPrefix + '/docs' + item.node.fields.slug === location.pathname
            ) {
              if (item.node.tableOfContents.items) {
                innerItems = item.node.tableOfContents.items.map((innerItem, index) => {
                  secondInnerItems = [];
                  if (innerItem.items) {
                    innerItem.items.forEach((secondInnerItem, index) => {
                      const itemId = secondInnerItem.title
                        ? secondInnerItem.title.replace(/\s+/g, '').toLowerCase()
                        : '#';

                      let innerList = (
                        <ListItem key={index + secondInnerItem.title} to={`#${itemId}`} level={2}>
                          {secondInnerItem.title}
                        </ListItem>
                      );

                      secondInnerItems.push(innerList);
                    });
                  }
                  const itemId = innerItem.title
                    ? innerItem.title.replace(/\s+/g, '').toLowerCase()
                    : '#';

                  return (
                    <ul key={index + 1}>
                      <ul to={`#${itemId}`}>
                        <ListItem2
                          to={`#${itemId}`}
                          level={3}
                          title={innerItem.title}
                          className="headli"
                        >
                          <ul style={{ paddingLeft: '10px' }}>{secondInnerItems}</ul>
                        </ListItem2>
                      </ul>
                    </ul>
                  );
                });
              }
            }
          }
          if (innerItems) {
            finalNavItems = innerItems;
          }
        });
      }

      if (finalNavItems && finalNavItems.length) {
        return (
          <Sidebar>
            <div className="max-h-28">
            <script
              async
              type="text/javascript"
              src="//cdn.carbonads.com/carbon.js?serve=CESIEKQI&placement=wwwdevwarescom"
              id="_carbonads_js"
              ></script>
            </div>
            <ul className={'rightSideBarUL'}>
              <li className={'rightSideTitle'}>CONTENTS</li>
              {finalNavItems}
              {/* <a href="/product/contrast-pro/">
                <img src={image} alt="ad-banner" />
              </a> */}
            </ul>
            <br />
            <div className="md:pr-6 md:pl-4">
              <h4 className="font-bold text-lg">Ship Beautiful UIs 10x Faster Using AI.</h4>
              <a href="https://windframe.dev">
                <img alt="windframe-ad" src={adImage} className="my-2 h-52" />
              </a>
              <p>Use Windframe to visually build webpages in minutes using tailwind css.</p>
              <p>
                Generate HTML, Angular, React, Vue code for your websites on the fly and spend
                less time centering divs 🙂
              </p>
              <a href="https://windframe.dev">
                <button className=" hover:bg-indigo-700 text-white font-bold py-2 mt-2 px-3 rounded" style={{ backgroundColor: '#3329e0' }}>Try it</button>
              </a>
            </div>
          </Sidebar>
        );
      } else {
        return (
          <Sidebar>
            <ul></ul>
          </Sidebar>
        );
      }
    }}
  />
);

export default SidebarLayout;
