import React from 'react';

export const Slider1 = () => {
  return (
    <>
      <label for="customRange1">Example range</label>
      <input type="range" class="custom-range" id="customRange1" min="0" max="100" value="50" />

      <label for="customRange2">Example range</label>
      <input
        type="range"
        class="custom-range thumb-danger"
        id="customRange2"
        min="0"
        max="100"
        value="50"
      />

      <label for="customRange3">Example range</label>
      <input
        type="range"
        class="custom-range thumb-success bg-dark"
        id="customRange3"
        min="0"
        max="100"
        value="50"
      />
    </>
  );
};
