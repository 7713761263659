import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { CDBBtn } from 'cdbreact-pro';
import Highlight, { defaultProps } from 'prism-react-renderer';
import vsDark from 'prism-react-renderer/themes/duotoneDark';

const CodeStyles = styled('div')`
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  width: 100%;
  .gatsby-highlight {
    position: relative;
    font-size: 1rem;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    * {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .gatsby-highlight pre[class*='language-'] {
    -webkit-overflow-scrolling: touch;
    padding: 1rem 0.75rem;
  }
  .gatsby-highlight pre[class*='language-']::before {
    background: black;
    border-radius: 0 0 0.25rem 0.25rem;
    color: white;
    font-size: 12px;
    letter-spacing: 0.025rem;
    padding: 0.1rem 0.5rem;
    position: absolute;
    right: 1rem;
    text-align: right;
    text-transform: uppercase;
    top: 0;
  }
  .prism-code {
    background: ${props => props.theme.codeBlocks.background} !important;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 400px;
    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
    &::-webkit-scrollbar-track {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.codeBlocks.scrollbarThumbBackground} !important;
      border-radius: 0.375rem;
      border-width: 4px;
      border-style: solid;
      border-color: ${props => props.theme.codeBlocks.scrollbarBorderColor} !important;
    }
    .token.keyword {
      color: ${props => props.theme.codeBlocks.keyword} !important;
    }
    .token.attr-value {
      color: ${props => props.theme.codeBlocks['attr-value']} !important;
    }
    .token.tag {
      color: ${props => props.theme.codeBlocks['tag']} !important;
    }
    .token.imports {
      color: ${props => props.theme.codeBlocks.imports} !important;
    }
    .token.attr-name {
      color: ${props => props.theme.codeBlocks['attr-name']} !important;
    }
    .token.string {
      color: ${props => props.theme.codeBlocks.string} !important;
    }
    .token.function {
      color: ${props => props.theme.codeBlocks.function} !important;
    }
    .token.plain {
      color: ${props => props.theme.codeBlocks.plain} !important;
    }
    .token.punctuation,
    .token.operator {
      color: ${props => props.theme.codeBlocks.punctuation} !important;
    }
  }
  .gatsby-highlight-header {
    font-size: 0.875em;
    margin-top: 1.7142857em;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    color: rgb(64, 63, 83);
    background: ${props => props.theme.codeBlocks.headerBackground} !important;
    border-bottom: 1px solid rgba(107, 111, 117, 0.15);
    padding-top: 0.4285714em;
    padding-right: 1.1428571em;
    padding-bottom: 0.4285714em;
    padding-left: 1.1428571em;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-direction: row;
    .copy-to-clipboard {
      border-radius: 6px;
      background: transparent !important;
      padding: 4px 10px;
      font-weight: 300;
      font-size: 12px;
      border: 1px solid ${props => props.theme.codeBlocks.copyToClipboard.color};
      color: ${props => props.theme.codeBlocks.copyToClipboard.color} !important;
      margin: 0 5px;
    }
    .edit-on-github {
      border-radius: 6px;
      padding: 4px 10px;
      background: transparent !important;
      border-radius: 0.375rem;
      font-weight: 300;
      font-size: 12px;
      border: 1px solid ${props => props.theme.codeBlocks.editOnGithub.color};
      color: ${props => props.theme.codeBlocks.editOnGithub.color} !important;
      margin: 0 5px;
      a {
        text-decoration: none;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
      }
    }
    .language-display {
      border-radius: 4px;
      padding: 4px 10px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      margin-right: 10px;
    }
    .language-display[data-lang='html'] {
      background: ${props => props.theme.baseColors.primary};
      color: #fff;
    }
    .language-display[data-lang='js'] {
      background: ${props => props.theme.baseColors.warning};
      color: #333;
    }

    .language-display[data-lang='bash'] {
      background: ${props => props.theme.baseColors.success};
      color: #fff;
    }
    .language-display[data-lang='css'] {
      background: #ff9800;
      color: #333;
    }
  }
`;
const Code = ({ codeString, language, link }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      navigator.clipboard.writeText(codeString);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <Highlight {...defaultProps} code={codeString} language={language} theme={vsDark}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <CodeStyles>
          <div className=" gatsby-highlight-header ">
            <div className="language-display lkrts-xkyw6z" data-lang={language}>
              {language}
            </div>
            {link && (
              <CDBBtn flat type="button" className="edit-on-github btn" circle size="small">
                <a href={`https://${link}`} target="_blank" rel="noopener noreferrer">
                  Edit on Github
                </a>
              </CDBBtn>
            )}
            <CDBBtn
              type="button"
              className="copy-to-clipboard btn"
              size="small"
              flat
              circle
              onClick={() => setCopied(true)}
            >
              {copied ? 'Copied' : 'Copy'}
            </CDBBtn>
          </div>
          <div className="gatsby-highlight" data-language={language}>
            <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })} key={i}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} key={key} />
                  ))}
                </div>
              ))}
            </pre>
          </div>
        </CodeStyles>
      )}
    </Highlight>
  );
};

export default Code;
