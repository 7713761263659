import React from "react";
import { Link } from "gatsby";
import notification from "../../../../content/contrast/react/components/images/notification1.png";
import notification2 from "../../../../content/contrast/react/components/images/notification2.png";
import notification3 from "../../../../content/contrast/react/components/images/notification3.png";
import notification4 from "../../../../content/contrast/react/components/images/notification4.png";
export default {
  React,
  Link,
  notification,
  notification2,
  notification3,
  notification4
};