import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin: 10px;
  }
`;

export const ButtonToolbar1 = () => {
  return (
    <Container>
      <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
        <div class="btn-group me-2" role="group" aria-label="First group">
          <button type="button" class="btn btn-primary bg-blue-500">
            1
          </button>
          <button type="button" class="btn btn-secondary bg-blue-400">
            2
          </button>
          <button type="button" class="btn btn-success bg-green-500">
            3
          </button>
        </div>
        <div class="btn-group me-2" role="group" aria-label="Second group">
          <button type="button" class="btn btn-primary bg-blue-500">
            4
          </button>
          <button type="button" class="btn btn-secondary bg-blue-400">
            5
          </button>
          <button type="button" class="btn btn-success bg-green-500">
            6
          </button>
        </div>
        <div class="btn-group" role="group" aria-label="Third group">
          <button type="button" class="btn btn-danger bg-red-500">
            7
          </button>
          <button type="button" class="btn btn-dark bg-black">
            8
          </button>
          <button type="button" class="btn btn-warning bg-yellow-500">
            9
          </button>
        </div>
      </div>
    </Container>
  );
};
