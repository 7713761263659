import React from 'react';

export const Form1 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center mt-4 mb-4">
            <p class="h4"> Sign in </p>
          </div>
          <div class="cs-form">
            <input type="email" class="form-control border-top-0 border-start-0 border-end-0" />
            <label>E-mail</label>
          </div>
          <div class="cs-form">
            <input type="password" class="form-control border-top-0 border-start-0 border-end-0" />
            <label>Password</label>
          </div>
          <div class="d-flex flex-wrap justify-content-center align-items-center">
            <input type="checkbox" class="me-1" />
            <p class="m-0">Remember me</p>
            <a class="text-decoration-none ms-3" href="#">
              Forgot Password ?
            </a>
          </div>
          <button class="btn btn-dark btn-block my-3">Sign in</button>
          <p class="text-center">
            Not a member?{' '}
            <a class="text-decoration-none" href="#">
              Register
            </a>
          </p>
          <p class="text-center"> or sign in with</p>
          <div class="my-3 d-flex justify-content-center">
            <button class="m-0 btn btn-flat">
              <i class="fab fa-facebook-f"></i>
            </button>
            <button class="m-0 btn btn-flat">
              <i class="fab fa-twitter"></i>
            </button>
            <button class="m-0 btn btn-flat">
              <i class="fab fa-google-plus-g"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const Form2 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="text-center bg-black text-white">
          <p class="h5 mt-2 py-4 font-weight-bold">Sign in</p>
        </div>
        <div class="mx-4 mt-4 p-3">
          <div class="cs-form">
            <input type="email" class="form-control" />
            <label>E-mail</label>
          </div>
          <div class="cs-form">
            <input type="password" class="form-control" />
            <label>Password</label>
          </div>
          <div class="mt-5 d-flex flex-wrap justify-content-center align-items-center">
            <input type="checkbox" class="me-2" />
            <p class="m-0">Remember me</p>
            <a href="#" class="text-decoration-none ms-3">
              Forgot Password ?
            </a>
          </div>
          <button class="btn btn-block btn-outline-dark my-3">Sign in</button>
          <p class="text-center">
            Not a member?{' '}
            <a class="text-decoration-none" href="#">
              Register
            </a>
          </p>
          <p class="text-center">or sign in with</p>
          <div class="my-3 d-flex justify-content-center">
            <button class="btn m-0">
              <i class="fab fa-facebook-f"></i>
            </button>
            <button class="btn m-0">
              <i class="fab fa-twitter"></i>
            </button>
            <button class="btn m-0">
              <i class="fab fa-linkedin-in"></i>
            </button>
            <button class="btn m-0">
              <i class="fab fa-github"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const Form3 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center mt-4 mb-2">
            <p class="h4 mb-5">Sign up</p>
          </div>
          <div class="row mb-n4">
            <div class="col">
              <div class="cs-form">
                <input
                  type="email"
                  class="form-control border-top-0 border-start-0 border-end-0"
                />
                <label>First Name</label>
              </div>
            </div>
            <div class="col">
              <div class="cs-form">
                <input type="text" class="form-control border-top-0 border-start-0 border-end-0" />
                <label>Last Name</label>
              </div>
            </div>
            <div class="cs-form">
              <input type="email" class="form-control border-top-0 border-start-0 border-end-0" />
              <label>E-mail</label>
            </div>
            <div class="cs-form">
              <input
                type="password"
                class="form-control border-top-0 border-start-0 border-end-0"
              />
              <label>Password</label>
            </div>
            <p class="text-muted text-center small">At least 8 characters and 1 digit</p>
            <div class="cs-form">
              <input type="text" class="form-control border-top-0 border-start-0 border-end-0" />
              <label>Phone number</label>
            </div>
            <p class="text-muted text-center small">Optional - for two step authentication</p>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <input class="me-2" type="checkbox" />
            <p class="m-0">Subscribe to our newsletter</p>
          </div>
          <button class="btn btn-block my-3 btn-dark">Sign up</button>
          <p class="text-center">or sign up with</p>
          <div class="mb-3 d-flex justify-content-center">
            <button class="m-0 btn btn-flat">
              <i class="fab fa-facebook-f"></i>
            </button>
            <button class="m-0 btn btn-flat">
              <i class="fab fa-twitter"></i>
            </button>
            <button class="m-0 btn btn-flat">
              <i class="fab fa-google-plus-g"></i>
            </button>
          </div>
          <p class="text-center m-0">
            Already have an account?{' '}
            <a class="text-decoration-none" href="#">
              Sign In
            </a>
          </p>
          <hr />
          <p class="text-center">
            By clicking <em>Sign up</em> you agree to our
            <a class="text-decoration-none" href="#">
              terms of service
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export const Form4 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="text-center bg-black text-white">
          <p class="h5 mt-2 py-4 font-weight-bold">Sign up</p>
        </div>
        <div class="mx-4 mt-4 p-3">
          <div class="row mb-n4">
            <div class="col">
              <div class="cs-form">
                <input type="email" class="form-control" />
                <label>First Name</label>
              </div>
            </div>
            <div class="col">
              <div class="cs-form">
                <input type="text" class="form-control" />
                <label>Last Name</label>
              </div>
            </div>
            <div class="cs-form">
              <input type="email" class="form-control" />
              <label>E-mail</label>
            </div>
            <div class="cs-form">
              <input type="password" class="form-control" />
              <label>Password</label>
            </div>
            <p class="text-muted text-center small">At least 8 characters and 1 digit</p>
            <div class="cs-form">
              <input type="text" class="form-control" />
              <label>Phone number</label>
            </div>
            <p class="text-muted text-center small">Optional - for two step authentication</p>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <input class="me-2" type="checkbox" />
            <p class="m-0">Subscribe to our newsletter</p>
          </div>
          <button class="btn btn-block my-3 btn-outline-dark">Sign up</button>
          <p class="text-center">or sign up with</p>
          <div class="mb-3 d-flex justify-content-center">
            <button class="m-0 btn">
              <i class="fab fa-facebook-f"></i>
            </button>
            <button class="m-0 btn">
              <i class="fab fa-twitter"></i>
            </button>
            <button class="m-0 btn">
              <i class="fab fa-linkedin-in"></i>
            </button>
            <button class="m-0 btn">
              <i class="fab fa-github"></i>
            </button>
          </div>
          <p class="text-center m-0">
            Already have an account?{' '}
            <a class="text-decoration-none" href="#">
              Sign In
            </a>
          </p>
          <hr />
          <p class="text-center">
            By clicking <em>Sign up</em> you agree to our
            <a class="text-decoration-none" href="#">
              terms of service
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export const Form5 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center mt-4 mb-2">
            <p class="h4">Subscribe</p>
          </div>
          <p class="text-center mt-4 font-weight-light">
            Join our mailing list. We write rarely, but only the best content.
          </p>
          <p class="text-center">
            <a class="font-weight-light text-decoration-none mb-4" href="#">
              See the last newsletter
            </a>
          </p>
          <div class="cs-form">
            <input type="text" class="form-control border-top-0 border-start-0 border-end-0" />
            <label>Name</label>
          </div>
          <div class="cs-form">
            <input type="email" class="form-control border-top-0 border-start-0 border-end-0" />
            <label>E-mail</label>
          </div>
          <button class="btn btn-dark btn-block my-3">Subscribe</button>
        </div>
      </div>
    </>
  );
};

export const Form6 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="text-center bg-black text-white">
          <p class="h5 mt-2 py-4 font-weight-bold">Subscribe</p>
        </div>
        <div class="mx-4 p-3">
          <p class="text-center mt-2">
            Join our mailing list. We write rarely, but only the best content.
          </p>
          <p class="text-center">
            <a class="text-decoration-none" href="#">
              See the last newsletter
            </a>
          </p>
          <div class="cs-form">
            <input type="text" class="form-control" />
            <label>Name</label>
          </div>
          <div class="cs-form">
            <input type="email" class="form-control" />
            <label>E-mail</label>
          </div>
          <button class="btn btn-block btn-outline-dark my-3">Subscribe</button>
        </div>
      </div>
    </>
  );
};

export const Form7 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center mt-4 mb-5">
            <p class="h4">Contact us</p>
          </div>
          <div class="cs-form">
            <input type="text" class="form-control border-top-0 border-start-0 border-end-0" />
            <label>Name</label>
          </div>
          <div class="cs-form">
            <input type="email" class="form-control border-top-0 border-start-0 border-end-0" />
            <label>E-mail</label>
          </div>
          <p class="text-center m-0">Subject</p>
          <select class="select w-100">
            <option>Resources</option>
            <option>Docs</option>
            <option>Contact</option>
          </select>
          <div class="cs-form mt-4">
            <textarea class="form-control border-top-0 border-start-0 border-end-0"></textarea>
            <label>Message</label>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <input class="me-2" type="checkbox" />
            <p class="m-0">Send me a copy of this message</p>
          </div>
          <button class="btn btn-block btn-dark my-3">Send</button>
        </div>
      </div>
    </>
  );
};

export const Form8 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="text-center bg-black text-white mb-4">
          <p class="h5 mt-2 py-4 font-weight-bold">Contact Us</p>
        </div>
        <div class="mx-4 p-3">
          <div class="cs-form">
            <input type="text" class="form-control" />
            <label>Name</label>
          </div>
          <div class="cs-form">
            <input type="email" class="form-control" />
            <label>E-mail</label>
          </div>
          <p class="text-center m-0 text-muted">Subject</p>
          <select class="select w-100">
            <option>Resources</option>
            <option>Docs</option>
            <option>Contact</option>
          </select>
          <div class="cs-form mt-4">
            <textarea class="form-control"></textarea>
            <label>Message</label>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <input class="me-2" type="checkbox" />
            <p class="m-0">Send me a copy of this message</p>
          </div>
          <button class="btn btn-block btn-outline-dark my-3">Send</button>
        </div>
      </div>
    </>
  );
};

export const Form9 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center my-4">
            <p class="h4 font-weight-bold">Sign in</p>
          </div>
          <div class="cs-form">
            <i class="fa fa-user prefix"></i>
            <input type="email" class="form-control" />
            <label>E-mail</label>
          </div>
          <div class="cs-form">
            <i class="fa fa-lock prefix"></i>
            <input type="password" class="form-control" />
            <label>Password</label>
          </div>
          <button class=" w-2/5 btn btn-success btn-block mt-5 mx-auto">Login</button>
        </div>
      </div>
    </>
  );
};

export const Form10 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center mt-4 mb-4">
            <p class="h4">Sign in</p>
          </div>
          <div>
            <label htmlFor="defaultLoginEmail" class="text-muted">
              Your email
            </label>
            <input type="email" id="defaultLoginEmail" class="form-control" />
          </div>
          <div class="mt-3">
            <label htmlFor="defaultLoginPassword" class="text-muted">
              Your password
            </label>
            <input type="password" id="defaultLoginPassword" class="form-control" />
          </div>
          <button class="btn btn-primary btn-block mt-5 mx-auto w-2/5">Login</button>
        </div>
      </div>
    </>
  );
};

export const Form11 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center my-4">
            <p class="h4 font-weight-bold">Sign in</p>
          </div>
          <div class="cs-form">
            <i class="fa fa-user prefix"></i>
            <input type="text" class="form-control" />
            <label>Your name</label>
          </div>
          <div class="cs-form">
            <i class="fa fa-envelope prefix"></i>
            <input type="email" class="form-control" />
            <label>Your Email</label>
          </div>
          <div class="cs-form">
            <i class="fa fa-exclamation-triangle prefix"></i>
            <input type="email" class="form-control" />
            <label>Confirm your Email</label>
          </div>
          <div class="cs-form">
            <i class="fa fa-lock prefix"></i>
            <input type="password" class="form-control" />
            <label>Your Password</label>
          </div>
          <button class="w-2/5 btn btn-success btn-block mt-5 mx-auto">Register</button>
        </div>
      </div>
    </>
  );
};

export const Form12 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center mt-4 mb-4">
            <p class="h4">Sign in</p>
          </div>
          <div>
            <label htmlFor="defaultLoginName" class="text-muted">
              Your name
            </label>
            <input type="text" id="defaultLoginName" class="form-control" />
          </div>
          <div class="mt-3">
            <label htmlFor="defaultLoginEmail" class="text-muted">
              Your email
            </label>
            <input type="email" id="defaultLoginEmail" class="form-control" />
          </div>
          <div class="mt-3">
            <label htmlFor="confirmLoginEmail" class="text-muted">
              Confirm your Email
            </label>
            <input type="email" id="confirmLoginEmail" class="form-control" />
          </div>
          <div class="mt-3">
            <label htmlFor="defaultLoginPassword" class="text-muted">
              Your password
            </label>
            <input type="password" id="defaultLoginPassword" class="form-control" />
          </div>
          <button class="w-2/5 btn btn-danger btn-block mt-5 mx-auto">Register</button>
        </div>
      </div>
    </>
  );
};

export const Form13 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center my-4">
            <p class="h4 font-weight-bold">Subscribe</p>
          </div>
          <div class="cs-form">
            <i class="fa fa-user prefix"></i>
            <input type="text" class="form-control" />
            <label>Your Name</label>
          </div>
          <div class="cs-form">
            <i class="fa fa-lock prefix"></i>
            <input type="email" class="form-control" />
            <label>Your Email</label>
          </div>
          <button class="w-2/5 btn btn-outline-primary btn-block mt-5 mx-auto">
            Send
            <i class="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export const Form14a = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center mt-4 mb-4">
            <p class="h4">Subscribe</p>
          </div>
          <div>
            <label htmlFor="defaultLoginPassword" class="text-muted">
              Your Name
            </label>
            <input type="text" id="defaultLoginPassword" class="form-control" />
          </div>
          <div class="mt-3">
            <label htmlFor="defaultLoginEmail" class="text-muted">
              Your email
            </label>
            <input type="email" id="defaultLoginEmail" class="form-control" />
          </div>
          <button class=" w-2/5 btn btn-outline-danger btn-block mt-5 mx-auto">
            Send
            <i class="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export const Form15 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center my-4">
            <p class="h4 font-weight-bold">Write to us</p>
          </div>
          <div class="cs-form">
            <i class="fa fa-user prefix"></i>
            <input type="text" class="form-control" />
            <label>Your name</label>
          </div>
          <div class="cs-form">
            <i class="fa fa-envelope prefix"></i>
            <input type="email" class="form-control" />
            <label>Your Email</label>
          </div>
          <div class="cs-form">
            <i class="fa fa-tags prefix"></i>
            <input type="text" class="form-control" />
            <label>Subject</label>
          </div>
          <div class="cs-form">
            <i class="fa fa-envelope prefix"></i>
            <input type="text" class="form-control" />
            <label>Your Message</label>
          </div>
          <button class="w-2/5 btn btn-outline-secondary btn-block mt-5 mx-auto">
            Send
            <i class="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export const Form16 = () => {
  return (
    <div class="border d-flex flex-column">
      <div class="mx-4 p-3">
        <div class="text-center mt-4 mb-4">
          <p class="h4">Write to us</p>
        </div>
        <div>
          <label htmlFor="defaultLoginName" class="text-muted">
            Your name
          </label>
          <input type="text" id="defaultLoginName" class="form-control" />
        </div>
        <div class="mt-3">
          <label htmlFor="defaultLoginEmail" class="text-muted">
            Your email
          </label>
          <input type="email" id="defaultLoginEmail" class="form-control" />
        </div>
        <div class="mt-3">
          <label htmlFor="confirmLoginEmail" class="text-muted">
            Subject
          </label>
          <input type="text" id="confirmLoginEmail" class="form-control" />
        </div>
        <div class="mt-3">
          <label htmlFor="defaultLoginPassword" class="text-muted">
            Your message
          </label>
          <textarea class="form-control"></textarea>
        </div>
        <button class="w-2/5 btn btn-outline-danger btn-block mt-5 mx-auto">
          Send
          <i class="fa fa-paper-plane"></i>
        </button>
      </div>
    </div>
  );
};

export const Form17 = () => {
  return (
    <>
      <div class="border d-flex flex-column">
        <div class="mx-4 p-3">
          <div class="text-center my-4">
            <p class="h4 font-weight-bold">Sign in</p>
          </div>
          <div class="cs-form">
            <input type="email" class="form-control" />
            <label>E-mail</label>
          </div>
          <div class="cs-form">
            <input type="password" class="form-control" />
            <label>Password</label>
          </div>
          <p class="mt-n3 text-end">
            <a class="text-decoration-none" href="#">
              Forgot Password ?
            </a>
          </p>
          <button class="btn btn-dark btn-block my-4 mx-0">Sign in</button>
          <p class="text-center">or sign in with</p>
          <div class="my-3 d-flex justify-content-center">
            <button class="m-0 btn">
              <i class="fab fa-facebook-f"></i>
            </button>
            <button class="m-0 btn">
              <i class="fab fa-twitter"></i>
            </button>
            <button class="m-0 btn">
              <i class="fab fa-google-plus-g"></i>
            </button>
          </div>
          <hr />
          <p class="text-center">
            Not a member?{' '}
            <a class="text-decoration-none" href="#">
              Sign up
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
