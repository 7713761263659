import React from 'react';

import { CDBAutocomplete, CDBContainer } from 'cdbreact-pro';

export const Autocomplete1 = () => {
  const choices = [
    'ActionScript',
    'AppleScript',
    'Asp',
    'Assembly',
    'BASIC',
    'Batch',
    'C',
    'C++',
    'CSS',
    'Clojure',
    'COBOL',
    'ColdFusion',
    'Erlang',
    'Fortran',
    'Groovy',
    'Haskell',
    'HTML',
    'Java',
    'JavaScript',
    'Lisp',
    'Perl',
    'PHP',
    'PowerShell',
    'Python',
    'Ruby',
    'Scala',
    'Scheme',
    'SQL',
    'TeX',
    'XML',
  ];
  return (
    <CDBContainer>
      <CDBAutocomplete label="Which country are you from" suggestions={choices} />
    </CDBContainer>
  );
};
