export const WindframeCompoenentPreview = function({ children, windframeLink }) {
  if (!children) return null;

  return (
    <section className=" w-full overflow-auto rounded-lg border">
      <header className="flex justify-end border-b bg-gray-100 p-2">
        <a
          href={windframeLink}
          disabled={!windframeLink}
          target="_blank"
          rel="noopener noreferrer"
          className={`${
            windframeLink ? 'cursor-pointer' : 'cursor-not-allowed'
          } rounded-md bg-white p-2 text-sm flex items-center gap-1 !text-black`}
        >
          <svg
            fill="#000000"
            className="w-5 h-5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.661,19.113,3,21l1.887-5.661ZM20.386,7.388a2.1,2.1,0,0,0,0-2.965l-.809-.809a2.1,2.1,0,0,0-2.965,0L6.571,13.655l3.774,3.774Z" />
          </svg>
          Edit on Windframe{' '}
        </a>
      </header>
      <article className="p-2 flex-1 items-center justify-center h-full !min-h-[20rem] overflow-auto mx-auto">
        {children}
      </article>
    </section>
  );
};
