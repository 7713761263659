import React, { useRef } from 'react';
import firebase from 'gatsby-plugin-firebase';
import NotificationSystem from 'react-notification-system';
import { FooterRow, Button } from './Footer.styles';
import { StyledFooter } from './Footer.styles';
import logo from '../images/logo.png';

export const Footer = () => {
  const db = firebase.firestore();

  const notificationSystem = useRef();
  const emailInput = useRef();

  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const email = emailInput.current.value;

    let docRef = db.collection('newsletter');

    docRef
      .add({ email })
      .catch(error => {
        addNotification({
          title: 'Error',
          message: 'Subscription Unsuccessful',
          level: 'error',
        });
      })
      .then(() => {
        addNotification({
          title: 'Email Added',
          message: 'Your email has been added for newsletter updates',
          level: 'success',
        });
      });
  };

  return (
    <StyledFooter>
      <div className="container">
        <NotificationSystem ref={notificationSystem} />
        <FooterRow className="row">
          <div className="col-sm-12 col-md-12 footer-main newsletter">
            <h5>Subscribe to our Newsletter</h5>
            <p style={{ marginBottom: '-22px' }}>
              Get notified first when we release new updates, tools and resources
            </p>
            <form
              data-netlify="true"
              netlify-honeypot="bot-field"
              name="newsletter"
              method="post"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="newsletter" />
              <input
                type="email"
                ref={emailInput}
                placeholder="Enter your email"
                name="email"
                className="bg-gray-800 border !border-gray-700"
              />
              <Button type="submit" className="bg-gray-800 text-white">Join</Button>
            </form>
          </div>
          <div className="col-sm-12 col-md-4 footer-main">
            <div className="flex items-center">
              <img src={logo} alt="devwares" />
              <div className="footer-title">Devwares</div>
            </div>
            <div className="footer-description">
              <span>
                We are creating high quality resources and tools to aid developers during the
                development of their projects
              </span>
            </div>
            <div className="footer-rights">@devwares 2024 All rights Reserved</div>
          </div>
          <div className="col-sm-12 col-md-5 footer-menu">
            <div className="row">
              <div className="col-4 footer-list">
                <h2>Devwares</h2>
                <ul className="p-0">
                 
                  <li>
                    <a href="/resources">Resources</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                </ul>
              </div>
              <div className="col-4 footer-list">
                <h2>Help</h2>
                <ul className="p-0">
                  <li>
                    <a href="/contact">Support</a>
                  </li>
                  <li>
                    <a href="/app2/signup">Sign up</a>
                  </li>
                  <li>
                    <a href="/app2/signin">Sign In</a>
                  </li>
                </ul>
              </div>
              <div className="col-4 footer-list">
                <h2>Legal</h2>
                <ul className="p-0">
                  <li>
                    <a href="/privacy-policy">Privacy and Security</a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions">Terms of Service</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 footer-socials">
            <div>
              <a
                href="https://www.producthunt.com/posts/windframe?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-windframe"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=303580&theme=light"
                  alt="Windframe - No code TailwindCSS page builder | Product Hunt"
                  style={{ width: '250px', height: '54px' }}
                />
              </a>
            </div>
            <h2>Social media</h2>
            <ul className="p-0">
              <li>
                <a
                  href="https://www.facebook.com/Devwares-102291481719158/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/devwares?s=09" target="_blank" rel="noreferrer">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/devwares" target="_blank" rel="noreferrer">
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/devwares"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </FooterRow>
      </div>
    </StyledFooter>
  );
};
