import React from 'react';

export const Radio1 = () => {
  return (
    <>
      <div class="custom-radio">
        <input type="radio" id="customRadio1" name="customRadio" class="custom-radio-input" />
        <label class="custom-control-label" for="customRadio1">
          radio1
        </label>
      </div>
      <div class="custom-radio">
        <input type="radio" id="customRadio2" name="customRadio" class="custom-radio-input" />
        <label class="custom-control-label" for="customRadio2">
          radio2
        </label>
      </div>
      <div class="custom-radio">
        <input type="radio" id="customRadio3" name="customRadio" class="custom-radio-input" />
        <label class="custom-control-label" for="customRadio3">
          radio3
        </label>
      </div>
      <div class="custom-radio">
        <input type="radio" id="customRadio4" name="customRadio" class="custom-radio-input" />
        <label class="custom-control-label" for="customRadio4">
          radio4
        </label>
      </div>
    </>
  );
};

export const Radio2 = () => {
  return (
    <>
      <div class="custom-radio">
        <input
          type="radio"
          id="customRadio5"
          name="customRadio2"
          class="custom-radio-input text-info"
        />
        <label class="custom-control-label" for="customRadio5"></label>
      </div>
      <div class="custom-radio">
        <input
          type="radio"
          id="customRadio6"
          name="customRadio3"
          class="custom-radio-input text-danger"
        />
        <label class="custom-control-label" for="customRadio6"></label>
      </div>
      <div class="custom-radio">
        <input
          type="radio"
          id="customRadio7"
          name="customRadi4"
          class="custom-radio-input text-primary"
        />
        <label class="custom-control-label" for="customRadio7"></label>
      </div>
      <div class="custom-radio">
        <input
          type="radio"
          id="customRadio8"
          name="customRadi5"
          class="custom-radio-input text-dark"
        />
        <label class="custom-control-label" for="customRadio8"></label>
      </div>
    </>
  );
};
