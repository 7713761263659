import React, { useState } from 'react';
import styled from '@emotion/styled';

import { CDBBtn, CDBContainer, CDBStepper, CDBStep, CDBInput } from 'cdbreact-pro';

const FlexRowContainer = styled('div')`
  padding: 10px;
  display: flex;
  width: ${props => props.width};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  box-sizing: border-box;
`;
const FlexColumnContainer = styled('div')`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  box-sizing: border-box;
`;

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
`;
const StepContainer = styled('div')`
  width: 100%;
  height: 100%;
`;
export const StepperJs1 = () => {
  const [active, setActive] = useState(1);

  const handleNextPrevClick = a => {
    setActive(a);
  };
  return (
    <Container>
      <CDBStepper direction="vertical" mainColor="#333333">
        <CDBStep
          id={1}
          icon="pencil-alt"
          name="Basic Information"
          handleClick={() => handleNextPrevClick(1)}
          active={active}
          component={<Step1 handleNextPrevClick={handleNextPrevClick} />}
        />
        <CDBStep
          id={2}
          icon="info-circle"
          name="Personal Data"
          handleClick={() => handleNextPrevClick(2)}
          active={active}
          component={<Step2 handleNextPrevClick={handleNextPrevClick} />}
        />
        <CDBStep
          id={3}
          icon="book-reader"
          name="Terms and Conditions"
          handleClick={() => handleNextPrevClick(3)}
          active={active}
          component={<Step3 handleNextPrevClick={handleNextPrevClick} />}
        />
        <CDBStep
          id={4}
          icon="check"
          name="Finish"
          handleClick={() => handleNextPrevClick(4)}
          active={active}
          component={<Step4 handleNextPrevClick={handleNextPrevClick} />}
        />
      </CDBStepper>
    </Container>
  );
};

export const StepperJs2 = () => {
  const [active, setActive] = useState(1);

  const handleNextPrevClick = a => {
    setActive(a);
  };
  return (
    <CDBContainer>
      <CDBStepper>
        <CDBStep
          id={1}
          icon="pencil-alt"
          name="Basic Information"
          handleClick={() => handleNextPrevClick(1)}
          active={active}
          component={<Step1 handleNextPrevClick={handleNextPrevClick} />}
        />
        <CDBStep
          id={2}
          icon="info-circle"
          name="Personal Data"
          handleClick={() => handleNextPrevClick(2)}
          active={active}
          component={<Step2 handleNextPrevClick={handleNextPrevClick} />}
        />
        <CDBStep
          id={3}
          icon="book-reader"
          name="Terms and Conditions"
          handleClick={() => handleNextPrevClick(3)}
          active={active}
          component={<Step3 handleNextPrevClick={handleNextPrevClick} />}
        />
        <CDBStep
          id={4}
          icon="check"
          name="Finish"
          handleClick={() => handleNextPrevClick(4)}
          active={active}
          component={<Step4 handleNextPrevClick={handleNextPrevClick} />}
        />
      </CDBStepper>
    </CDBContainer>
  );
};

export const StepperJs3 = () => {
  const [active, setActive] = useState(1);

  const handleNextPrevClick = a => {
    setActive(a);
  };
  return (
    <CDBContainer>
      <CDBStepper direction="horizontal" showTitle={false} showTooltip={false}>
        <CDBStep
          id={1}
          name="Basic Information"
          handleClick={() => handleNextPrevClick(1)}
          active={active}
          component={<Step1 handleNextPrevClick={handleNextPrevClick} />}
        />
        <CDBStep
          id={2}
          name="Personal Data"
          handleClick={() => handleNextPrevClick(2)}
          active={active}
          component={<Step2 handleNextPrevClick={handleNextPrevClick} />}
        />
        <CDBStep
          id={3}
          name="Terms and Conditions"
          handleClick={() => handleNextPrevClick(3)}
          active={active}
          component={<Step3 handleNextPrevClick={handleNextPrevClick} />}
        />
        <CDBStep
          id={4}
          name="Finish"
          handleClick={() => handleNextPrevClick(4)}
          active={active}
          component={<Step4 handleNextPrevClick={handleNextPrevClick} />}
        />
      </CDBStepper>
    </CDBContainer>
  );
};
const Step4 = ({ handleNextPrevClick }) => {
  return (
    <StepContainer md="12">
      <div style={{ width: '100%', background: '#f9f9f9', padding: '30px 10px', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            maxWidth: '500px',
            borderRadius: '10px',
            background: '#f5f5f5',
            boxShadow: '0px 4px 10px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <FlexColumnContainer>
            <div
              style={{
                textAlign: 'center',
                padding: '20px 0 10px 0',
                color: '#939393',
                fontSize: '30px',
                fontWeight: 'bold',
              }}
            >
              Step 4
            </div>
            <FlexColumnContainer width="100%">
              <div className="text-center" style={{ fontSize: '25px', textAlign: 'center' }}>
                Congratulations! You have completed this process.
                <span style={{ fontSize: '50px', display: 'block' }} role="img" aria-label="image">
                  🎉
                </span>
              </div>
              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
                <CDBBtn
                  flat
                  outline
                  circle={false}
                  color="dark"
                  onClick={() => handleNextPrevClick(3)}
                >
                  Previous
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </div>
    </StepContainer>
  );
};
const Step3 = ({ handleNextPrevClick }) => {
  return (
    <StepContainer>
      <div style={{ width: '100%', background: '#f9f9f9', padding: '30px 10px', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            maxWidth: '500px',
            borderRadius: '10px',
            background: '#f5f5f5',
            boxShadow: '0px 4px 10px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <FlexColumnContainer>
            <div
              style={{
                textAlign: 'center',
                padding: '20px 0 10px 0',
                color: '#939393',
                fontSize: '30px',
                fontWeight: 'bold',
              }}
            >
              Step 3
            </div>
            <FlexColumnContainer width="100%">
              <div
                style={{
                  fontSize: '10px',
                  fontWeight: '200',
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
              >
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui dicta minus
                  molestiae vel beatae natus eveniet ratione temporibus aperiam harum alias officiis
                  assumenda officia quibusdam deleniti eos cupiditate dolore doloribus!
                </p>
                <p>
                  Ad dolore dignissimos asperiores dicta facere optio quod commodi nam tempore
                  recusandae. Rerum sed nulla eum vero expedita ex delectus voluptates rem at neque
                  quos facere sequi unde optio aliquam!
                </p>
                <p>
                  Ad dolore dignissimos asperiores dicta facere optio quod commodi nam tempore
                  recusandae. Rerum sed nulla eum vero expedita ex delectus voluptates rem at neque
                  quos facere sequi unde optio aliquam!
                </p>
                <p>
                  Ad dolore dignissimos asperiores dicta facere optio quod commodi nam tempore
                  recusandae. Rerum sed nulla eum vero expedita ex delectus voluptates rem at neque
                  quos facere sequi unde optio aliquam!
                </p>
              </div>
              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
                <CDBBtn
                  classNameName="float-start"
                  circle={false}
                  color="dark"
                  flat
                  outline
                  onClick={() => handleNextPrevClick(2)}
                >
                  Previous
                </CDBBtn>
                <CDBBtn
                  classNameName="float-end"
                  flat
                  color="dark"
                  circle={false}
                  onClick={() => handleNextPrevClick(4)}
                >
                  Next
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </div>
    </StepContainer>
  );
};
const Step2 = ({ handleNextPrevClick }) => {
  return (
    <StepContainer md="12">
      <div style={{ width: '100%', background: '#f9f9f9', padding: '30px 10px', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            maxWidth: '500px',
            borderRadius: '10px',
            background: '#f5f5f5',
            boxShadow: '0px 4px 10px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <FlexColumnContainer>
            <div
              style={{
                textAlign: 'center',
                padding: '20px 0 10px 0',
                color: '#939393',
                fontSize: '30px',
                fontWeight: 'bold',
              }}
            >
              Step 2
            </div>
            <FlexColumnContainer width="100%"> 
            <label className="text-muted mb-2">
                        First Name
                      </label>
                      <div className="">
                        <input type="text"  className="form-control bg-light " />
                      </div>
                      <label className="text-muted mb-2">
                        Last Name
                      </label>
                      <div className="">
                        <input type="email" className="form-control bg-light " />
                      </div>
                      <label className="text-muted mb-2">
                        Phone Number
                      </label>
                      <div className="">
                        <input type="text"  className="form-control bg-light " />
                      </div>
                      <label className="text-muted mb-2">
                        Address
                      </label>
                      <div className="">
                        <input type="email" className="form-control bg-light " />
                      </div>
                      <label className="text-muted mb-2">
                        Country
                      </label>
                      <div className="">
                        <input type="text"  className="form-control bg-light " />
                      </div>

              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
                <CDBBtn
                  color="dark"
                  flat
                  classNameName="float-start"
                  circle={false}
                  outline
                  onClick={() => handleNextPrevClick(1)}
                >
                  Previous
                </CDBBtn>
                <CDBBtn
                  color="dark"
                  circle={false}
                  flat
                  classNameName="float-end"
                  onClick={() => handleNextPrevClick(3)}
                >
                  Next
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </div>
    </StepContainer>
  );
};
const Step1 = ({ handleNextPrevClick }) => {
  return (
    <StepContainer>
      <div style={{ width: '100%', background: '#f9f9f9', padding: '30px 10px', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            maxWidth: '500px',
            borderRadius: '10px',
            background: '#f5f5f5',
            boxShadow: '0px 4px 10px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <FlexColumnContainer>
            <div
              style={{
                textAlign: 'center',
                padding: '20px 0 10px 0',
                color: '#939393',
                fontSize: '30px',
                fontWeight: 'bold',
              }}
            >
              Step 1
            </div>
            <FlexColumnContainer width="100%">
            <label className="text-muted mb-2">
                Username
              </label>
              <div className="">
                <input type="text"  className="form-control bg-light" />
              </div>
              <label className="text-muted mb-2">
                Email
              </label>
              <div className="">
                <input type="text" className="form-control bg-light" />
              </div>
              <label className="text-muted mb-2">
                Password
              </label>
              <div className="">
                <input type="password"  className="form-control bg-light" />
              </div>
              <label className="text-muted mb-2">
                Confirm Password
              </label>
              <div className="">
                <input type="password"  className="form-control bg-light" />
              </div>
                      
              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  classNameName="float-end"
                  onClick={() => handleNextPrevClick(2)}
                >
                  Next
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </div>
    </StepContainer>
  );
};
