import React from 'react';
import MDXRuntimeTest from '../../templates/docs';
import MDXRuntimeTestNg from '../../templates/angulardocs';
import MDXRuntimeTestJs from '../../templates/javascriptdocs';
import Post from '../../templates/blog';
import MDXRuntimeTestTailwind from '../../templates/tailwindclass';
import MDXRuntimeWindframe from '../../templates/windframedocs';
import MDXRuntimeWindframeComponents from '../../templates/componentdocs';


const EmptyLayout = props => {
  if (props.location.pathname.includes('docs/contrast/react')) {
    return <MDXRuntimeTest {...props} />;
  } else if (props.location.pathname.includes('docs/contrast/angular')) {
    return <MDXRuntimeTestNg {...props} />;
  } else if (props.location.pathname.includes('docs/contrast/javascript')) {
    return <MDXRuntimeTestJs {...props} />;
  } else if (
    props.location.pathname.includes('blog/') &&
    props.path !== '/blog/' &&
    props.uri !== '/blog' &&
    props.data.mdx
  ) {
    return <Post {...props} />;
  } else if (props.location.pathname.includes('tailwindcss/classes')) {
    return <MDXRuntimeTestTailwind {...props} />;
  } else if (props.location.pathname.includes('windframe/docs')) {
    return <MDXRuntimeWindframe {...props} />;
  } else if (props.location.pathname.includes('windframe/components')) {
    return <MDXRuntimeWindframeComponents {...props} />;
  }
  else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

export default EmptyLayout;
