import React, { useState } from "react";
import { RightNav } from "../RightNav/RightNav";
import { StyledBurger } from "./Burger.styles";

export const Burger = ({user}) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div className="span1" />
        <div className="span2" />
        <div className="span3" />
      </StyledBurger>
      <RightNav open={open} user={user}/>
    </React.Fragment>
  );
};
