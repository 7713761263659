import React, { useState } from 'react';
import { StyledNavbar } from './NavBar.styles';
import { Burger } from '../Burger/Burger';
import { Link } from 'gatsby';
import logo from '../images/logo.png';
import chevron from '../images/chevron.png';
import AppContext, { UserContext } from '../AppContext';

const NavBar = props => {
  const { user } = props;

  const [toggle, setToggle] = useState(false);
  const [expanded, toggleExpanded] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);

  return (
    <AppContext>
      <UserContext.Consumer>
        {({ requestLogout }) => {
          return (
            <React.Fragment>
              <header className="py-2 px-4 md:py-6">
                <div className="container mx-auto p-0">
                  <div className="flex items-center justify-between">
                    <div className="flex-shrink-0">
                      <Link to="/" className="flex rounded outline-none">
                        <img className="w-auto h-8" src={logo} alt="" />
                      </Link>
                    </div>

                    <div className="flex lg:hidden">
                      <button type="button" className="text-gray-900">
                        {!expanded ? (
                          <span onClick={() => toggleExpanded(!expanded)}>
                            <svg
                              className="w-7 h-7"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M4 6h16M4 12h16M4 18h16"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span onClick={() => toggleExpanded(!expanded)}>
                            <svg
                              className="w-7 h-7"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </span>
                        )}
                      </button>
                    </div>

                    <div className="hidden lg:flex lg:ml-12 lg:items-center lg:justify-center lg:space-x-6 xl:space-x-6">
                      <Link
                        href="/windframe"
                        title=""
                        className="text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                      >
                        Windframe
                      </Link>

                      <Link
                        to="/resources"
                        title=""
                        className="text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                      >
                        Resources
                      </Link>

                      <div className="dropdown">
                        <p
                          onClick={() => setDropdown(!dropdown)}
                          className="dropdown-toggle flex items-center text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                          style={{ cursor: 'pointer' }}
                        >
                          Products
                        </p>
                        {dropdown && (
                          <ul className="dropdown-menu d-block" role="menu">
                            <h5 className="dropdown-header">
                              <i className="fab fa-react mr-2 text-primary"></i>
                              React
                            </h5>
                            <div className="dropdown-divider"></div>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/contrast/" className="link text-dark">
                                CDBReact
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/contrast-pro/" className="link text-dark">
                                CDBReact PRO
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/admin-contrast/" className="link text-dark">
                                CDBReact Admin Template
                              </Link>
                            </li>
                            <div className="dropdown-divider"></div>
                            <h5 className="dropdown-header">
                              <i className="fab fa-angular mr-2 text-danger"></i>
                              Angular
                            </h5>
                            <div className="dropdown-divider"></div>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/angular-contrast/" className="link text-dark">
                                CDBAngular
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/angular-contrast-pro/" className="link text-dark">
                                CDBAngular PRO
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link
                                to="/product/angular-admin-contrast/"
                                className="link text-dark"
                              >
                                CDBAngular Admin Template
                              </Link>
                            </li>
                            <div className="dropdown-divider"></div>
                            <h5 className="dropdown-header">
                              <i className="fab fa-js mr-2 text-warning"></i>
                              JS: Bootstrap 5
                            </h5>
                            <div className="dropdown-divider"></div>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/bootstrap-contrast/" className="link text-dark">
                                CDBootstrap
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link
                                to="/product/bootstrap-contrast-pro/"
                                className="link text-dark"
                              >
                                CDBootstrap Pro
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link
                                to="/product/bootstrap-admin-contrast/"
                                className="link text-dark"
                              >
                                CDBootstrap Admin Template
                              </Link>
                            </li>
                            <div className="dropdown-divider"></div>
                            <h5 className="dropdown-header">
                              <i className="fas fa-infinity mr-2 text-primary"></i>
                              Loop
                            </h5>
                            <div className="dropdown-divider"></div>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/loop/" className="link text-dark">
                                Loop
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/loopv2/" className="link text-dark">
                                Loop v2
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/loopprox/" className="link text-dark">
                                Loop pro X
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>

                      <div className="dropdown">
                        <p
                          onClick={() => setDropdown2(!dropdown2)}
                          className="dropdown-toggle flex items-center text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                          style={{ cursor: 'pointer' }}
                        >
                          Docs
                        </p>
                        {dropdown2 && (
                          <ul className="dropdown-menu d-block" role="menu">
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/docs/contrast/react/index/" className="link text-dark">
                                <i className="fab fa-react mr-2 text-primary"></i>
                                React
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/docs/contrast/angular/index/" className="link text-dark">
                                <i className="fab fa-angular mr-2 text-danger"></i>
                                Angular
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link
                                to="/docs/contrast/javascript/index/"
                                className="link text-dark"
                              >
                                <i className="fab fa-js mr-2 text-warning"></i>
                                Js: Bootstrap 5
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>

                      <Link
                        to="/blog"
                        title=""
                        className="text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                      >
                        Blog
                      </Link>
                      <Link
                        to="/contact"
                        title=""
                        className="text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                      >
                        Contact
                      </Link>
                    </div>

                    {/* <div className="loop">
                      <a
                        href="https://www.producthunt.com/posts/windframe?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-windframe"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=303580&theme=light"
                          alt="Windframe - No code TailwindCSS page builder | Product Hunt"
                          style={{ width: '250px', height: '54px' }}
                        />
                      </a>
                    </div> */}

                    {user.loggedIn ? (
                      <ul className="hidden lg:flex nav navbar-nav">
                        <li className="dropdown relative">
                          <div
                            onClick={() => {
                              console.log('Clicked');
                              setToggle(!toggle);
                            }}
                            role="button"
                            className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-gray-900 transition-all duration-200 bg-white border-2 border-gray-900 rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                          >
                            <span>Welcome</span>
                            <span>
                              <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.16108 10.0731C4.45387 9.2649 5.02785 8 6.1018 8H17.898C18.972 8 19.5459 9.2649 18.8388 10.0731L13.3169 16.3838C12.6197 17.1806 11.3801 17.1806 10.6829 16.3838L5.16108 10.0731ZM6.65274 9.5L11.8118 15.396C11.9114 15.5099 12.0885 15.5099 12.1881 15.396L17.3471 9.5H6.65274Z"
                                  fill="#212121"
                                />
                              </svg>
                            </span>
                          </div>

                          {toggle && (
                            <ul
                              className="absolute z-10 bg-white w-56 rounded-lg shadow p-2"
                              role="menu"
                            >
                              <li className="py-1 pl-2 pr-8 hover:bg-gray-300 bg-white">
                                <Link to="/windframe/subscription/" className="link">
                                  Windframe
                                </Link>
                              </li>
                              <li className="py-1 pl-2 pr-8 hover:bg-gray-300 bg-white">
                                <Link to="/app/downloads/" className="link">
                                  Downloads
                                </Link>
                              </li>
                              <li className="py-1 pl-2 pr-8 hover:bg-gray-300 bg-white">
                                <Link to="/app/account-settings/" className="link">
                                  Account Settings
                                </Link>
                              </li>
                              <li className="py-1 pl-2 pr-8 hover:bg-gray-300 bg-white">
                                <Link to="/app/change-password/" className="link">
                                  Change Password
                                </Link>
                              </li>

                              <li className="py-1 pl-2 pr-8 hover:bg-gray-300 bg-white">
                                <a
                                  onClick={() => {
                                    requestLogout();
                                  }}
                                >
                                  <span style={{ cursor: 'pointer' }} className="link">
                                    Logout
                                  </span>
                                </a>
                              </li>
                            </ul>
                          )}
                        </li>
                      </ul>
                    ) : (
                      <div className="hidden lg:flex lg:ml-auto lg:items-center lg:space-x-6">
                        <Link
                          to="/app2/signup"
                          title=""
                          className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                          role="button"
                        >
                          Sign up
                        </Link>
                        <Link
                          to="/app2/signin"
                          title=""
                          className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-gray-900 transition-all duration-200 bg-white border-2 border-gray-900 rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                          role="button"
                        >
                          Sign in
                        </Link>
                      </div>
                    )}
                  </div>
                  {expanded && (
                    <div className="lg:hidden flex flex-col py-2">
                      <Link
                        href="/windframe"
                        className="py-2 pr-2.5 text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                      >
                        Windframe
                      </Link>

                      <Link
                        to="/resources"
                        className="py-2 pr-2.5 text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                      >
                        Resources
                      </Link>

                      <div className="dropdown pl-0">
                        <p
                          onClick={() => setDropdown(!dropdown)}
                          className="dropdown-toggle flex items-center text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                          style={{ cursor: 'pointer' }}
                        >
                          Products
                        </p>
                        {dropdown && (
                          <ul
                            className="border mt-4 w-64 absolute bg-white z-10"
                            style={{ maxHeight: '50vh', overflowY: 'auto' }}
                            role="menu"
                          >
                            <h5 className="dropdown-header">
                              <i className="fab fa-react mr-2 text-primary"></i>
                              React
                            </h5>
                            <div className="dropdown-divider"></div>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/contrast/" className="link text-dark">
                                CDBReact
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/contrast-pro/" className="link text-dark">
                                CDBReact PRO
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/admin-contrast/" className="link text-dark">
                                CDBReact Admin Template
                              </Link>
                            </li>
                            <div className="dropdown-divider"></div>
                            <h5 className="dropdown-header">
                              <i className="fab fa-angular mr-2 text-danger"></i>
                              Angular
                            </h5>
                            <div className="dropdown-divider"></div>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/angular-contrast/" className="link text-dark">
                                CDBAngular
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/angular-contrast-pro/" className="link text-dark">
                                CDBAngular PRO
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link
                                to="/product/angular-admin-contrast/"
                                className="link text-dark"
                              >
                                CDBAngular Admin Template
                              </Link>
                            </li>
                            <div className="dropdown-divider"></div>
                            <h5 className="dropdown-header">
                              <i className="fab fa-js mr-2 text-warning"></i>
                              JS: Bootstrap 5
                            </h5>
                            <div className="dropdown-divider"></div>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/bootstrap-contrast/" className="link text-dark">
                                CDBootstrap
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link
                                to="/product/bootstrap-contrast-pro/"
                                className="link text-dark"
                              >
                                CDBootstrap Pro
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link
                                to="/product/bootstrap-admin-contrast/"
                                className="link text-dark"
                              >
                                CDBootstrap Admin Template
                              </Link>
                            </li>
                            <div className="dropdown-divider"></div>
                            <h5 className="dropdown-header">
                              <i className="fas fa-infinity mr-2 text-primary"></i>
                              Loop
                            </h5>
                            <div className="dropdown-divider"></div>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/loop/" className="link text-dark">
                                Loop
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/loopv2/" className="link text-dark">
                                Loop v2
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/product/loopprox/" className="link text-dark">
                                Loop pro X
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>

                      <div className="dropdown pl-0">
                        <p
                          onClick={() => setDropdown2(!dropdown2)}
                          className="dropdown-toggle flex items-center text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                          style={{ cursor: 'pointer' }}
                        >
                          Docs
                        </p>
                        {dropdown2 && (
                          <ul className="border mt-4 w-56 absolute bg-white z-10" role="menu">
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/docs/contrast/react/index/" className="link text-dark">
                                <i className="fab fa-react mr-2 text-primary"></i>
                                React
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link to="/docs/contrast/angular/index/" className="link text-dark">
                                <i className="fab fa-angular mr-2 text-danger"></i>
                                Angular
                              </Link>
                            </li>
                            <li className="w-56 py-1.5 px-3">
                              <Link
                                to="/docs/contrast/javascript/index/"
                                className="link text-dark"
                              >
                                <i className="fab fa-js mr-2 text-warning"></i>
                                Js: Bootstrap 5
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>

                      <Link
                        to="/blog"
                        title=""
                        className="py-2 pr-2.5 text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                      >
                        Blog
                      </Link>
                      <Link
                        to="/contact"
                        title=""
                        className="py-2 pr-2.5 text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                      >
                        Contact
                      </Link>
                      {user.loggedIn ? (
                        <>
                          <Link
                            to="/app/downloads/"
                            title=""
                            className="py-2 pr-2.5 text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                          >
                            Downloads
                          </Link>
                          <Link
                            to="/app/account-settings/"
                            title=""
                            className="py-2 pr-2.5 text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                          >
                            Account Settings
                          </Link>
                          <Link
                            to="/app/change-password/"
                            title=""
                            className="py-2 pr-2.5 text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                          >
                            Change Password
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link
                            to="/app2/signin"
                            title=""
                            className="py-2 pr-2.5 text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                          >
                            Sign In
                          </Link>
                          <Link
                            to="/app2/signup"
                            title=""
                            className="py-2 pr-2.5 text-base font-bold text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50"
                          >
                            Sign Up
                          </Link>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </header>
            </React.Fragment>
          );
        }}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default NavBar;
