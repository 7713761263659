import React from 'react';

export const Box1 = () => {
  return (
    <>
      <span>Span</span>
      <div>Div</div>
      <p>Paragraph</p>
      <section>Section</section>
    </>
  );
};

export const Box2 = () => {
  return (
    <>
      <div class="d-flex">Display FLex</div>
      <div class="d-block">Display Block</div>
      <div class="d-inline">Display Inline</div>
    </>
  );
};

export const Box3 = () => {
  return (
    <>
      <div class="d-flex flex-column">
        <p>Coloumn</p>
        <p>Coloumn</p>
        <p>Coloumn</p>
      </div>
      <div class="d-flex flex-row ">
        <p>row</p>
        <p>row</p>
        <p>row</p>
      </div>
    </>
  );
};

export const Box4 = () => {
  return (
    <>
      <div class="text-danger">Danger</div>
      <div class="text-success">Success</div>
      <div class="text-warning">Warning</div>
      <div class="text-primary">Primary</div>
    </>
  );
};
export const Box5 = () => {
  return (
    <>
      <div class="bg-danger">Danger</div>
      <div class="bg-success">Success</div>
      <div class="bg-warning">Warning</div>
      <div class="bg-primary">Primary</div>
    </>
  );
};
