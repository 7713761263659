import React from 'react';

export const Pagination1 = () => {
  return (
    <>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#">
              Prev
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              1
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              2
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              3
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export const Pagination2 = () => {
  return (
    <>
      <nav aria-label="Page navigation example">
        <ul class="pagination pagination-dark">
          <li class="page-item">
            <a class="page-link page-link-round" href="#">
              Prev
            </a>
          </li>
          <li class="page-item">
            <a class="page-link page-link-round" href="#">
              1
            </a>
          </li>
          <li class="page-item">
            <a class="page-link page-link-round" href="#">
              2
            </a>
          </li>
          <li class="page-item">
            <a class="page-link page-link-round" href="#">
              3
            </a>
          </li>
          <li class="page-item">
            <a class="page-link page-link-round" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export const Pagination3 = () => {
  return (
    <>
      <nav>
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link text-dark bg-light" href="#">
              Prev
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-dark bg-light" href="#">
              1
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-dark bg-light" href="#">
              2
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-dark bg-light" href="#">
              3
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-dark bg-light" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link text-dark bg-warning" href="#">
              Prev
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-dark bg-warning" href="#">
              1
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-dark bg-warning" href="#">
              2
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-dark bg-warning" href="#">
              3
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-dark bg-warning" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link text-white bg-danger" href="#">
              Prev
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-white bg-danger" href="#">
              1
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-white bg-danger" href="#">
              2
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-white bg-danger" href="#">
              3
            </a>
          </li>
          <li class="page-item">
            <a class="page-link text-white bg-danger" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export const Pagination4 = () => {
  return (
    <>
      <nav aria-label="Page navigation example">
        <ul class="pagination pagination-lg">
          <li class="page-item">
            <a class="page-link" href="#">
              Prev
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              1
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              2
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              3
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#">
              Prev
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              1
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              2
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              3
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      <nav aria-label="Page navigation example">
        <ul class="pagination pagination-sm">
          <li class="page-item">
            <a class="page-link" href="#">
              Prev
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              1
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              2
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              3
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};
