import React from 'react';

export const Checkbox1 = () => {
  return (
    <>
      <div class="custom-check">
        <input class="custom-check-input" type="checkbox" value="" id="defaultCheck1" />
        <label class="custom-check-label" for="defaultCheck1">
          Default checkbox
        </label>
      </div>

      <div class="custom-check">
        <input class="custom-check-input" type="checkbox" value="" id="defaultCheck2" disabled />
        <label class="custom-check-label" for="defaultCheck2">
          Disabled checkbox
        </label>
      </div>
    </>
  );
};

export const Checkbox2 = () => {
  return (
    <>
      <div class="custom-check form-check-inline">
        <input class="custom-check-input" type="checkbox" value="" id="Checkbox1" />
        <label class="custom-check-label" for="Checkbox1">
          Checkbox 1
        </label>
      </div>

      <div class="custom-check form-check-inline">
        <input class="custom-check-input" type="checkbox" value="" id="Checkbox2" />
        <label class="custom-check-label" for="Checkbox2">
          Checkbox 2
        </label>
      </div>
    </>
  );
};

export const Checkbox3 = () => {
  return (
    <>
      <div class="custom-check">
        <input
          class="custom-check-input text-primary bg-warning"
          type="checkbox"
          value=""
          id="Checkbox3"
        />
        <label class="custom-check-label" for="Checkbox3">
          checkbox 3
        </label>
      </div>

      <div class="custom-check">
        <input class="custom-check-input" type="checkbox" value="" id="Checkbox4" />
        <label class="custom-check-label" for="Checkbox4">
          checkbox 4
        </label>
      </div>

      <div class="custom-check">
        <input
          class="custom-check-input border-primary text-primary"
          type="checkbox"
          value=""
          id="Checkbox5"
        />
        <label class="custom-check-label text-primary" for="Checkbox5">
          checkbox 5
        </label>
      </div>
    </>
  );
};
