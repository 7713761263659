import React, { useEffect, useState, useCallback } from 'react';
import { navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import Cookies from 'universal-cookie';

export const UserContext = React.createContext({});

const UserProvider = UserContext.Provider;

const cookies = new Cookies();

export function onAuthStateChange(callback, secondCallback) {
  return firebase.auth().onAuthStateChanged(user => {
    if (user) {
      callback({ loggedIn: true, user: user });
      secondCallback(false);
    } else {
      callback({ loggedIn: false });
      secondCallback(false);
    }
  });
}

export function login(username, password, location) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(user => {
        resolve();
        
        cookies.set('framer', user.user.uid, {
          path: '/',
          domain: 'devwares.com',
        });
        if (user.user.uid && user.user.emailVerified) {
          if (location.search == '?q=WindFrame') {
            navigate('https://windframe.devwares.com');
          } else {
            navigate('/');
          }
        } else if (user.user.uid) {
          navigate('/app/confirm');
        }
      })
      .catch(error => reject(error));
  });
}

export function logout() {
  firebase
    .auth()
    .signOut()
    .then(() => {
      navigate('/');
    });
}

function AppContext(props) {
  const [user, setUser] = useState({ loggedIn: false });
  const [userData, setUserData] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [framerIsStillActive, setFramerIsActive] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [isauthenticating, setAuthenticating] = useState(true);
  const [error, setError] = useState('');
  const [buttonState, setButtonState] = useState(false);
  const [res, setRes] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const docRef = firebase.firestore().collection('resources');

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser, setAuthenticating);
    docRef.get().then(collection => {
      const docres = [];
      collection.forEach(doc => {
        docres.push(doc.data());
      });
      setRes(docres);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
      firebase.firestore()
      .collection('blocks')
      .get()
      .then(collection => {
        const blocks = [];
        collection.forEach(doc => {
          setBlocks(doc.data())
        });
        // setBlocks(blocks)
      })

        // .catch(error => setSubscriptionData({ error: 'no subscription retrieved' }));
    
  }, []);
  useEffect(() => {
    
    if (user.loggedIn === true) {
      const db = firebase
        .firestore()
        .collection('windFrameSubscription')
        .doc(user.user.uid);
      db.get()
        .then(doc => {
          if (doc.exists) {
            setSubscriptionData(doc.data());
          } else {
            setSubscriptionData({ error: 'no subscription retrieved' });
          }
        })
        .catch(error => setSubscriptionData({ error: 'no subscription retrieved' }));
    }
  }, [user]);

  const requestLogin = useCallback((username, password, location) => {
    login(username, password, location)
      .then(() => {
        setButtonState(false);
      })
      .catch(error => {
        setError(error.message);
        setButtonState(false);
      });
  });

  const requestLogout = useCallback(() => {
    try {
      cookies.remove('framer', {
        path: '/',
        domain: 'devwares.com',
      });
      console.log('done');
    } catch (e) {
      console.log(e);
    }
    logout();
  }, []);

  return (
    <UserProvider
      value={{
        user,
        // userData,
        subscriptionData,
        error,
        buttonState,
        setButtonState,
        requestLogin,
        requestLogout,
        res,
        blocks,
        framerIsStillActive,
        subscriptionPlan,
        setSubscriptionPlan,
      }}
    >
      {props.children}
    </UserProvider>
  );
}

export default AppContext;
