/* eslint-disable*/

import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
// import Loadable from 'react-loadable';
import { StyledNavbar } from './NavBar/NavBar.styles';
import { Burger } from './Burger/Burger';
import Sidebar from './sidebar';

// import config from '../../config.js';
// import LoadingProvider from './mdxComponents/loading';
import { DarkModeSwitch } from './DarkModeSwitch';
import logo from './images/logo.png';

// const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

// let searchIndices = [];

// if (isSearchEnabled && config.header.search.indexName) {
//   searchIndices.push({
//     name: `${config.header.search.indexName}`,
//     title: `Results`,
//     hitComp: `PageHit`,
//   });
// }

// const LoadableComponent = Loadable({
//   loader: () => import('./search/index'),
//   loading: LoadingProvider,
// });

function myFunction() {
  var x = document.getElementById('navbar');

  if (x.className === 'topnav') {
    x.className += ' responsive';
  } else {
    x.className = 'topnav';
  }
}

const StyledBgDiv = styled('div')`
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  position: relative;
  display: none;
  background: ${props => (props.isDarkThemeActive ? '#001932' : undefined)};

  @media (max-width: 767px) {
    display: block;
  }
`;

const Header2 = ({
  isDarkThemeActive,
  toggleActiveTheme,
  doc,
  user,
  requestLogout,
  sidebarComponent
}) => {
      const [toggle, setToggle] = useState(false);

      const [dropdown, setDropdown] = useState(false);
      const [dropdown2, setDropdown2] = useState(false);

      const navClasses = classNames('navbar', doc ? 'doc-nav' : null);
      const _sidebarComponent = useMemo(() => sidebarComponent , [])

      return (
        <div
          className={'navBarWrapper'}
          style={{ maxWidth: '1200px', margin: '0 auto' }}
          id="navbar2"
        >
          <StyledNavbar className={navClasses} isDarkThemeActive={isDarkThemeActive}>
            <div className="nav-menu">
              <div className="nav-menux">
                <Link to="/">
                  <div className="nav-logo">
                    {!user ? <img src={logo} alt="devwares" /> : <img src={logo} alt="devwares" />}
                  </div>
                </Link>
                <div className="nav-list">
                  <ul>
                    <Link to="/windframe" className="link">
                      <li> Windframe</li>
                    </Link>
                    {/* <Link to="/sales" className="link">
                      <li>Sales</li>
                    </Link> */}
                    <Link href="/windframe/components/index" className="link">
                      <li>Components</li>
                    </Link>

                    <Link href="/windframe/import" className="link">
                      <li>Import</li>
                    </Link>
                    <Link href="/windframe/blocks" className="link">
                      <li>Block</li>
                    </Link>
                    <Link to="/windframe/form-builder" className="link">
                      <li>Form Builder</li>
                    </Link>

                    <Link to="/windframe/pricing" className="link">
                      <li>Pricing</li>
                    </Link>

                    <Link to="/windframe/contact" className="link">
                      <li>Support</li>
                    </Link>
                    <Link to="https://windframe.tolt.io/" target="_blank" className="link">
                      <li>Refer and earn</li>
                    </Link>
                  </ul>
                </div>
              </div>

              <div className="nav-buttons">
                <DarkModeSwitch
                  isDarkThemeActive={isDarkThemeActive}
                  toggleActiveTheme={toggleActiveTheme}
                />
                {user.loggedIn ? (
                  <ul className="nav navbar-nav">
                    <li className="dropdown">
                      <a onClick={() => setToggle(!toggle)}>
                        <span className="dropdown-toggle" style={{ cursor: 'pointer' }}>
                          Welcome
                          {/* <img src="images/droparrow.png" alt="" /> */}
                        </span>
                      </a>
                      {toggle && (
                        <ul className="dropdown-menu" role="menu">
                          <li>
                            <Link to="/windframe/subscription/" className="link">
                              Windframe
                            </Link>
                          </li>
                          <li>
                            <Link to="/app/downloads/" className="link">
                              Downloads
                            </Link>
                          </li>
                          <li>
                            <Link to="/app/change-password/" className="link">
                              Change Password
                            </Link>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                console.log('this is the header');
                                requestLogout();
                              }}
                            >
                              <span style={{ cursor: 'pointer' }} className="link">
                                Logout
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                ) : (
                  <React.Fragment>
                    <Link to="/app2/signup/" className="link">
                      <button className="signup">
                        Sign up
                        {/* <img src={chevron} alt=">" /> */}
                      </button>
                    </Link>
                    <Link to="/app2/signin/" className="link">
                      <button className="signin">Sign in</button>
                    </Link>
                  </React.Fragment>
                )}
              </div>
              <Burger user={user} />
            </div>
          </StyledNavbar>

          <StyledBgDiv isDarkThemeActive={isDarkThemeActive}>
            <div className={'navBarDefault removePadd'}>
              <span
                onClick={myFunction}
                className={'navBarToggle'}
                onKeyDown={myFunction}
                role="button"
                tabIndex={0}
              >
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
              </span>
            </div>
            {/* {isSearchEnabled ? (
              <div className={'searchWrapper'}>
                <LoadableComponent collapse={true} indices={searchIndices} />
              </div>
            ) : null} */}
          </StyledBgDiv>
          <div id="navbar" className={'topnav'}>
            <div className="visibleMobile">
              {_sidebarComponent ? _sidebarComponent : <Sidebar />}
              <hr />
            </div>
          </div>
        </div>
      );
};

export default Header2;
