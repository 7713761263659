import React from 'react';

export const Spinner1 = () => {
  return (
    <>
      <div class="spinner spinner-muilticolor">loading...</div>
    </>
  );
};

export const Spinner2 = () => {
  return (
    <>
      <div class="spinner spinner-lg text-info">loading...</div>
      <div class="spinner text-primary">loading...</div>
      <div class="spinner spinner-sm text-success">loading...</div>
    </>
  );
};
