import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import tailwindVideo from '../images/windheader2.mp4';
import Img from "gatsby-image/withIEPolyfill"

const Advert = ({ packageName }) => {
  const _graphqlData = useStaticQuery(imageQuery)

  const { reactImage, bootstrapImage, angularImage, windframeImage } = _graphqlData
  const [currentPackage, setCurrentPackage] = useState('bootstrap');
  // Create Stunning websites and web apps
  // Building different custom components in react for your web apps
  //         or websites can get very stressful.Thats why we decided to build contrast.
  // We have put together a UI kit with over 10000 + components, 5 admin dashboards and
  // 23 additional different pages template for building almost any type of web app or
  // webpage into a single product called Contrast Pro.
  // bottomText: `Contrast React Bootstrap PRO is a Multipurpose pro template, UI kit to build your
  //         next landing, admin, SAAS, prelaunch, etc project with a clean, well documented,
  //         well crafted template and UI components.`,
  const data = {
    react: {
      headText: `Download a free React admin template`,
      topText: `We have created a free React admin template to help
       you quickly build clean and beautiful interfaces for your
        webapps and websites. The template was built using the Contrast UI Library, which is a React bootstrap library to help you easily build mobile-first, responsive and elegant websites and webapps.
        `,
      image: reactImage,
      link: '/product/admin-contrast/',
    },
    bootstrap: {
      headText: `Build modern projects using Bootstrap 5 and Contrast`,
      topText: `Trying to create components and pages for a web app or website from 
        scratch while maintaining a modern User interface can be very tedious. 
        This is why we created Contrast, to help drastically reduce the amount of time we spend doing that.
        so we can focus on building some other aspects of the project.`,
      middleText: `Contrast Bootstrap PRO consists of a Premium UI Kit Library featuring over 10000+ component variants. 
        Which even comes bundled together with its own admin template comprising of 5 admin dashboards and 23+ additional admin and multipurpose pages for 
        building almost any type of website or web app.`,

      bottomText: `Contrast Bootstrap PRO was built using the most popular CSS framework Bootstrap to help 
        build your next landing, admin SAAS, prelaunch etc project with a clean, prebuilt and well documented 
        template and UI components.`,
      image: bootstrapImage,
      link: '/product/bootstrap-contrast-pro/',
    },
    angular: {
      headText: `Download a free Angular admin template`,
      topText: `We have created a free Angular admin template to help
       you quickly build clean and beautiful interfaces for your
        webapps and websites. The template was built using the Contrast UI Library, which is a Angular bootstrap library to help you easily build mobile-first, responsive and elegant websites and webapps.
        `,
      image: angularImage,
      link: '/product/angular-admin-contrast/',
    },
    tailwind: {
      topText: `What if you could build tailwind websites visually and ship projects to production in minutes 
      instead of days. We created a tool to do just that! Windframe is a tailwind css visual builder for creating
       components, prototypes, websites, and webapps. Windframe generates code from your designs to export
        to your favourite frameworks like React, Vue, Angular. So you can easily integrate into your own code.`,
      video: tailwindVideo,
      link: '/windframe/',
      image: windframeImage,
    },
  };

  useEffect(() => {
    setPackage();
  }, []);

  const setPackage = () => {
    if (!packageName) return;

    const _packageName = packageName.split(' ').join("").toLowerCase()
    switch (_packageName) {
      case 'react':
        setCurrentPackage('react');
        break;
      case 'tailwind':
        setCurrentPackage('tailwind');
        break;
      case 'tailwindcss':
        setCurrentPackage('tailwind');
        break;
      case 'bootstrap':
        setCurrentPackage('bootstrap');
        break;
      case 'angular':
        setCurrentPackage('angular');
        break;
      default:
        setCurrentPackage('bootstrap');
        break;
    }
  };
  return (
    <div style={{ margin: '50px 0px' }}>
      {(currentPackage !== 'tailwind' && currentPackage === 'react' && (
        <>
          <h2 className="text-3xl font-bold mb-3">{data[currentPackage].headText}</h2>
          <p>
            <span className="text-base">{data[currentPackage].topText}</span>
            <br />
            <Link to={data[currentPackage].link}>Download the free react template</Link>
          </p>
          <Link to={data[currentPackage].link} className="p-2">
            <div alt="ad-banner" className=' aspect-square h-[18rem] sm:h-[25rem] md:h-[30rem]'
            >
              {data[currentPackage].image && data[currentPackage].image.childImageSharp.fluid ?
                <Img
                  fluid={data[currentPackage].image.childImageSharp.fluid}
                  alt="postImage"
                /> : null
              }
            </div>
          </Link>
          {data[currentPackage].bottomText && (
            <p style={{ marginTop: '20px' }}>
              <span>{data[currentPackage].bottomText}</span>
              <Link to={data[currentPackage].link}>Learn more about Contrast</Link>
            </p>
          )}
        </>
      )) ||
        (currentPackage === 'tailwind' && (
          <>
            <h2 className="text-3xl font-bold mb-3 font-weight-bold">
              Visually build tailwind css projects 10x faster using AI
            </h2>
            <p>
              <span className="text-base">{data[currentPackage].topText}</span>

            </p>
            <Link to={data[currentPackage].link} className="text-lg">
              Try Windframe out for free.
            </Link>
            <div className="w-100 mx-auto mt-2">
             
            <Link to={data[currentPackage].link} className="rounded-lg">
              <video className="w-100 rounded-xl" autoPlay muted loop>
                <source src={data[currentPackage].video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
                {/* <div alt="ad-banner" className=' aspect-square h-[18rem] sm:h-[25rem] md:h-[30rem]'
              >
              
                  {data[currentPackage].image && data[currentPackage].image.childImageSharp.fluid ?
                    <Img
                      fluid={data[currentPackage].image.childImageSharp.fluid}
                      alt="postImage"
                    /> : null
                  }
                </div> */}
              </Link>
            </div>
          </>
        )) ||
        (currentPackage === 'angular' && (
          <>
            <h2 className="text-3xl font-bold mb-3">{data[currentPackage].headText}</h2>
            <p>
              <span className="text-base">{data[currentPackage].topText}</span>
              <br />

              <Link to={data[currentPackage].link}>Download the free Angular template</Link>
            </p>
            <Link to={data[currentPackage].link} className="p-2">
              <div alt="ad-banner" className=' aspect-square h-[18rem] sm:h-[25rem] md:h-[30rem]'
              >
                {data[currentPackage].image && data[currentPackage].image.childImageSharp.fluid ?
                  <Img
                    fluid={data[currentPackage].image.childImageSharp.fluid}
                    alt="postImage"
                  /> : null
                }
              </div>
            </Link>
            {data[currentPackage].bottomText && (
              <p style={{ marginTop: '20px' }}>
                <span>{data[currentPackage].bottomText}</span>
                <Link to={data[currentPackage].link}>Learn more about Contrast</Link>
              </p>
            )}
          </>
        )) ||
        (currentPackage === 'bootstrap' && (
          <>
            <h2 className="text-3xl font-bold mb-3">{data[currentPackage].headText}</h2>
            <p>
              <span className="text-base">{data[currentPackage].topText}</span>
              <br /> <br />
              <span className="text-base">{data[currentPackage].middleText}</span>
              <br />
              <Link to={data[currentPackage].link}>
                See a demo and learn more about Contrast Bootstrap Pro by clicking here.
              </Link>
            </p>
            <Link to={data[currentPackage].link} className="p-2">
              <div alt="ad-banner" className=' aspect-square h-[18rem] sm:h-[25rem] md:h-[30rem]'
              >
                {data[currentPackage].image && data[currentPackage].image.childImageSharp.fluid ?
                  <Img
                    fluid={data[currentPackage].image.childImageSharp.fluid}
                    alt="postImage"
                  /> : null
                }
              </div>
            </Link>
          </>
        ))
      }
    </div >
  );
};

export default Advert;

const imageQuery = graphql`
query imageQuery {
  reactImage: file(relativePath: {eq: "Artboard03.png"}) {
    childImageSharp {
      fluid(
        quality: 80,
        maxWidth: 1000
      ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  
  bootstrapImage: file(relativePath: {eq: "contrast-bootstrap5.png"}) {
    childImageSharp {
      fluid(
        quality: 80,
        maxWidth: 1000
      ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  angularImage: file(relativePath: {eq: "adminheropro.png"}) {
      childImageSharp {
        fluid(
          quality: 80,
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  windframeImage: file(relativePath: {eq: "windframebanner.png"}) {
    childImageSharp {
      fluid(
        quality: 80,
        maxWidth: 1000
      ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
