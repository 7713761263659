import React from 'react';

export const Mask1 = () => {
  return (
    <>
      <div class="view">
        <img src="https://images.unsplash.com/photo-1548940740-204726a19be3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zm9vZHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" />
        <div class="mask pattern-1"></div>
      </div>
      <div class="view">
        <img src="https://images.unsplash.com/photo-1548940740-204726a19be3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zm9vZHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" />
        <div class="mask pattern-2"></div>
      </div>
      <div class="view">
        <img src="https://images.unsplash.com/photo-1548940740-204726a19be3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zm9vZHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" />
        <div class="mask pattern-3"></div>
      </div>
      <div class="view">
        <img src="https://images.unsplash.com/photo-1548940740-204726a19be3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zm9vZHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" />
        <div class="mask pattern-4"></div>
      </div>
    </>
  );
};

export const Mask2 = () => {
  return (
    <>
      <div class="view">
        <img src="https://images.unsplash.com/photo-1548940740-204726a19be3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zm9vZHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" />
        <div class="mask rgba-blue3-light"></div>
      </div>
    </>
  );
};

export const Mask3 = () => {
  return (
    <>
      <div class="view">
        <img src="https://images.unsplash.com/photo-1548940740-204726a19be3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zm9vZHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" />
        <div class="mask rgba-blue-strong"></div>
      </div>
    </>
  );
};
