
import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin: 10px;
  }
`;

const Container2 = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  & > * {
    margin: 10px;
  }
`;

export const WidthExample1 = () => {
    return (
      <Container2>
        <div class="flex flex-col gap-3 w-50 justify-center m-4 text-white font-bold text-center">
          <div class="flex">
            <div class="flex items-center justify-center w-1/2 bg-indigo-600 h-12 rounded-l-lg">
              w-1/2
            </div>
            <div class="flex items-center justify-center w-1/2 bg-indigo-500 h-12 rounded-r-lg">
              w-1/2
            </div>
          </div>
          <div class="flex">
            <div class=" flex items-center justify-center w-2/5 bg-indigo-600 h-12 rounded-l-lg">
              w-2/5
            </div>
            <div class="flex items-center justify-center w-3/5 bg-indigo-500 h-12 rounded-r-lg">
              w-3/5
            </div>
          </div>
          <div class="flex">
            <div class="flex items-center justify-center w-1/3 bg-indigo-600 h-12 rounded-l-lg">
              w-1/3
            </div>
            <div class=" flex items-center justify-center w-2/3 bg-indigo-500 h-12 rounded-r-lg">
              w-2/3
            </div>
          </div>
          <div class="flex ...">
            <div class="flex items-center justify-center w-1/4 bg-indigo-600 h-12 rounded-l-lg">
              w-1/4
            </div>
            <div class="flex items-center justify-center w-3/4 bg-indigo-500 h-12 rounded-r-lg">
              w-3/4
            </div>
          </div>
          <div class="flex">
            <div class="flex items-center justify-center w-1/5 bg-indigo-600 h-12 rounded-l-lg">
              w-1/5
            </div>
            <div class=" flex items-center justify-center w-4/5 bg-indigo-500 h-12 rounded-r-lg">
              w-4/5
            </div>
          </div>
          <div class="flex">
            <div class="flex items-center justify-center w-1/6 bg-indigo-600 h-12 rounded-l-lg">
              w-1/6
            </div>
            <div class="flex items-center justify-center w-5/6 bg-indigo-500 h-12 rounded-r-lg">
              w-5/6
            </div>
          </div>
          <div class="flex items-center justify-center w-full bg-indigo-500 h-12 rounded-lg">
            w-full
          </div>
        </div>
      </Container2>
    );
}

export const WidthExample2 = () => {
    return (
      <Container2>
        <div class="flex flex-col justify-center items-center mt-2 font-bold text-white text-center gap-3">
          <div class="w-24 min-w-full bg-indigo-600 h-12 rounded">
            <p class="mt-3">min-w-full</p>
          </div>
          <div class="w-12 min-w-0 bg-indigo-500 h-12 rounded">
            <p class="text-white">min-w-0</p>
          </div>
          <div class="w-12 min-w-max bg-indigo-500 h-12 rounded">
            <p class="mt-2">min-w-max</p>
          </div>
          <div class="flex">
            <div class="w-24 min-w-fit bg-indigo-600 h-12 rounded-l-lg">
              <p class="mt-2">min-w-fit</p>
            </div>
            <div class=" w-24 min-w-mini bg-indigo-300 h-12 rounded-r-lg">
              <p class="mt-2">min-w-mini</p>
            </div>
          </div>
        </div>
      </Container2>
    );
}


export const TailwindFixedWidth = () => {
  return (
    <Container2>
      <div class="flex items-center justify-center">
        <div class="w-20 h-20 bg-indigo-500 rounded text-center text-white flex items-center justify-center m-3">
          width of 80px
        </div>
        <div class="w-32 h-32 bg-indigo-500 rounded text-center text-white flex items-center justify-center m-3">
          width of 128px{' '}
        </div>
        <div class="w-40 h-40 bg-indigo-500 rounded text-center text-white flex items-center justify-center m-3">
          width of 160px
        </div>
      </div>
    </Container2>
  );
};

export const TailwindPercentWidth = () => {
    return (
      <Container2>
        <div class="flex flex-col items-center justify-center mt-2">
          <div class="w-2/6 h-1/2 bg-indigo-500 rounded text-center text-white flex items-center justify-center m-3">
            width of 33.33%
          </div>
          <div class="w-1/2 h-2/3 bg-indigo-500 rounded text-center text-white flex items-center justify-center m-3">
            width of 50%{' '}
          </div>
          <div class="w-3/4 bg-indigo-500 rounded text-center text-white flex items-center justify-center m-3">
            width of 75%
          </div>
        </div>
      </Container2>
    );
}


export const TailwindResponseWidth = () => {
  return (
    <Container2>
      <div class="flex items-center justify-center mt-5">
        <div class="w-full md:w-1/2 lg:w-1/4 bg-indigo-500 rounded text-center text-white font-semibold">
          This element has a full width on small screens, half width on medium screens, and
          one-fourth width on large screens.
        </div>
      </div>
    </Container2>
  );
};

export const TailwindWidthFull = () => {
    return (
      <Container2>
        <div class=" text-white font-semibold text-center w-full h-10 bg-indigo-500 rounded mt-10 pt-2">
          This div will take up the full width of its parent container.
        </div>
      </Container2>
    );
}

export const TailwindWidthFlex = () => {
    return (
      <Container2>
        <div class="flex flex-col items-center justify-center gap-4 text-white font-bold text-center">
          <div class="w-1/2 flex bg-indigo-500 rounded text-center">
            This element will take up half of the available space.
          </div>
          <div class="w-1/2 flex-grow bg-indigo-500 rounded text-center">
            This element will also take up half of the available space.
          </div>
        </div>
      </Container2>
    );
}