import React from 'react';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';

import ThemeProvider from './themewindframe/themeProvider';
import mdxComponents from './mdxComponents';
import Sidebar from './sidebarWindframe';
import config from '../../config.js';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.background};

  i + h2 {
    display: inline;
  }

  .demo-div {
    width: 40px;
    position: fixed;
    z-index: 99991;
    right: 0px;
    top: calc(50% - 55px);
  }

  .demo {
    width: 100%;
    min-width: 100%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
    padding: 12px 0px 10px;
    transition: padding-right 0.3s ease 0s;
    cursor: pointer;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    background-color: #4145d2;
    min-height: 48px;
    font-family: inherit;
    border-radius: 0px;
    text-decoration: none;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    border: 0px;
    display: block;
  }

  .btn-text {
    writing-mode: vertical-rl;
    text-orientation: upright;
    padding-left: 5px;
    padding-right: 5px;
  }

  .defaultClicked {
    background: #fff;
  }

  .logoref {
    display: block;
    padding: 6px 0px;
    text-align: -webkit-center;
  }

  .badge-pro {
    background-color: rgb(152, 113, 255);
    margin-bottom: 25px;
    margin-left: 10px;
    margin-top: 20px;
  }

  .sideBarUL li a {
    color: ${({ theme }) => theme.colors.text};
  }

  ul li .collapser svg path {
    fill: ${({ theme }) => theme.colors.text};
  }

  .sideBarUL .item > a:hover {
    background-color: #3329e0;
    color: #fff !important;
    /* background: #F8F8F8 */
  }

  .main-doc {
    width: 75%;
    padding-right: 20px;
  }

  @media only screen and (max-width: 767px) {
    display: block;
    .main-doc {
      width: 100%;
    }
  }
`;

const Content = styled('main')`
  display: flex;
  flex-grow: 1;
  margin: 0px;
  margin-top: 2rem;
  background: ${({ theme }) => theme.colors.background};

  table tr {
    // background: ${({ theme }) => theme.colors.background};
  }

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
    margin: 0 10px;
    padding-top: 3rem;
  }
`;

const MaxWidth = styled('div')`
  width: 100%;
  max-width: 1300px;
  padding: 0px 0px 0px 20px;
  margin: 0 auto;
  @media only screen and (max-width: 50rem) {
    width: 100%;
    padding: 0px;
    position: relative;
  }
`;

const LeftSideBarWidth = styled('div')`
  width: 50%;
  max-width: 225px;
`;

const Layout = ({ children, location }) => (
  <ThemeProvider sidebarComponent={<Sidebar />}>
    <MDXProvider components={mdxComponents}>
      <Wrapper>
        <LeftSideBarWidth className={'hiddenMobile'}>
          <Sidebar location={location} />
        </LeftSideBarWidth>
        {config.sidebar.title ? (
          <div
            className={'sidebarTitle sideBarShow'}
            dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
          />
        ) : null}
        <Content>
          <MaxWidth>{children}</MaxWidth>
        </Content>
      </Wrapper>
    </MDXProvider>
  </ThemeProvider>
);

export default Layout;
