import React, { useRef } from 'react';

export const Alert1 = () => {
  return (
    <div>
      <div className="alert alert-primary">
        A simple alert built with contrast design check it out!
      </div>
      <div className="alert alert-secondary">
        A simple alert built with contrast design check it out!
      </div>
      <div className="alert alert-success">
        A simple alert built with contrast design check it out!
      </div>
      <div className="alert alert-danger">
        A simple alert built with contrast design check it out!
      </div>
      <div className="alert alert-warning">
        A simple alert built with contrast design check it out!
      </div>
      <div className="alert alert-info">
        A simple alert built with contrast design check it out!
      </div>
      <div className="alert alert-dark">
        A simple alert built with contrast design check it out!
      </div>
    </div>
  );
};

export const Alert2 = () => {
  return (
    <div>
      <div class="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Holy guacamole!</strong> You should check in on some of those fields below.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </div>
  );
};

export const Alert3 = () => {
  return (
    <div>
      <div className="alert alert-warning alert-dismissible fade show" id="alert1" role="alert">
        Hey Dismiss me using Javascript
      </div>
      <button id="dismiss1" className="btn btn-dark btn-flat mt-4">
        Dismiss Alert
      </button>
    </div>
  );
};
