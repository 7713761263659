import React from 'react';
import { CDBMultiselect, CDBContainer } from 'cdbreact-pro';

export const Multiselect1 = () => {
  const option = [
    {
      label: 'New York',
      value: 'NY',
    },
    {
      label: 'Washington',
      value: 'WA',
    },
    {
      label: 'California',
      value: 'CA',
    },
    {
      label: 'New Jersey',
      value: 'NJ',
    },
    {
      label: 'North Carolina',
      value: 'NC',
    },
  ];

  return (
    <>
      <CDBContainer>
        <CDBMultiselect options={option} selected="Value" />
      </CDBContainer>
    </>
  );
};
