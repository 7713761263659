import styled from "styled-components";

export const StyledRightNav = styled.ul`
         list-style: none;
         flex-flow: row nowrap;
         display: none;

         li {
           padding: 15px 30px;
         }

         .dropdown-menu {
           margin-top: 5px !important;

           .dropdown-header {
             font-size:15px;
             padding-top:5px;
             padding-bottom:5px;
           }
           li {
             padding: 5px 10px !important;
             font-size: 13px;
             width:300px !important;
           }
         }

         @media (max-width: 768px) {
           display: flex;
           flex-flow: column nowrap;
           background-color: #ffff;
           position: fixed;
           transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
           top: 0;
           right: -50px;
           height: 100vh;
           width: 300px;
           z-index:2;
           padding-top: 3.5rem;
           transition: transform 0.3s ease-in-out;

           li {
             color: #2a21bf;
           }
         }
       `;
