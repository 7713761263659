import React from 'react';

export const Panels1 = () => {
  return (
    <>
      <div className="panel w-[25rem]">
        <div class="panel-body">
          <div class="pane d-flex align-items-center mt-0 mb-3">
            <img
              class="pane-img"
              src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
              alt=""
            />
            <div class="ms-3">
              <h6 className="m-0 font-[600]">Warren Briggs</h6>
              <p class="m-0 pane-item">Cooking</p>
            </div>

            <div class="dropdown p-0 ms-auto pane-item">
              <i
                class="fa fa-ellipsis-h display-1"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></i>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="card-text">
            Nullam feugiat est sed leo efficitur accumsan. Aenean rutrum, nisl ac ultricies
            efficitur, nunc lacus venenatis dui, eget ultrices odio libero et libero. Maecenas erat
            elit, scelerisque nec est nec, consequat varius
          </div>
        </div>
      </div>
    </>
  );
};

export const Panels2 = () => {
  return (
    <>
      <div class="panel">
        <div class="panel-img">
          <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg" alt="" />
        </div>
        <div class="panel-body">
          <div class="panel-title ">Bristol</div>
          <div class="panel-text">
            Nullam feugiat est sed leo efficitur accumsan. Aenean rutrum, nisl ac ultricies
            efficitur, nunc lacus venenatis dui, eget ultrices odio libero et libero. Maecenas erat
            elit, scelerisque nec est nec, consequat varius
          </div>
          <img
            class="pane-img"
            src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
          />
          <img
            class="pane-img"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ7ntUU7AzmOxa5HB8zS83sa-JFHEfZJAoI2A&usqp=CAU"
          />
          <img
            class="pane-img"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ7ntUU7AzmOxa5HB8zS83sa-JFHEfZJAoI2A&usqp=CAU"
          />
        </div>
      </div>
    </>
  );
};

export const Panels3 = () => {
  return (
    <>
      <div class="panel">
        <div class="panel-body">
          <div class="panel-label">
            <div class="badge badge-flat bg-secondary">Label 1</div>
            <div class="badge badge-flat bg-warning">Label 2</div>
            <div class="badge badge-flat bg-danger">Label 3</div>
          </div>
          <div class="panel-title ">Moon Fever</div>
          <div class="panel-text">
            Nullam feugiat est sed leo efficitur accumsan. Aenean rutrum, nisl ac ultricies
            efficitur, nunc lacus venenatis dui, eget ultrices odio libero et libero. Maecenas erat
            elit, scelerisque nec est nec, consequat varius
          </div>
          <img
            class="pane-img"
            src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
          />
          <img
            class="pane-img"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ7ntUU7AzmOxa5HB8zS83sa-JFHEfZJAoI2A&usqp=CAU"
          />
        </div>
      </div>
    </>
  );
};

export const Panels4 = () => {
  return (
    <>
      <div class="panel">
        <div class="panel-body">
          <div class="d-flex justify-content-between align-item-center mb-1">
            <p class="mb-0">My Messages</p>
            <div class="dropdown p-0">
              <i
                class="fa fa-ellipsis-h display-1"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></i>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="pane d-flex align-items-center">
            <img
              class="pane-img"
              src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
              alt=""
            />
            <div class="ms-3">
              <h6 class="m-0 font-[600]">Warren Briggs</h6>
              <p class="m-0 pane-item">Cooking</p>
            </div>
            <p class="ms-auto pane-item">5mins Ago</p>
          </div>

          <div class="pane d-flex align-items-center">
            <img
              class="pane-img"
              src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
              alt=""
            />
            <div class="ms-3">
              <h6 class="m-0 font-[600]">Warren Briggs</h6>
              <p class="m-0 pane-item">Cooking</p>
            </div>
            <p class="ms-auto pane-item">5mins Ago</p>
          </div>

          <div class="pane d-flex align-items-center">
            <img
              class="pane-img"
              src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
              alt=""
            />
            <div class="ms-3">
              <h6 class="m-0 font-[600]">Warren Briggs</h6>
              <p class="m-0 pane-item">Cooking</p>
            </div>
            <p class="ms-auto pane-item">5mins Ago</p>
          </div>

          <div class="pane d-flex align-items-center">
            <img
              class="pane-img"
              src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
              alt=""
            />
            <div class="ms-3">
              <h6 class="m-0 font-[600]">Warren Briggs</h6>
              <p class="m-0 pane-item">Cooking</p>
            </div>
            <p class="ms-auto pane-item">5mins Ago</p>
          </div>

          <div class="pane d-flex align-items-center">
            <img
              class="pane-img"
              src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
              alt=""
            />
            <div class="ms-3">
              <h6 class="m-0 font-[600]">Warren Briggs</h6>
              <p class="m-0 pane-item">Cooking</p>
            </div>
            <p class="ms-auto pane-item">5mins Ago</p>
          </div>
        </div>
      </div>
    </>
  );
};

export const Panels5 = () => {
  return (
    <>
      <div class="panel w-[25rem]">
        <div class="panel-body">
          <div class="pane d-flex align-items-center mt-0">
            <img
              class="pane-img"
              src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
              alt=""
            />
            <div class="ms-3">
              <h6 class="m-0 font-[600]">Warren Briggs</h6>
              <p class="m-0 pane-item">Cooking</p>
            </div>
            <p class="ms-auto pane-item">5mins Ago</p>
          </div>

          <div class="progress-container">
            <div class="progress-text pane-item">Instagram</div>
            <div class="progress progress-primary" role="progress" min="0" max="100" value="20">
              <div class="progress-bar"></div>
            </div>
          </div>

          <div class="progress-container">
            <div class="progress-text progress-text-secondary">Twitter</div>
            <div class="progress progress-secondary" role="progress" min="0" max="100" value="30">
              <div class="progress-bar"></div>
            </div>
          </div>

          <div class="progress-container">
            <div class="progress-text progress-text-danger">Facebook</div>
            <div class="progress progress-danger" role="progress" min="0" max="100" value="80">
              <div class="progress-bar"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
