import styled from '@emotion/styled';

export const StyledBgDiv = styled.div`
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  position: relative;
  display: none;
  background: ${props => (props.isDarkThemeActive ? '#001932' : undefined)};

  @media (max-width: 767px) {
    display: block;
  }
`;

export const StyledNavbar = styled('nav')`
  width: 100%;
  padding: 30px 10px;
  transition: 1s;
  background: #fff;
  // background: ${props => props.theme.colors.background};
  &.doc-nav {
    padding: 5px 3rem;
    border-bottom: 1px solid #eee;
  }
  @media (max-width: 768px) {
    padding: 0px 10px;
    &.doc-nav {
      padding-top: 2rem;
    }
  }
  .windframe-link {
    padding: 1rem;
    color: #3329e0;
    margin-left: 1rem;
  }
  .dropdown-menu {
    max-height: 70vh;
    overflow-y: auto;
  }
  .nav-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    .nav-menux {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .loop {
      display: flex;
      align-items: center;
      width: 60%;
      @media (max-width: 7680px) {
        margin-right: 20px;
      }
      max-width: 300px;
      a {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .nav-logo {
      display: flex;
      padding: 10px 5px;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 40px;
      img {
        width: 100%;
      }
    }
    .nav-list {
      display: flex;
      padding: 10px 5px;
      align-items: center;
      justify-content: center;
      ul {
        list-style: none;
        text-decoration: none;
        display: flex;
        align-items: baseline;
        flex-direction: row;
        margin-bottom: 0px !important;
        justify-content: center;
        li {
          padding: 0px 10px;
          color: #333333;
          font-weight: 700;

          ul {
            li {
              width: 220px;
              padding: 5px 15px;
              font-weight: normal;
              font-size: 0.85em;
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      .nav-list {
        display: none;
      }
      .nav-logo {
        padding: 0px;
      }
    }
    .nav-buttons {
      padding: 10px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .nav {
        .dropdown {
          position: relative;
          margin-right: 20px;
          .dropdown-toggle {
            width: 100%;
            padding: 17px 10px;
            border: 1px solid #3329e0;
            border-radius: 20px;
            text-decoration: none;
            font-size: 18px;
            font-weight: 800;
            color: #3329e0;
            &:before {
              content: ' ' !important;
              transform: scale(0);
            }
          }
          .dropdown-menu {
            position: absolute;
            width: 100% !important;
            list-style: none;
            flex-direction: column;
            display: block;
            top: 75%;
            left: 12px;
            li {
              padding: 5px 10px;

              .link {
                color: #000 !important;
                text-decoration: none;
              }
            }
          }
        }
      }
      button {
        margin: 0 10px;
      }
      .signup {
        padding: 15px 30px;
        border: none;
        background: #ccc9ff;
        box-shadow: 0px 5px 5px rgba(75, 93, 104, 0.1);
        border-radius: 16px;
        color: #3329e0;
        font-weight: 800;
        width: max-content;
        cursor: pointer !important;
        img {
          margin-left: 10px;
          height: 20px;
        }
      }
      .signin {
        width: 80px;
        height: 40px;
        border: none;
        background: #fff;
        border-radius: 20px;
        color: #000;
        font-weight: 800;
        cursor: pointer;
      }
    }
    @media (max-width: 768px) {
      .nav-buttons {
        display: none;
      }
    }
  }
  .widget {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
    display: flex;

    span {
      font-size: 0.8em;
      margin-left: 3px;
    }
    .btn {
      display: flex;
      align-items: center;
      border-radius: 0px;
      background: #eff3f6;
      border-right: 1px solid #ccc;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .count {
      color: black;
      padding: 0 10px;
      font-size: 0.9em;
      background: #eff3f6;
      display: flex;
      align-items: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
