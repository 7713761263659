/* eslint-disable*/

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
// import Loadable from 'react-loadable';
import { StyledNavbar } from './NavBar/NavBar.styles';
import { Burger } from './Burger/Burger';
import Sidebar from './sidebar';

// import config from '../../config.js';
// import LoadingProvider from './mdxComponents/loading';
import { DarkModeSwitch } from './DarkModeSwitch';
import logo from './images/logo.png';
import GitHubButton from 'react-github-btn';

// const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

// let searchIndices = [];

// if (isSearchEnabled && config.header.search.indexName) {
//   searchIndices.push({
//     name: `${config.header.search.indexName}`,
//     title: `Results`,
//     hitComp: `PageHit`,
//   });
// }

// const LoadableComponent = Loadable({
//   loader: () => import('./search/index'),
//   loading: LoadingProvider,
// });

function myFunction() {
  var x = document.getElementById('navbar');

  if (x.className === 'topnav') {
    x.className += ' responsive';
  } else {
    x.className = 'topnav';
  }
}

const StyledBgDiv = styled('div')`
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  position: relative;
  display: none;
  background: ${props => (props.isDarkThemeActive ? '#001932' : undefined)};

  @media (max-width: 767px) {
    display: block;
  }
`;

const Header = ({
  location,
  isDarkThemeActive,
  toggleActiveTheme,
  doc,
  user,
  requestLogout,
  repo,
  sidebarComponent,
}) => {
 
      const [toggle, setToggle] = useState(false);
      const [dropdown, setDropdown] = useState(false);
      const [dropdown2, setDropdown2] = useState(false);

      const navClasses = classNames('navbar', doc ? 'doc-nav' : null);
      const _sidebarComponent = React.useMemo(() => sidebarComponent, [])

      return (
        <div
          className={'navBarWrapper'}
          style={{ maxWidth: '1200px', margin: '0 auto' }}
          id="navbar2"
        >
          <StyledNavbar className={navClasses} isDarkThemeActive={isDarkThemeActive}>
            <div className="nav-menu">
              <div className="nav-menux">
                <Link to="/">
                  <div className="nav-logo">
                    {!user ? <img src={logo} alt="devwares" /> : <img src={logo} alt="devwares" />}
                  </div>
                </Link>
                <div className="nav-list">
                  <ul>
                    <Link to="/windframe/" className="link">
                      <li>Windframe</li>
                    </Link>
                    {/* <Link to="/sales" className="link">
                      <li>Sales</li>
                    </Link> */}
                    <Link to="/resources/" className="link">
                      <li>Resources</li>
                    </Link>
                    <li className="dropdown">
                      <span
                        onClick={() => setDropdown(!dropdown)}
                        className="dropdown-toggle d-flex align-items-center"
                        style={{ cursor: 'pointer' }}
                      >
                        Products
                      </span>
                      {dropdown && (
                        <ul className="dropdown-menu d-block" role="menu">
                          <h5 className="dropdown-header">
                            <i className="fab fa-react mr-2 text-primary"></i>
                            React
                          </h5>
                          <div className="dropdown-divider"></div>
                          <li>
                            <Link to="/product/contrast/" className="link text-dark">
                              CDBReact
                            </Link>
                          </li>
                          <li>
                            <Link to="/product/contrast-pro/" className="link text-dark">
                              CDBReact PRO
                            </Link>
                          </li>
                          <li>
                            <Link to="/product/admin-contrast/" className="link text-dark">
                              CDBReact Admin Template
                            </Link>
                          </li>
                          <div className="dropdown-divider"></div>
                          <h5 className="dropdown-header">
                            <i className="fab fa-angular mr-2 text-danger"></i>
                            Angular
                          </h5>
                          <div className="dropdown-divider"></div>
                          <li>
                            <Link to="/product/angular-contrast/" className="link text-dark">
                              CDBAngular
                            </Link>
                          </li>
                          <li>
                            <Link to="/product/angular-contrast-pro/" className="link text-dark">
                              CDBAngular PRO
                            </Link>
                          </li>
                          <li>
                            <Link to="/product/angular-admin-contrast/" className="link text-dark">
                              CDBAngular Admin Template
                            </Link>
                          </li>
                          <div className="dropdown-divider"></div>
                          <h5 className="dropdown-header">
                            <i className="fab fa-js mr-2 text-warning"></i>
                            JS: Bootstrap 5
                          </h5>
                          <div className="dropdown-divider"></div>
                          <li>
                            <Link to="/product/bootstrap-contrast/" className="link text-dark">
                              CDBootstrap
                            </Link>
                          </li>
                          <li>
                            <Link to="/product/bootstrap-contrast-pro/" className="link text-dark">
                              CDBootstrap PRO
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/product/bootstrap-admin-contrast/"
                              className="link text-dark"
                            >
                              CDBootstrap Admin Template
                            </Link>
                          </li>
                          <div className="dropdown-divider"></div>
                          <h5 className="dropdown-header">
                            <i className="fas fa-infinity mr-2 text-primary"></i>
                            Loop
                          </h5>
                          <div className="dropdown-divider"></div>
                          <li>
                            <Link to="/product/loop/" className="link text-dark">
                              Loop
                            </Link>
                          </li>
                          <li>
                            <Link to="/product/loopv2/" className="link text-dark">
                              Loop v2
                            </Link>
                          </li>
                          <li>
                            <Link to="/product/loopprox/" className="link text-dark">
                              Loop pro X
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li className="dropdown">
                      <span
                        onClick={() => setDropdown2(!dropdown2)}
                        className="dropdown-toggle d-flex align-items-center"
                        style={{ cursor: 'pointer' }}
                      >
                        Docs
                      </span>
                      {dropdown2 && (
                        <ul className="dropdown-menu d-block" role="menu">
                          <li style={{ padding: '5px 10px', fontSize: '0.85em' }}>
                            <Link to="/docs/contrast/react/index/" className="link text-dark">
                              <i className="fab fa-react mr-2 text-primary"></i>
                              React
                            </Link>
                          </li>
                          <li style={{ padding: '5px 10px', fontSize: '0.85em' }}>
                            <Link to="/docs/contrast/angular/index/" className="link text-dark">
                              <i className="fab fa-angular mr-2 text-danger"></i>
                              Angular
                            </Link>
                          </li>
                          <li style={{ padding: '5px 10px', fontSize: '0.85em' }}>
                            <Link to="/docs/contrast/javascript/index/" className="link text-dark">
                              <i className="fab fa-js mr-2 text-warning"></i>
                              Js: Bootstrap 5
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <Link to="/blog/" className="link">
                      <li>Blog</li>
                    </Link>
                    <Link to="/contact/" className="link">
                      <li>Contact</li>
                    </Link>
                  </ul>
                </div>
              </div>

              <div className="nav-buttons">
              {repo &&  <GitHubButton
                  href={'https://github.com/' + repo}
                  data-icon="octicon-star"
                  data-size="large"
                  data-show-count="true"
                  aria-label={'Star ' + repo + ' on GitHub'}
                >
                  Star
                </GitHubButton>}
                <DarkModeSwitch
                  isDarkThemeActive={isDarkThemeActive}
                  toggleActiveTheme={toggleActiveTheme}
                />
                {user.loggedIn ? (
                  <ul className="nav navbar-nav">
                    <li className="dropdown">
                      <a onClick={() => setToggle(!toggle)}>
                        <span className="dropdown-toggle" style={{ cursor: 'pointer' }}>
                          Welcome
                          {/* <img src="images/droparrow.png" alt="" /> */}
                        </span>
                      </a>
                      {toggle && (
                        <ul className="dropdown-menu" role="menu">
                          <li>
                            <Link to="/windframe/subscription/" className="link">
                              Windframe
                            </Link>
                          </li>
                          <li>
                            <Link to="/app/downloads/" className="link">
                              Downloads
                            </Link>
                          </li>
                          <li>
                            <Link to="/app/change-password/" className="link">
                              Change Password
                            </Link>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                console.log('this is the header');
                                requestLogout();
                              }}
                            >
                              <span style={{ cursor: 'pointer' }} className="link">
                                Logout
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                ) : (
                  <React.Fragment>
                    <Link to="/app2/signup/" className="link">
                      <button className="signup">
                        Sign up
                        {/* <img src={chevron} alt=">" /> */}
                      </button>
                    </Link>
                    <Link to="/app2/signin/" className="link">
                      <button className="signin">Sign in</button>
                    </Link>
                  </React.Fragment>
                )}
              </div>
              <Burger user={user} />
            </div>
          </StyledNavbar>

          <StyledBgDiv isDarkThemeActive={isDarkThemeActive}>
            <div className={'navBarDefault removePadd'}>
              <span
                onClick={myFunction}
                className={'navBarToggle'}
                onKeyDown={myFunction}
                role="button"
                tabIndex={0}
              >
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
              </span>
            </div>
            {/* {isSearchEnabled ? (
              <div className={'searchWrapper'}>
                <LoadableComponent collapse={true} indices={searchIndices} />
              </div>
            ) : null} */}
          </StyledBgDiv>
          <div id="navbar" className={'topnav'}>
            <div className="visibleMobile">
                {
                  _sidebarComponent ? _sidebarComponent :  <Sidebar location={location}/>
                }
                <hr />
              </div>
          </div>
        </div>
      )
    };

export default Header;
