import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  display: flex;
  border-radius: "15px",
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin: 10px;
  }
`;

export const ButtonToolbarExample1 = () => {
  return (
    <Container>
      <div className="container">
        <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
          <div className="btn-group me-2" role="group" aria-label="First group">
            <button type="button" className="btn btn-primary">
              click me
            </button>
            <button type="button" className="btn btn-secondary">
              click me
            </button>
            <button type="button" className="btn btn-info">
              click me
            </button>
          </div>
          <div className="btn-group me-2" role="group" aria-label="First group">
            <button type="button" className="btn btn-sm btn-primary">
              click me
            </button>
            <button type="button" className="btn btn-sm btn-secondary">
              click me
            </button>
            <button type="button" className="btn btn-sm btn-info">
              click me
            </button>
          </div>
          <div className="btn-group me-2" role="group" aria-label="First group">
            <button type="button" className="btn btn-primary">
              click me
            </button>
            <button type="button" className="btn btn-secondary">
              click me
            </button>
            <button type="button" className="btn btn-info">
              click me
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};
