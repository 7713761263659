import React from 'react';

export const Navbar1 = () => {
  return (
    <>
      <header>
        <nav class="navbar navbar-expand-md navbar-dark bg-dark">
          <a class="navbar-brand" href="#">
            Navbar
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav">
              <li class="nav-item active">
                <a class="nav-link" href="#">
                  <i class="fa fa-home"></i>
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <i class="fas fa-feather"></i>
                  Features
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link" href="#">
                  <i class="fa fa-dollar"></i>
                  Pricing
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <i class="fa fa-cogs"></i>
                  Option
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export const Navbar2 = () => {
  return (
    <>
      <header>
        <nav class="navbar navbar-expand-md navbar-dark bg-dark">
          <a class="navbar-brand" href="#">
            Navbar
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav me-auto">
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <i class="fas fa-feather"></i>
                  About
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link" href="#">
                  <i class="fa fa-dollar"></i>
                  Pricing
                </a>
              </li>
            </ul>

            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <i class="fa fa-globe"></i>
                  EN
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link" href="#">
                  <i class="fa fa-user"></i>
                  Login
                </a>
              </li>
              <li class="nav-item">
                <button class="btn bg-white nav-link text-dark">Sign Up</button>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};
