import React from 'react';
import Table from './Table';
import CodeBlock from './codeBlock';
import { preToCodeBlock } from 'mdx-utils';
import AnchorTag from './anchor';
import { Code } from '../../../examples/contrast';
import {
    ColorPalette1,
    ColorPalette2,
    FormExample2,
    FormExample3,
    FormExample4,
    FormExample5,
    FormExample6,
    GridExample,
    GridExample1,
    GridExample2,
    GridExample3,
    TableExample,
    TableExample1,
    WidthExample1,
    WidthExample2,
    TailwindFixedWidth,
    TailwindPercentWidth,
    TailwindResponseWidth,
    TailwindWidthFull,
    TailwindWidthFlex
} from '../../../example';

export default {
    h1: ({ children, ...props }) => (
        <h1 className="heading1" id={children.replace(/\s+/g, '').toLowerCase()} {...props}>{children}</h1>
    ),
    h2: ({ children, ...props }) => (
        <h2 className="heading2" id={children.replace(/\s+/g, '').toLowerCase()} {...props}>{children}</h2>
    ),
    h3: ({ children, ...props }) => (
        <h3 className="heading3" id={children.replace(/\s+/g, '').toLowerCase()} {...props}>{children}</h3>
    ),
    h4: ({ children, ...props }) => (
        <h4 className="heading4" id={children.replace(/\s+/g, '').toLowerCase()} {...props}>{children}</h4>
    ),
    h5: ({ children, ...props }) => (
        <h5 className="heading5" id={children.replace(/\s+/g, '').toLowerCase()} {...props}>{children}</h5>
    ),
    h6: ({ children, ...props }) => (
        <h6 className="heading6" id={children.replace(/\s+/g, '').toLowerCase()} {...props}>{children}</h6>
    ),
    p: ({ children, ...props }) => <p className="paragraph" {...props} >{children}</p>,
    code: CodeBlock,
    a: AnchorTag,
    table: props => <Table {...props} />,
    pre: preProps => {
        const props = preToCodeBlock(preProps);
        if (props) {
            return <Code {...props} />;
        }
        return <pre {...preProps} />;
    },
    wrapper: ({ children }) => <>{children}</>,
    button: ({ children, ...rest }) => <button {...rest}>{children} </button>,
    colorPalette: ColorPalette1,
    widthExample1: WidthExample1,
    widthExample2: WidthExample2,
    TailwindFixedWidth: TailwindFixedWidth,
    TailwindPercentWidth: TailwindPercentWidth,
    TailwindResponseWidth: TailwindResponseWidth,
    TailwindWidthFull: TailwindWidthFull,
    TailwindWidthFlex: TailwindWidthFlex,
    colorPalette2: ColorPalette2,
    tableExample: TableExample,
    tableExample1: TableExample1,
    gridExample: GridExample,
    gridExample1: GridExample1,
    gridExample2: GridExample2,
    gridExample3: GridExample3,
    formExample2: FormExample2,
    formExample3: FormExample3,
    formExample4: FormExample4,
    formExample5: FormExample5,
    formExample6: FormExample6,
}