import * as React from 'react';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';

import { lightTheme, darkTheme } from './index';
import Header2 from '../Header2';
import { baseStyles } from '../styles/GlobalStyles';
import AppContext, { UserContext } from '../AppContext';

class ThemeProvider extends React.Component {
  state = {
    isDarkThemeActive: false,
  };

  componentDidMount() {
    this.retrieveActiveTheme();
  }

  retrieveActiveTheme = () => {
    const isDarkThemeActive = JSON.parse(window.localStorage.getItem('isDarkThemeActive'));

    this.setState({ isDarkThemeActive });
  };

  toggleActiveTheme = () => {
    this.setState(prevState => ({ isDarkThemeActive: !prevState.isDarkThemeActive }));

    window.localStorage.setItem('isDarkThemeActive', JSON.stringify(!this.state.isDarkThemeActive));
  };

  render() {
    const { children, sidebarComponent } = this.props;

    const { isDarkThemeActive } = this.state;

    const currentActiveTheme = isDarkThemeActive ? darkTheme : lightTheme;

    return (
      <div>
        <Global styles={baseStyles} />
        <AppContext>
          <UserContext.Consumer>
            {({ user, requestLogout }) => (
              <div>
                <Header2
                  doc="true"
                  isDarkThemeActive={isDarkThemeActive}
                  toggleActiveTheme={this.toggleActiveTheme}
                  user={user}
                  requestLogout={requestLogout}
                  sidebarComponent={sidebarComponent}
                />
                <EmotionThemeProvider theme={currentActiveTheme}>{children}</EmotionThemeProvider>
              </div>
            )}
          </UserContext.Consumer>
        </AppContext>
      </div>
    );
  }
}

export default ThemeProvider;
