import React from 'react';

export const Pane1 = () => {
  return (
    <>
      <div class="pane">
        <img
          class="pane-img pane-md"
          src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
        />
        <div class="ms-3">
          <div class="panel-title">Warren Briggs</div>
          <p class="panel-text panel-text">Australia</p>
        </div>
        <p class="ms-auto mb-0 text-danger">
          300m <i class="fas fa-map-marker-alt"></i>
        </p>
      </div>
    </>
  );
};

export const Pane2 = () => {
  return (
    <>
      <div class="pane">
        <img
          class="pane-img pane-md"
          src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170"
        />
        <div class="ms-3">
          <div class="panel-title">Warren Briggs</div>
          <p class="panel-text panel-text">Breafast</p>
        </div>

        <div class="dropdown ms-auto">
          <button
            class="btn btn-white btn-flat dropdown-toggle dropdown-toggle-false me-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <a class="dropdown-item disabled" href="#">
                Edit Profile
              </a>
            </li>
            <li>
              <a class="dropdown-divider" href="#"></a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                Action
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                Action 2
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
