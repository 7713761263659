import React from 'react';

export const Switch1 = () => {
  return (
    <>
      <label class="custom-switch">
        <input type="checkbox" />
        <span class="custom-control"></span>
      </label>

      <label class="custom-switch">
        <input type="checkbox" checked />
        <span class="custom-control"></span>
      </label>
    </>
  );
};

export const Switch2 = () => {
  return (
    <>
      <label class="custom-switch custom-switch-rounded">
        <input type="checkbox" />
        <span class="custom-control"></span>
      </label>

      <label class="custom-switch custom-switch-rounded">
        <input type="checkbox" checked />
        <span class="custom-control"></span>
      </label>
    </>
  );
};
