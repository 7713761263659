import React, { useState } from 'react';
import { CDBSelect2, CDBContainer } from 'cdbreact-pro';

export const Select2a = () => {
  const [option] = useState([
    {
      text: 'Option 1',
      icon: 'facebook',
    },
    {
      text: 'Option 2',
      icon: 'twitter',
    },
    {
      text: 'Option 3',
      icon: 'instagram',
    },
    {
      text: 'Option 4',
    },
    {
      text: 'Option 5',
    },
  ]);
  return (
    <CDBContainer>
      <CDBSelect2 options={option} iconBrand selected="Choose an option" color="white" />
    </CDBContainer>
  );
};

export const Select2b = () => {
  const [option] = useState([
    {
      text: 'Option 1',
      icon: 'facebook',
    },
    {
      text: 'Option 2',
      icon: 'twitter',
    },
    {
      text: 'Option 3',
      icon: 'instagram',
    },
    {
      text: 'Option 4',
    },
    {
      text: 'Option 5',
    },
  ]);
  return (
    <CDBContainer>
      <CDBSelect2 options={option} iconBrand selected="Choose an option" color="none" disabled />
    </CDBContainer>
  );
};

export const Select2c = () => {
  const [option] = useState([
    {
      text: 'Select Option',
      icon: 'stack-overflow',
    },
    {
      text: 'Another Option',
      icon: 'reddit',
    },
    {
      text: 'Option 3',
      icon: 'instagram',
    },
    {
      text: 'Option 4',
    },
    {
      text: 'Last Option',
    },
  ]);
  return (
    <CDBContainer>
      <CDBSelect2 options={option} iconBrand selected="Choose an option" color="primary" />
      <CDBSelect2 options={option} iconBrand selected="Choose an option" color="secondary" />
      <CDBSelect2 options={option} iconBrand selected="Choose an option" color="success" />
      <CDBSelect2 options={option} iconBrand selected="Choose an option" color="warning" />
      <CDBSelect2 options={option} iconBrand selected="Choose an option" color="dark" />
    </CDBContainer>
  );
};

export const Select2d = () => {
  const [option] = useState([
    {
      text: 'Select Option',
      icon: 'stack-overflow',
    },
    {
      text: 'Another Option',
      icon: 'reddit',
    },
    {
      text: 'Option 3',
      icon: 'instagram',
    },
    {
      text: 'Option 4',
    },
    {
      text: 'Last Option',
    },
  ]);
  return (
    <CDBContainer>
      <CDBSelect2
        options={option}
        iconBrand
        selected="Choose an option"
        hoverColor
        color="danger"
      />
    </CDBContainer>
  );
};
