import { Search } from 'styled-icons/fa-solid/Search';
import styled from '@emotion/styled';

export const SearchIcon = styled(Search)`
  width: 15px;
  pointer-events: none;
  color: #8d959d;
  margin-right: 6px;
`;

export const SearchIconWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-contents: center;
  align-items: center;
  border: 1.5px solid #8d959d;
  padding: 6px 6px;
  min-width: 120px;
  font-size: 12px;
  color: #adb5bd;
  margin: 10px;
  border-radius: 4px;
  cursor: pointer;
`;

export const ModalWrapper = styled('div')`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0rem;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(0.4375rem);
  border-radius: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999999;
`;

export const Modal = styled('div')`
  max-width: 700px;
  min-width: 400px;
  max-height: 500px;
  min-height: 350px;
  width: 100%;
  background-color: white;
  outline: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.0625rem 0.1875rem,
    rgba(0, 0, 0, 0.05) 0px 2.25rem 1.75rem -0.4375rem,
    rgba(0, 0, 0, 0.04) 0px 1.0625rem 1.0625rem -0.4375rem;
  pointer-events: all;
  max-height: calc(100vh - 15rem);
  position: relative;
  overflow: hidden;
`;

export const ModalSearchWrapper = styled('div')`
  border-bottom: 2px solid rgb(233, 236, 239);
  padding: 8px 16px;
  input {
    border: none;
    outline: none;
    font-size: 16px;
    padding-left: 8px;

    &:hover {
      outline: none;
    }
  }
`;

export const Results = styled('div')`
  max-height: calc(100vh - 18rem);
  overflow: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #ccc;
  }
`;

export const ResultItem = styled('div')`
  padding: 4px 16px;
  padding-bottom: 12px;
  cursor: pointer;
  border-bottom: 0.5px solid rgb(242 243 245);

  &:hover {
    background-color: rgb(0,0,0,0.1);
  }
  h6 {
    margin: 0;
    padding: 8px 0;
    font-size: 14px;
    font-weight: normal;
  }

  p {
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 11px;
  }
`;
