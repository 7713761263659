import React from "react";
import { Link } from "gatsby";
import select2a from "../../../../content/contrast/react/components/images/select1.png";
import select2b from "../../../../content/contrast/react/components/images/select2.png";
import select2c from "../../../../content/contrast/react/components/images/select3.png";
import select2d from "../../../../content/contrast/react/components/images/select4.png";
export default {
  React,
  Link,
  select2a,
  select2b,
  select2c,
  select2d
};