import React from 'react';
import { CDBRating, CDBContainer } from 'cdbreact-pro';

export const Rating1 = () => {
  return (
    <CDBContainer>
      <CDBRating iconRegular />
      <CDBRating iconFaces fillClassName="black-text" iconRegular />
      <CDBRating feedback />
    </CDBContainer>
  );
};
