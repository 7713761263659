import React from 'react';

export const Modal1 = () => {
  return (
    <>
      <button
        type="button"
        class="btn btn-light btn-flat"
        data-bs-toggle="modal"
        data-bs-target="#customModal1"
      >
        Modal
      </button>
      <div
        class="modal fade"
        id="customModal1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="card-border card">
            <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fEh1bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" />
            <div class="card-body">
              <div class="card-title">Title</div>
              <p class="card-text">
                Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum enim
                fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum molestie
                mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus bibendum sem.
                Morbi pulvinar a metus sit amet ullamcorper.
              </p>
              <div class="d-flex">
                <div class="d-flex justify-content-start">
                  <button class="btn btn-dark btn-flat">Label</button>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-light btn-flat close" type="button" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button class="btn btn-dark btn-flat">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Modal2 = () => {
  return (
    <>
      <button
        type="button"
        class="btn btn-light btn-flat"
        data-bs-toggle="modal"
        data-bs-target="#customModal2"
      >
        Small Modal
      </button>
      <div
        class="modal fade"
        id="customModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Title</div>
              <p class="card-text">
                Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum enim
                fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum molestie
                mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus bibendum sem.
                Morbi pulvinar a metus sit amet ullamcorper.
              </p>
              <div class="d-flex">
                <div class="d-flex justify-content-start">
                  <button class="btn btn-dark btn-flat">Label</button>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-light btn-flat close" type="button" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button class="btn btn-dark btn-flat">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-light btn-flat"
        data-bs-toggle="modal"
        data-bs-target="#customModal3"
      >
        Medium Modal
      </button>
      <div
        class="modal fade"
        id="customModal3"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Title</div>
              <p class="card-text">
                Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum enim
                fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum molestie
                mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus bibendum sem.
                Morbi pulvinar a metus sit amet ullamcorper.
              </p>
              <div class="d-flex">
                <div class="d-flex justify-content-start">
                  <button class="btn btn-dark btn-flat">Label</button>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-light close" type="button" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button class="btn btn-dark btn-flat">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-light btn-flat"
        data-bs-toggle="modal"
        data-bs-target="#customModal4"
      >
        Large Modal
      </button>
      <div
        class="modal fade"
        id="customModal4"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Title</div>
              <p class="card-text">
                Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum enim
                fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum molestie
                mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus bibendum sem.
                Morbi pulvinar a metus sit amet ullamcorper.
              </p>
              <div class="d-flex">
                <div class="d-flex justify-content-start">
                  <button class="btn btn-dark btn-flat">Label</button>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-light btn-flat close" type="button" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button class="btn btn-dark btn-flat">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-light btn-flat"
        data-bs-toggle="modal"
        data-bs-target="#customModal5"
      >
        Fluid Modal
      </button>
      <div
        class="modal fade"
        id="customModal5"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-fluid">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Title</div>
              <p class="card-text">
                Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum enim
                fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum molestie
                mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus bibendum sem.
                Morbi pulvinar a metus sit amet ullamcorper.
              </p>
              <div class="d-flex">
                <div class="d-flex justify-content-start">
                  <button class="btn btn-light btn-flat">Label</button>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-light btn-flat close" type="button" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button class="btn btn-dark btn-flat">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Modal3 = () => {
  return (
    <>
      <div class="blockcode">
        <div class="header">Modal Positions</div>
        <div class="example">
          <button
            type="button"
            class="btn btn-light btn-flat"
            data-bs-toggle="modal"
            data-bs-target="#customModal11"
          >
            Top Left Modal
          </button>
          <div
            class="modal fade"
            id="customModal11"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-top-left">
              <div class="card">
                <div class="card-body">
                  <div class="card-title">Title</div>
                  <p class="card-text">
                    Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum
                    enim fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum
                    molestie mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus
                    bibendum sem. Morbi pulvinar a metus sit amet ullamcorper.
                  </p>
                  <div class="d-flex">
                    <div class="d-flex justify-content-start">
                      <button class="btn btn-dark btn-flat">Label</button>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-light btn-flat close"
                        type="button"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button class="btn btn-dark btn-flat">Done</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-light btn-flat"
            data-bs-toggle="modal"
            data-bs-target="#customModal12"
          >
            Top Right Modal
          </button>
          <div
            class="modal fade"
            id="customModal12"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-top-right">
              <div class="card">
                <div class="card-body">
                  <div class="card-title">Title</div>
                  <p class="card-text">
                    Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum
                    enim fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum
                    molestie mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus
                    bibendum sem. Morbi pulvinar a metus sit amet ullamcorper.
                  </p>
                  <div class="d-flex">
                    <div class="d-flex justify-content-start">
                      <button class="btn btn-dark btn-flat">Label</button>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button class="btn btn-light close" type="button" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button class="btn btn-dark btn-flat">Done</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-light btn-flat"
            data-bs-toggle="modal"
            data-bs-target="#customModal13"
          >
            Bottom Left Modal
          </button>
          <div
            class="modal fade"
            id="customModal13"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-bottom-left">
              <div class="card">
                <div class="card-body">
                  <div class="card-title">Title</div>
                  <p class="card-text">
                    Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum
                    enim fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum
                    molestie mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus
                    bibendum sem. Morbi pulvinar a metus sit amet ullamcorper.
                  </p>
                  <div class="d-flex">
                    <div class="d-flex justify-content-start">
                      <button class="btn btn-dark btn-flat">Label</button>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-light btn-flat close"
                        type="button"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button class="btn btn-dark btn-flat">Done</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-light btn-flat"
            data-bs-toggle="modal"
            data-bs-target="#customModal14"
          >
            Bottom Right Modal
          </button>
          <div
            class="modal fade"
            id="customModal14"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-bottom-right">
              <div class="card">
                <div class="card-body">
                  <div class="card-title">Title</div>
                  <p class="card-text">
                    Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum
                    enim fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum
                    molestie mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus
                    bibendum sem. Morbi pulvinar a metus sit amet ullamcorper.
                  </p>
                  <div class="d-flex">
                    <div class="d-flex justify-content-start">
                      <button class="btn btn-light btn-flat">Label</button>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-light btn-flat close"
                        type="button"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button class="btn btn-dark btn-flat">Done</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-light btn-flat"
            data-bs-toggle="modal"
            data-bs-target="#customModal15"
          >
            Center Modal
          </button>
          <div
            class="modal fade"
            id="customModal15"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-center">
              <div class="card">
                <div class="card-body">
                  <div class="card-title">Title</div>
                  <p class="card-text">
                    Quisque a varius augue. Etiam volutpat ipsum nec mi porttitor, nec fermentum
                    enim fermentum. Curabitur metus eros, scelerisque vel urna a, condimentum
                    molestie mauris. Aliquam blandit congue risus ac sollicitudin. Proin tempus
                    bibendum sem. Morbi pulvinar a metus sit amet ullamcorper.
                  </p>
                  <div class="d-flex">
                    <div class="d-flex justify-content-start">
                      <button class="btn btn-light btn-flat">Label</button>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-light btn-flat close"
                        type="button"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button class="btn btn-dark btn-flat">Done</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
