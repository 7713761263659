import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin: 10px;
  }
`;

export const CardExample1 = () => {
  return (
    <Container>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <img
          className="img-fluid card-img"
          src="https://ichef.bbci.co.uk/news/999/cpsprodpb/15951/production/_117310488_16.jpg"
          style={{ width: '100%', borderRadius: "15px" }}
          alt="Contrast React Bootstrap Card component"
        />
        <div className="card-body">
          <div className="card-title">Basic</div>
          <div className="card-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ac massa gravida,
            fermentum odio a, tristique lorem. Fusce hendrerit blandit aliquam. Vivamus finibus
            libero tincidunt leo venenatis malesuada. Ut mollis finibus molestie. Phasellus id felis
            nec arcu rhoncus maximus. Quisque luctus nisi posuere velit porttitor, eu porttitor est
            mattis. Integer semper quis quam ut mollis. Vivamus congue molestie fermentum. Etiam
            diam quam, iaculis vel felis vitae, vulputate egestas diam.
          </div>
          <div className="d-flex pt-3 pb-3">
            <button className="btn btn-primary">Button</button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export const CardExample2 = () => {
  return (
    <Container>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
          <div className="d-flex justify-content-center">
            <button className="btn btn-light btn-flat btn-rounded">Button</button>
          </div>
        </div>
      </div>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <button className="btn btn-flat btn-block btn-light">Button</button>
        </div>
      </div>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
          <button className="btn btn-flat btn-rounded btn-light">Button</button>
        </div>
      </div>
    </Container>
  );
};
export const CardExample3 = () => {
  return (
    <Container>
      <div className="card card-border" style={{ width: '25rem', borderRadius: "15px" }}>
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
          <div className="d-flex justify-content-center">
            <button className="btn btn-light btn-flat btn-rounded">Button</button>
          </div>
        </div>
      </div>
      <div className="card card-border" style={{ width: '25rem', borderRadius: "15px" }}>
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <button className="btn btn-flat btn-block btn-light">Button</button>
        </div>
      </div>
      <div className="card card-border" style={{ width: '25rem', borderRadius: "15px" }}>
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
          <button className="btn btn-flat btn-rounded btn-light">Button</button>
        </div>
      </div>
    </Container>
  );
};
export const CardExample4 = () => {
  return (
    <Container>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <img
          className="card-img img-fluid"
          src="https://media.istockphoto.com/photos/male-lion-resting-on-a-rock-picture-id1333977253?b=1&k=20&m=1333977253&s=170667a&w=0&h=q_EqYl_GqFCR1XmF_AK91YRFDapwAClOoc2fZbsnmr4="
          alt="Contrast React Bootstrap Card component"
        />
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
          <div className="d-flex justify-content-center">
            <button className="btn btn-light btn-block">Button</button>
          </div>
        </div>
      </div>
      <div className="card card-border" style={{ width: '25rem', borderRadius: "15px" }}>
        <img
          className="card-img img-fluid"
          src="https://media.istockphoto.com/photos/male-lion-resting-on-a-rock-picture-id1333977253?b=1&k=20&m=1333977253&s=170667a&w=0&h=q_EqYl_GqFCR1XmF_AK91YRFDapwAClOoc2fZbsnmr4="
          alt="Contrast React Bootstrap Card component"
        />
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
          <button className="btn btn-flat btn-light">Button</button>
        </div>
      </div>
    </Container>
  );
};
export const CardExample5 = () => {
  return (
    <Container>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
        </div>
        <img
          className="card-img img-fluid"
          src="https://media.istockphoto.com/photos/male-lion-resting-on-a-rock-picture-id1333977253?b=1&k=20&m=1333977253&s=170667a&w=0&h=q_EqYl_GqFCR1XmF_AK91YRFDapwAClOoc2fZbsnmr4="
          alt="Contrast React Bootstrap Card component"
        />
        <div className="card-body">
          <button className="btn btn-light btn-flat btn-rounded">Button</button>
        </div>
      </div>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <div className="card-body">
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
        </div>
        <img
          className="card-img img-fluid"
          src="https://media.istockphoto.com/photos/male-lion-resting-on-a-rock-picture-id1333977253?b=1&k=20&m=1333977253&s=170667a&w=0&h=q_EqYl_GqFCR1XmF_AK91YRFDapwAClOoc2fZbsnmr4="
          alt="Contrast React Bootstrap Card component"
        />
        <div className="card-body">
          <button className="btn btn-block btn-light btn-flat btn-rounded">Button</button>
        </div>
      </div>
    </Container>
  );
};
export const CardExample6 = () => {
  return (
    <Container>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <div className="card-body">
          <div className="lead">Total Downloads</div>
          <h2 className="card-title">1,057,891</h2>
          <p className="small text-muted">
            Oct 1 - Dec 31,<i className="fa fa-globe"></i> Worldwide
          </p>
        </div>
      </div>
    </Container>
  );
};
export const CardExample7 = () => {
  return (
    <Container>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <img
          className="card-img img-fluid"
          style={{ borderRadius: "15px" }}
          src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg"
          alt=""
        />
        <img
          className="pane-img position-relative  mx-auto border"
          style={{ marginTop: '-5rem', width: '8rem', borderRadius: "15px" }}
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ7ntUU7AzmOxa5HB8zS83sa-JFHEfZJAoI2A&usqp=CAU"
          alt=""
        />
        <div className="card-body d-flex flex-column align-items-center my-4">
          <h2 className="card-title">Sam Russo</h2>
          <p className="card-text">Senior Software Developer</p>
          <p className="text-muted card-text">Detroit, USA</p>
          <div>
            <button className="btn btn-sm btn-dark">
              <i className="fa fa-user-plus"></i> Connect
            </button>
            <button className="btn btn-warning btn-sm text-dark">Send Message</button>
          </div>
        </div>
      </div>
    </Container>
  );
};
export const CardExample8 = () => {
  return (
    <Container>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <h2 className="card-title lead p-3 border-bottom">Team Members</h2>
        <div className="py-0 card-body">
          <div className="pane mt-2">
            <img
              className="pane-img pane-md"
              src="https://images.unsplash.com/photo-1623184663110-89ba5b565eb6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=812&q=80"
              alt="Contrast profile"
            />
            <div className="ms-3">
              <h2 className="card-title my-0 h6">Warren Briggs</h2>
              <p className="small m-0">
                <i className="fa fa-circle small p-0 me-1"></i>Online
              </p>
            </div>
            <button className="btn btn-success btn-sm ms-auto flat">Add</button>
          </div>
          <div className="pane mt-4">
            <img
              className="pane-img pane-md"
              src="https://images.unsplash.com/photo-1623184663110-89ba5b565eb6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=812&q=80"
              alt="Contrast React Bootstrap Card component"
            />
            <div className="ms-3">
              <h2 className="card-title my-0 h6">Lu Chen</h2>
              <p className="small m-0">
                <i className="fa fa-circle small text-warning me-1"></i>In a meeting
              </p>
            </div>
            <button className="btn btn-success btn-sm ms-auto flat">Add</button>
          </div>
          <div className="pane mt-4">
            <img
              className="pane-img pane-md"
              src="/img/pane/pane3.png"
              alt="Contrast React Bootstrap Card component"
            />
            <div className="ms-3">
              <h2 className="card-title my-0 h6">Lilah Loselev</h2>
              <p className="small m-0">
                <i className="fa fa-circle small text-muted me-1"></i>Not Available
              </p>
            </div>
            <button className="btn btn-success btn-sm ms-auto flat">Add</button>
          </div>
          <div className="pane my-4">
            <img
              className="pane-img pane-md"
              src="/img/pane/pane4.png"
              alt="Contrast React Bootstrap Card component"
            />
            <div className="ms-3">
              <h2 className="card-title my-0 h6">Danny</h2>
              <p className="small m-0">
                <i className="fa fa-circle small text-danger me-1"></i>Offline
              </p>
            </div>
            <button className="btn btn-success btn-sm ms-auto flat">Add</button>
          </div>
        </div>
      </div>
    </Container>
  );
};
export const CardExample9 = () => {
  return (
    <Container>
      <div
        className="card"
        style={{
          width: '25rem',
          borderRadius: "15px",
          backgroundImage:
            "url('https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_960_720.jpg')",
        }}
      >
        <div className="card-body" style={{ color: 'white' }}>
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
        </div>
        <div className="card-body">
          <button className="btn btn-light btn-rounded btn-flat">Button</button>
        </div>
      </div>

      <div
        className="card card-border"
        style={{
          width: '25rem',
          borderRadius: "15px",
          backgroundImage:
            "url('https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_960_720.jpg')",
        }}
      >
        <div className="card-body" style={{ color: 'white' }}>
          <h2 className="card-title">Heading</h2>
          <p className="card-text">Paragraph</p>
        </div>
        <div className="card-body">
          <button className="btn btn-light btn-block btn-rounded">Button</button>
        </div>
      </div>

      <div
        className="card"
        style={{
          position: 'relative',
          width: '25rem',
          height: '33rem',
          padding: '25px',
          borderRadius: "25px",
          backgroundImage: "url('https://i.imgur.com/eltDhxZ.png')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div
          className="card-body"
          style={{
            backgroundColor: 'white',
            width: 'calc(100% - 50px)',
            textAlign: 'center',
            align: 'center',
            borderRadius: "15px",
            position: 'absolute',
            bottom: '25px',
          }}
        >
          <h2 className="card-title font-weight-normal" style={{ color: '#333333' }}>
            Chukwudi Mezue
          </h2>
          <p
            className="card-text font-weight-lighter"
            style={{ color: '#333333', fontSize: '0.9rem' }}
          >
            Product Designer
          </p>
          <p style={{ color: '#333333' }} className="card-text font-weight-light">
            Enugu, Nigeria
          </p>
          <div
            className="card-body"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 50px',
              margin: '20px 0',
            }}
          >
            <i className="fab fa-2x fa-facebook-f" style={{ color: '#333333' }}></i>
            <i className="fab fa-2x fa-twitter" style={{ color: '#333333' }}></i>
            <i className="fab fa-2x fa-instagram" style={{ color: '#333333' }}></i>
          </div>
          <button className="btn btn-dark btn-block" style={{ margin: '0 auto' }}>
            Add User
          </button>
        </div>
      </div>

      <div
        className="card"
        style={{
          position: 'relative',
          width: '25rem',
          height: '33rem',
          borderRadius: "15px",
          padding: '25px',
          backgroundImage: "url('https://imgur.com/eltDhxZ.png')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div
          className="card-body"
          style={{
            backgroundColor: 'white',
            width: 'calc(100% - 50px)',
            textAlign: 'center',
            align: 'center',
            borderRadius: "15px",
            position: 'absolute',
            bottom: '25px',
          }}
        >
          <h2 style={{ color: '#333333' }} className="font-weight-normal">
            Mac Xenon
          </h2>
          <p style={{ color: '#333333', fontSize: '0.9rem' }} className="font-weight-lighter">
            Product Designer
          </p>
          <p style={{ color: '#333333' }} className="font-weight-light">
            Enugu, Nigeria
          </p>
          <button className="btn btn-dark btn0block" style={{ margin: '0 auto' }}>
            Send Message
          </button>
        </div>
      </div>
    </Container>
  );
};
export const CardExample10 = () => {
  return (
    <Container>
      <div
        className="card"
        style={{
          position: 'relative',
          width: '25rem',
          height: '33rem',
          borderRadius: "15px",
          padding: '25px',
          backgroundImage:
            "url('https://images.unsplash.com/photo-1623184663110-89ba5b565eb6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=812&q=80')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div
          className="card-body"
          style={{
            backgroundColor: 'white',
            width: 'calc(100% - 50px)',
            textAlign: 'center',
            align: 'center',
            position: 'absolute',
            borderRadius: "15px",
            bottom: '25px',
          }}
        >
          <h2 style={{ color: '#333333' }} className="font-weight-normal">
            Mac Xenon
          </h2>
          <p style={{ color: '#333333', fontSize: '0.9rem' }} className="font-weight-lighter">
            Product Designer
          </p>
          <p style={{ color: '#333333' }} className="font-weight-light">
            Enugu, Nigeria
          </p>
          <div
            className="card-body"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 50px',
              borderRadius: "15px",
              margin: '20px 0',
            }}
          >
            <i className="fab fa-2x fa-facebook-f" style={{ color: '#333333' }}></i>
            <i className="fab fa-2x fa-twitter" style={{ color: '#333333' }}></i>
            <i className="fab fa-2x fa-instagram" style={{ color: '#333333' }}></i>
          </div>
          <button className="btn btn-dark btn-block" style={{ margin: '0 auto' }}>
            Add User
          </button>
        </div>
      </div>

      <div
        className="card"
        style={{
          position: 'relative',
          width: '25rem',
          height: '33rem',
          borderRadius: "15px",
          padding: '25px',
          backgroundImage:
            "url('https://images.unsplash.com/photo-1623184663110-89ba5b565eb6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=812&q=80')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div
          className="card-body"
          style={{
            backgroundColor: 'white',
            width: 'calc(100% - 50px)',
            textAlign: 'left',
            align: 'center',
            borderRadius: "15px",
            position: 'absolute',
            bottom: ' 0',
          }}
        >
          <h2 style={{ color: '#333333' }} className="font-weight-normal">
            Mac Xenon
          </h2>
          <p style={{ color: '#333333', fontSize: '0.9rem' }} className="font-weight-lighter">
            Product Designer
          </p>
          <p style={{ color: '#333333' }} className="font-weight-light">
            Enugu, Nigeria
          </p>
          <button className="btn btn-dark btn0block">Send Message</button>
        </div>
      </div>

      <div
        className="card"
        style={{
          position: 'relative',
          width: '25rem',
          borderRadius: "15px",
          height: '33rem',
          backgroundImage:
            "url('https://images.unsplash.com/photo-1623184663110-89ba5b565eb6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=812&q=80')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div
          className="card-body"
          style={{
            backgroundColor: 'white',
            width: 'calc(100% - 50px)',
            textAlign: 'center',
            borderRadius: "15px",
            align: 'center',
            position: 'absolute',
            bottom: ' 0',
          }}
        >
          <h2 style={{ color: '#333333' }} className="font-weight-normal">
            Mac Xenon
          </h2>
          <p style={{ color: '#333333', fontSize: '0.9rem' }} className="font-weight-lighter">
            Product Designer
          </p>
          <p style={{ color: '#333333' }} className="font-weight-light">
            Enugu, Nigeria
          </p>
          <button className="btn btn-dark btn-block" style={{ margin: '0 auto' }}>
            Send Message
          </button>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: "15px",
              padding: '0 50px',
              margin: '20px 0',
            }}
          >
            <button className="btn btn-dark">
              <i className="fab fa-facebook-f"></i>
            </button>
            <button className="btn btn-dark">
              <i className="fab fa-twitter"></i>
            </button>
            <button className="btn btn-dark">
              <i className="fab fa-instagram"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="card" style={{ width: '25rem', borderRadius: "15px" }}>
        <img alt="" className="img-fluid w-100" src="/img/uber.png" />
        <img
          style={{ marginTop: '-5rem', borderStyle: 'solid', borderWidth: '4px', borderRadius: "15px" }}
          className="mx-auto border-white rounded-circle"
          width="130px"
          src="/img/ellipse.png"
          alt=""
        />
        <div className="card-body d-flex flex-column align-items-center mb-4 pt-1" style={{ marginTop: '100px' }}>
          <h4>Chukwudi Mezue</h4>
          <p className="text-muted">Product Designer</p>
          <p style={{ fontSize: '16', color: '#333' }}>Enugu, Nigeria</p>
          <p style={{ color: '#333', marginTop: '10px' }}>
            Creating High Quality Resources and tools to Aid developers during the developement of
            their projects
          </p>
          <div className="d-flex justify-content-between w-100">
            <button className="w-50 me-4 btn btn-dark">Add User</button>
            <button className="w-50 btn btn-outline-dark"> Send Message </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

{
  /*   <div className="card mt-4" style="width: 25rem;">
          <img className="img-fluid w-100" src="/img/uber.png" />
          <div className="card-body d-flex justify-content-between">
            <img
              style="margin-top:-6rem; border-style:solid; border-width:4px;"
              className="border-white rounded-circle"
              width="130px"
              height="130px"
              src="/img/ellipse.png"
            />
            <div className="align-items-left pt-1 pb-0">
              <h4>Chukwudi Mezue</h4>
              <p className="text-muted">Product Designer</p>
            </div>
          </div>
          <div className="card-body d-flex flex-column align-items-center mb-2">
            <p style="color:#333;">
              Creating High Quality Resources and tools to Aid developers during the developement of
              their projects
            </p>
            <div className="d-flex justify-content-between w-100">
              <button className="w-50 me-4 btn btn-dark">Add User</button>
              <button className="w-50 btn btn-outline-dark"> Send Message </button>
            </div>
          </div>
          <div
            style="
      display:flex;
      justify-content:space-between;
      align-items:center;
      padding:0 20%;
      margin:0 0 20px;
    "
          >
            <button style="height:32px;" className="btn btn-outline-dark btn-flat btn-sm">
              <i className="fa-lg fab fa-facebook-f"></i>
            </button>
            <button style="height:32px;" className="btn btn-outline-dark btn-flat btn-sm">
              <i className="fa-lg fab fa-twitter"></i>
            </button>
            <button style="height:32px;" className="btn btn-outline-dark btn-flat btn-sm">
              <i className="fa-lg fab fa-instagram"></i>
            </button>
          </div>
        </div>

        <div className="card mt-4" style="width: 25rem;">
          <img
            style="margin-top:2rem; border-style:solid; border-width:4px;"
            className="mx-auto border-white rounded-circle"
            width="130px"
            src="/img/ellipse.png"
          />
          <div className="card-body d-flex flex-column align-items-center mb-4 pt-1">
            <h4>Chukwudi Mezue</h4>
            <p className="text-muted">Product Designer</p>
            <p style="font-size:16;color:#333;">Enugu, Nigeria</p>
            <p style="color:#333;">
              Creating High Quality Resources and tools to Aid developers during the developement of
              their projects
            </p>
            <div className="w-100">
              <button className="btn btn-block btn-dark">Send Message</button>
              <button className="btn btn-block btn-outline-dark"> Send Message </button>
            </div>
          </div>
        </div>

        <div className="card" style="width: 25rem;">
          <img className="img-fluid w-100" src="/img/uber.png" />
          <img
            style="margin-top:-5rem; border-style:solid; border-width:4px;"
            className="mx-auto border-white rounded-circle"
            width="130px"
            src="/img/ellipse.png"
          />
          <div className="card-body d-flex flex-column align-items-center mb-4 pt-1">
            <h4>Chukwudi Mezue</h4>
            <p className="text-muted">Product Designer</p>
            <p style="font-size:16;color:#333;">Enugu, Nigeria</p>
            <div
              style="
        width:100%;
        display:flex;
        justify-content:space-between;
        padding:0 70px;
        margin:10px 0 30px;"
            >
              <i className="fa-lg fa text-dark fa-facebook-f"></i>
              <i className="fa-lg fa text-dark fa-twitter"></i>
              <i className="fa-lg fa text-dark fa-instagram"></i>
            </div>
            <div className="w-100">
              <button className="btn btn-block btn-dark mb-3">Add User</button>
              <button className="btn btn-block btn-outline-dark"> Send Message </button>
            </div>
          </div>
        </div>

        <div className="card mt-4" style="width: 25rem;">
          <img className="img-fluid w-100" src="/img/uber.png" />
          <img
            style="margin-top:-5rem; border-style:solid; border-width:4px;"
            className="mx-auto border-white rounded-circle"
            width="130px"
            src="/img/ellipse.png"
          />
          <div className="card-body d-flex flex-column align-items-center mb-4 pt-1">
            <h4>Chukwudi Mezue</h4>
            <p className="text-muted">Product Designer</p>
            <p style="font-size:16;color:#333;">Enugu, Nigeria</p>
            <p style="color:#333; margin:20px 0 30px;">
              Creating High Quality Resources and tools to Aid developers during the developement of
              their projects
            </p>
            <div className="w-100">
              <button className="btn btn-dark btn-block mb-0">Send Message</button>
            </div>
          </div>
        </div>

        <div className="card" style="width:25rem; margin-top:6rem;">
          <img
            style="margin-top:-4rem; border-style:solid; border-width:4px;"
            className="mx-auto border-white rounded-circle"
            width="130px"
            src="/img/ellipse.png"
          />
          <div className="card-body d-flex flex-column align-items-center mb-4 pt-1">
            <h4>Chukwudi Mezue</h4>
            <p className="text-muted">Product Designer</p>
            <p style="font-size:16;color:#333;">Enugu, Nigeria</p>
            <p style="color:#333;">
              Creating High Quality Resources and tools to Aid developers during the developement of
              their projects
            </p>
            <div className="w-100">
              <button className="btn btn-dark btn-block mb-3">Send Message</button>
              <button className="btn btn-outline-dark btn-block"> Send Message </button>
            </div>
          </div>
        </div> */
}
