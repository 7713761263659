import React from "react";
import { Link } from "gatsby";
import progress1 from "../../../../content/contrast/react/components/images/progress1.png";
import progress2 from "../../../../content/contrast/react/components/images/progress2.png";
import progress3 from "../../../../content/contrast/react/components/images/progress3.png";
import progress4 from "../../../../content/contrast/react/components/images/progress4.png";
import progress5 from "../../../../content/contrast/react/components/images/progress5.png";
export default {
  React,
  Link,
  progress1,
  progress2,
  progress3,
  progress4,
  progress5
};