import React from 'react';

export const Breadcrumb1 = () => {
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active">Home</li>
        </ol>
      </nav>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item text-primary">Home</li>
          <li class="breadcrumb-item active" aria-current="page">
            Library
          </li>
        </ol>
      </nav>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-flat">
          <li class="breadcrumb-item">Home</li>
          <li class="breadcrumb-item">Library</li>
          <li class="breadcrumb-item active" aria-current="page">
            Data
          </li>
        </ol>
      </nav>
    </>
  );
};
