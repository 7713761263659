import React from 'react';
import { CDBNotification, CDBContainer } from 'cdbreact-pro';

export const Notification1 = () => {
  return (
    <CDBContainer>
      <CDBNotification
        show
        fade
        autohide={2}
        title="Contrast"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />
      <CDBNotification
        show
        fade
        iconClassName="text-primary"
        title="Contrast"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />
      <CDBNotification
        show
        fade
        iconClassName="text-secondary"
        title="Contrast"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />
    </CDBContainer>
  );
};

export const Notification2 = () => {
  return (
    <CDBContainer>
      <CDBNotification
        show
        fade
        iconClassName="text-primary"
        title="Contrast"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />
      <CDBNotification
        show
        fade
        iconClassName="text-secondary"
        title="Contrast"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />
      <CDBNotification
        show
        fade
        iconClassName="text-primary"
        title="Contrast"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />
    </CDBContainer>
  );
};

export const Notification3 = () => {
  return (
    <CDBContainer>
      <CDBNotification
        show
        fade
        title="Contrast"
        icon="award"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />
      <CDBNotification
        show
        fade
        iconClassName="text-primary"
        title="Contrast"
        icon="bus"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />

      <CDBNotification
        show
        fade
        iconClassName="text-danger"
        title="Contrast"
        icon="burn"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />
      <CDBNotification
        show
        fade
        iconClassName="text-success"
        icon="car-crash"
        title="Contrast"
        message="Hello, world! This is a primary message."
        text="11 mins ago"
      />
    </CDBContainer>
  );
};
