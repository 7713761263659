import React from 'react';
import NavBar from '../NavBar/NavBar';
import { Footer } from '../Footer/Footer';
import { PageWrapper } from './Layout.styles';
import { Global } from '@emotion/core';
import { baseStyles } from '../styles/GlobalStyles';
import AppContext, { UserContext } from '../AppContext';
import { lightTheme } from './../theme/index';
import '../../utils/App.css';
// import Ad from '../Ad/React/Ad';

export const Layout = props => {
  return (
    <AppContext>
      {/* <Banner /> */}
      {/* <Ad/> */}
      <Global styles={baseStyles} />
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <PageWrapper>
              <NavBar user={user} theme={lightTheme} />
              {props.children}
              <Footer />
            </PageWrapper>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};
