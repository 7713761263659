import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin: 10px;
  }
`;

const Container2 = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  & > * {
    margin: 10px;
  }
`;

export const ButtonExample1 = () => {
  return (
    <Container>
      <button className="btn btn-primary">Primary</button>
      <button className="btn btn-secondary">Secondary</button>
      <button className="btn btn-success">Success</button>
      <button className="btn btn-danger">Danger</button>
      <button className="btn btn-warning">Warning</button>
      <button className="btn btn-info">Info</button>
      <button className="btn btn-light">Light</button>
      <button className="btn btn-dark">Dark</button>
    </Container>
  );
};

export const ButtonExample2 = () => {
  return (
    <Container>
      <button className="btn btn-primary btn-rounded">Primary</button>
      <button className="btn btn-secondary btn-rounded">Secondary</button>
      <button className="btn btn-success btn-rounded">Success</button>
      <button className="btn btn-danger btn-rounded">Danger</button>
      <button className="btn btn-warning btn-rounded">Warning</button>
      <button className="btn btn-info btn-rounded">Info</button>
      <button className="btn btn-light btn-rounded">Light</button>
      <button className="btn btn-dark btn-rounded">Dark</button>
    </Container>
  );
};

export const ButtonExample3 = () => {
  return (
    <Container>
      <button className="btn btn-outline-primary">Primary</button>
      <button className="btn btn-outline-secondary">Secondary</button>
      <button className="btn btn-outline-success">Success</button>
      <button className="btn btn-outline-danger">Danger</button>
      <button className="btn btn-outline-warning">Warning</button>
      <button className="btn btn-outline-info">Info</button>
      <button className="btn btn-outline-light">Light</button>
      <button className="btn btn-outline-dark">Dark</button>
    </Container>
  );
};

export const ButtonExample4 = () => {
  return (
    <Container2>
      <button className="btn btn-danger btn-xl">Xl</button>
      <button className="btn btn-primary btn-lg">Lg</button>
      <button className="btn btn-secondary btn-md">Md</button>
      <button className="btn btn-success btn-sm">Sm</button>
    </Container2>
  );
};

export const ButtonExample5 = () => {
  return (
    <Container>
      <button type="button" className="btn btn-primary">
        <i className="fa fa-book"></i>
        <span> Primary </span>
      </button>
      <button type="button" className="btn btn-secondary">
        <i className="fa fa-user"></i>
        <span>Secondary </span>
      </button>
      <button type="button" className="btn btn-success">
        <i className="fa fa-check"></i>
        <span> Success </span>
      </button>
      <button type="button" className="btn btn-danger">
        <i className="fa fa-times"></i>
        <span> Cancel </span>
      </button>
      <button type="button" className="btn btn-dark btn-rounded">
        <i className="fa fa-home"></i>
        <span> Home </span>
      </button>
      <button type="button" className="btn btn-info btn-rounded">
        <i className="fa fa-book"></i>
        <span> Read </span>
      </button>
      <button type="button" className="btn btn-outline-warning btn-rounded">
        <i className="fa fa-lock"></i>
        <span> Lock </span>
      </button>
    </Container>
  );
};

export const ButtonExample6 = () => {
  return (
    <Container>
      <button className="btn btn-gradient-primary">Primary</button>
      <button className="btn btn-gradient-secondary">Secondary</button>
      <button className="btn btn-gradient-success">Success</button>
      <button className="btn btn-gradient-danger">Danger</button>
      <button className="btn btn-gradient-warning">Warning</button>
      <button className="btn btn-gradient-info">Info</button>
      <button className="btn btn-gradient-light">Light</button>
      <button className="btn btn-gradient-dark">Dark</button>
    </Container>
  );
};

export const ButtonExample7 = () => {
  return (
    <Container>
      <button className="btn btn-rounded btn-outline-primary">Primary</button>
      <button className="btn btn-rounded btn-outline-secondary">Secondary</button>
      <button className="btn btn-rounded btn-outline-success">Success</button>
      <button className="btn btn-rounded btn-outline-danger">Danger</button>
      <button className="btn btn-rounded btn-outline-warning">Warning</button>
      <button className="btn btn-rounded btn-outline-info">Info</button>
      <button className="btn btn-rounded btn-outline-light">Light</button>
      <button className="btn btn-rounded btn-outline-dark">Dark</button>
    </Container>
  );
};

export const ButtonExample8 = () => {
  return (
    <Container>
      <button className="btn btn-flat btn-primary">Primary</button>
      <button className="btn btn-flat btn-secondary">Secondary</button>
      <button className="btn btn-flat btn-success">Success</button>
      <button className="btn btn-flat btn-danger">Danger</button>
      <button className="btn btn-flat btn-dark">Dark</button>
      <button className="btn btn-flat btn-warning">Warning</button>
      <button className="btn btn-flat btn-info">Info</button>
    </Container>
  );
};

export const ButtonExample9 = () => {
  return (
    <Container>
      <button className="btn btn-gradient-primary">Primary</button>
      <button className="btn btn-gradient-secondary">Secondary</button>
      <button className="btn btn-gradient-success">Success</button>
      <button className="btn btn-gradient-danger">Danger</button>
      <button className="btn btn-gradient-warning">Warning</button>
      <button className="btn btn-gradient-info">Info</button>
      <button className="btn btn-gradient-light">Light</button>
      <button className="btn btn-gradient-dark">Dark</button>
    </Container>
  );
};

export const ButtonExample10 = () => {
  return (
    <Container>
      <button className="btn btn-warning">
        <i className="fa fa-magic"></i>
        Warning
      </button>
      <button className="btn btn-info">
        Info
        <i className="fa fa-magic"></i>
      </button>
    </Container>
  );
};

export const ButtonExample11 = () => {
  return (
    <Container>
      <button className="btn btn-secondary disabled">Disabled Button</button>
    </Container>
  );
};

export const ButtonExample12 = () => {
  return (
    <Container2>
      <div className="d-grid gap-2">
        <button className="btn btn-primary" type="button">
          Button
        </button>
        <button className="btn btn-primary" type="button">
          Button
        </button>
      </div>
    </Container2>
  );
};
