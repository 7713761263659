import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import Layout from '../components/docLayout';
import NextPrevious from '../components/NextPrevious';
import config from '../../config';
import { GitHubBtn, StyledHeading, StyledMainWrapper } from '../components/styles/Docs';
import RightSidebar from '../components/rightSidebar';
import Advert from '../components/Advert';
import { CDBBtn } from 'cdbreact-pro';

const forcedNavOrder = config.sidebar.forcedNavOrder;

export default class MDXRuntimeTest extends Component {
  render() {
    const { data } = this.props;

    if (!data.allMdx) {
      return null;
    }
    const {
      allMdx,
      mdx,
      site: {
        siteMetadata: { docsLocation, title },
      },
    } = data;

    const gitHub = require('../components/images/github.svg');

    const navItems = allMdx.edges
      .map(({ node }) => node.fields.slug)
      .filter(slug => slug !== '/')
      .reduce(
        (acc, cur) => {
          if (forcedNavOrder.find(url => url === cur)) {
            return { ...acc, [cur]: [cur] };
          }

          let prefix = cur.split('/')[1];

          if (config.gatsby && config.gatsby.trailingSlash) {
            prefix = prefix + '/';
          }

          if (prefix && forcedNavOrder.find(url => url === `/${prefix}`)) {
            return { ...acc, [`/${prefix}`]: [...acc[`/${prefix}`], cur] };
          } else {
            return { ...acc, items: [...acc.items, cur] };
          }
        },
        { items: [] }
      );

    const nav = forcedNavOrder
      .reduce((acc, cur) => {
        return acc.concat(navItems[cur]);
      }, [])
      .concat(navItems.items)
      .map(slug => {
        if (slug) {
          const { node } = allMdx.edges.find(({ node }) => node.fields.slug === slug);

          return { title: node.fields.title, url: '/docs' + node.fields.slug };
        }
      });

    // meta tags
    const metaTitle = mdx.frontmatter.metaTitle;

    const metaDescription = mdx.frontmatter.metaDescription;

    const link = mdx.frontmatter.githubUrl;

    let canonicalUrl = config.gatsby.siteUrl;
    canonicalUrl =
      config.gatsby.pathPrefix !== '/' ? canonicalUrl + config.gatsby.pathPrefix : canonicalUrl;

    canonicalUrl = canonicalUrl + '/docs' + mdx.fields.slug;

    let location2 = { pathname: '/docs' + mdx.fields.slug };

    return (
      <div>
        {/* <Banner /> */}
        {/* <Ad /> */}
        <Layout {...this.props}>
          <Helmet>
            {metaTitle ? <title>{metaTitle}</title> : null}
            {metaTitle ? <meta name="title" content={metaTitle} /> : null}
            {metaDescription ? <meta name="description" content={metaDescription} /> : null}
            {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
            {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
            <meta property="og:site_name" content="Devwares" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@devwares" />
            <meta name="twitter:creator" content="@devwares" />
            {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
            {metaDescription ? (
              <meta property="twitter:description" content={metaDescription} />
            ) : null}
            <meta
              property="og:image"
              content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg"
            />
            <meta
              name="twitter:image"
              content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg"
            />
          </Helmet>
          <div style={{ display: 'flex' }}>
            <div className="main-doc">
              {/* <Notification /> */}
              <div className={'titleWrapper'}>
                <StyledHeading>{mdx.fields.title}</StyledHeading>
                {link && (
                  <GitHubBtn>
                    <CDBBtn flat type="button" className="edit-on-github btn" circle size="small">
                      <a href={`${link}`} target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-pencil-alt ml-0"></i> Edit on Github
                      </a>
                    </CDBBtn>
                  </GitHubBtn>
                )}
              </div>
              <StyledMainWrapper>
                {/* <a href="/product/contrast-pro" className="p-2">
                  <img src={image} alt="ad-banner" />
                </a> */}
                <MDXRenderer>{mdx.body}</MDXRenderer>
                <Advert packageName="react" />
              </StyledMainWrapper>
              <div className={'addPaddTopBottom'}>
                <NextPrevious mdx={mdx} nav={nav} />
              </div>
            </div>
            <div className={'hiddenMobile'} style={{ width: '25%' }}>
              <RightSidebar location={this.props.location ? this.props.location : location2} />
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        githubUrl
        metaTitle
        metaDescription
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;
