import React from 'react';
import styled from '@emotion/styled';

import { CDBInput, CDBBox as Box } from 'cdbreact-pro';

export const InputExampleBootstrap1 = () => {
  return (
    <>
      <div class="contrast-input input-color-primary">
        <input type="text" class="form-control" />
      </div>
      <div class="contrast-input input-color-primary bg">
        <input type="text" class="form-control" placeholder="Primary" />
      </div>
      <div class="contrast-input input-color-secondary bg">
        <input type="text" class="form-control" placeholder="Secondary" />
      </div>
      <div class="contrast-input input-color-secondary">
        <input type="text" class="form-control" placeholder="Secondary" />
      </div>
      <div class="contrast-input input-color-success">
        <input type="text" class="form-control" placeholder="Success" />
      </div>
      <div class="contrast-input input-color-success bg">
        <input type="text" class="form-control" placeholder="Success" />
      </div>
      <div class="contrast-input input-color-danger bg">
        <input type="text" class="form-control" placeholder="Danger" />
      </div>
      <div class="contrast-input input-color-warning">
        <input type="text" class="form-control" placeholder="Warning" />
      </div>
      <div class="contrast-input input-color-info">
        <input type="text" class="form-control" placeholder="Info" />
      </div>
      <div class="contrast-input input-color-dark">
        <input type="text" class="form-control" placeholder="Dark" />
      </div>
      <div class="contrast-input input-outline">
        <input type="number" class="form-control" placeholder="Number" />
      </div>
      <div class="contrast-input input-color-secondary bg" size="20px">
        <input type="text" class="form-control" />
      </div>
      <div class="contrast-input input-color-secondary" color="secondary">
        <input type="number" class="form-control" />
      </div>
      <div class="contrast-input">
        <textarea class="form-control" rows="10" placeholder="TextArea Secondary"></textarea>
      </div>
      <div class="contrast-input input-color-primary">
        <textarea class="form-control" rows="10" placeholder="TextArea"></textarea>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="defaultCheck" />
        <label class="form-check-label" for="defaultCheck">
          {' '}
          checkbox1{' '}
        </label>
      </div>
      <div class="contrast-input form-lg input-outline" color="secondary">
        <input type="text" class="form-control" />
      </div>{' '}
    </>
  );
};
