import React from 'react';

export const ButtonGroup1 = () => {
  return (
    <>
      <div className="btn-group ">
        <button type="button" className="btn btn-primary bg-blue-700">
          Left
        </button>
        <button type="button" className="btn btn-primary bg-blue-700 ">
          Middle
        </button>
        <button type="button" className="btn btn-primary bg-blue-700 ">
          Right
        </button>
      </div>
    </>
  );
};

export const ButtonGroup2 = () => {
  return (
    <div className="btn-group">
      <button type="button" className="btn btn-warning btn-rounded bg-yellow-500">
        Left
      </button>
      <button type="button" className="btn btn-success btn-rounded bg-green-700">
        Middle
      </button>
      <button type="button" className="btn btn-primary btn-rounded bg-blue-700">
        Right
      </button>
    </div>
  );
};

export const ButtonGroup3 = () => {
  return (
    <>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-warning bg-yellow-500">
          Left
        </button>
        <button type="button" className="btn btn-success bg-green-500">
          Middle
        </button>
        <button type="button" className="btn btn-secondary bg-blue-400">
          Right
        </button>
      </div>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-info bg-blue-500">
          Left
        </button>
        <button type="button" className="btn btn-dark bg-black">
          Middle
        </button>
        <button type="button" className="btn btn-danger bg-red-500">
          Right
        </button>
      </div>
    </>
  );
};

export const ButtonGroup4 = () => {
  return (
    <>
      <div className="btn-group-vertical" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-primary bg-blue-400">
          I am Vertical
        </button>
        <button type="button" className="btn btn-secondary bg-blue-400">
          I am Vertical
        </button>
        <button type="button" className="btn btn-info bg-blue-300">
          I am Vertical
        </button>
        <button type="button" className="btn btn-success bg-green-500">
          I am Vertical
        </button>
        <button type="button" className="btn btn-danger bg-red-500">
          I am Vertical
        </button>
        <button type="button" className="btn btn-warning bg-yellow-500">
          I am Vertical
        </button>
        <button type="button" className="btn btn-dark bg-black">
          I am Vertical
        </button>
      </div>
    </>
  );
};

export const ButtonGroup5 = () => {
  return (
    <>
      <div className="btn-group btn-group-lg" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-dark bg-black">
          button
        </button>
        <button type="button" className="btn btn-dark bg-black">
          button
        </button>
        <button type="button" className="btn btn-dark bg-black">
          button
        </button>
      </div>

      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-warning bg-yellow-500">
          button
        </button>
        <button type="button" className="btn btn-warning bg-yellow-500">
          button
        </button>
        <button type="button" className="btn btn-warning bg-yellow-500">
          button
        </button>
      </div>

      <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-dark bg-black">
          button
        </button>
        <button type="button" className="btn btn-dark bg-black">
          button
        </button>
        <button type="button" className="btn btn-dark bg-black">
          button
        </button>
      </div>
    </>
  );
};

export const ButtonGroup6 = () => {
  return (
    <>
      <div className="mb-2 btn-group">
        <button type="button" className="btn m-0 btn-primary">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-secondary">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-success">
          Click me
        </button>
      </div>
      <div className="btn-group">
        <button type="button" className="btn m-0 btn-danger">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-dark">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-warning">
          Click me
        </button>
      </div>
    </>
  );
};

export const ButtonGroup7 = () => {
  return (
    <div className="btn-group">
      <button type="button" className="btn btn-sm m-0 btn-primary">
        Click me
      </button>
      <button type="button" className="btn btn-sm m-0 btn-secondary">
        Click me
      </button>
      <button type="button" className="btn btn-sm m-0 btn-success">
        Click me
      </button>
      <button type="button" className="btn btn-sm m-0 btn-danger">
        Click me
      </button>
      <button type="button" className="btn btn-sm m-0 btn-dark">
        Click me
      </button>
      <button type="button" className="btn btn-sm m-0 btn-warning">
        Click me
      </button>
    </div>
  );
};
export const ButtonGroup8 = () => {
  return (
    <div className="container btn-group-vertical">
      <button type="button" className="btn btn-lg btn-primary">
        Click me
      </button>
      <button type="button" className="btn btn-lg btn-secondary">
        Click me
      </button>
      <button type="button" className="btn btn-lg btn-success">
        Click me
      </button>
      <button type="button" className="btn btn-lg btn-danger">
        Click me
      </button>
      <button type="button" className="btn btn-lg btn-dark">
        Click me
      </button>
      <button type="button" className="btn btn-lg btn-warning">
        Click me
      </button>
    </div>
  );
};

export const ButtonGroup9 = () => {
  return (
    <>
      <div className="container btn-group-vertical">
        <button type="button" className="btn btn-md btn-primary">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-secondary">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-success">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-danger">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-dark">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-warning">
          Click me
        </button>
      </div>
    </>
  );
};
export const ButtonGroup10 = () => {
  return (
    <>
      <div className="container btn-group-vertical">
        <button type="button" className="btn btn-sm btn-primary">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-secondary">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-success">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-danger">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-dark">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-warning">
          Click me
        </button>
      </div>
    </>
  );
};
export const ButtonGroup11 = () => {
  return (
    <>
      <div className="container">
        <button type="button" className="btn m-0 btn-sm btn-primary">
          <i className="fab fa-twitter"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-secondary">
          <i className="fab fa-facebook-f"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-success">
          <i className="fab fa-reddit"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-danger">
          <i className="fab fa-instagram"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-dark">
          <i className="fab fa-imdb"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-warning">
          <i className="fab fa-stack-overflow"></i>
        </button>
      </div>
    </>
  );
};
export const ButtonGroup12 = () => {
  return (
    <>
      <div className="container d-flex">
        <button type="button" className="btn m-0 btn-md btn-primary">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-md btn-secondary">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-md btn-success">
          Click me
        </button>
        <div className="dropdown">
          <button
            className="btn m-0 btn-warning text-dark dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <a className="dropdown-item" href="/">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/">
                Another action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/">
                Something else here
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
