import styled from 'styled-components';

export const StyledBurger = styled.div`
  width: 20px;
  height: 20px;
  position: fixed;
  top: 40px;
  right: 20px;
  z-index: 20;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  .span1 {
    width: 20px;
    height: 2.5px;
    background-color: ${({ open }) => (open ? '#000' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
  }
  .span2 {
    width: 25px;
    height: 2.5px;
    background-color: ${({ open }) => (open ? '#000' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
    opacity: ${({ open }) => (open ? 0 : 1)};
  }
  .span3 {
    width: 20px;
    height: 2.5px;
    background-color: ${({ open }) => (open ? '#000' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
  }
`;
