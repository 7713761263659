import React from 'react';

export const Animation = () => {
  return (
    <>
      <div class="animated bounce infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated flash infinite ">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated pulse infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated rubberBand infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated shake infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated headShake infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated swing infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated tada infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated wobble infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated jello infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
      <div class="animated heartBeat infinite">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOdWqdDL3pQ16WMIIGyn_2uYpQQbXwQapT5Q&usqp=CAU"
          alt=""
          className="h-12 w-12"
        />
      </div>
    </>
  );
};
