import React from 'react';

export const Icon1 = () => {
  return (
    <>
      <button type="button" class="btn btn-primary">
        <i class="fa fa-book"></i>
        <span> Click me </span>
      </button>
      <button type="button" class="btn btn-secondary">
        <i class="fa fa-user"></i>
        <span>Click me </span>
      </button>
      <button type="button" class="btn btn-success">
        <i class="fa fa-check"></i>
        <span> Click me </span>
      </button>
      <button type="button" class="btn btn-danger">
        <i class="fa fa-times"></i>
        <span> Click me </span>
      </button>
      <button type="button" class="btn btn-dark">
        <i class="fa fa-home"></i>
        <span> Click me </span>
      </button>
      <button type="button" class="btn btn-info">
        <i class="fa fa-book"></i>
        <span> Click me </span>
      </button>
      <button type="button" class="btn btn-outline-warning">
        <i class="fa fa-lock"></i>
        <span> Click me </span>
      </button>
    </>
  );
};

export const Icon2 = () => {
  return (
    <>
      <i class="fa fa-book icon-border"></i>
      <i class="fa fa-user icon-border"></i>
      <i class="fa fa-check icon-border"></i>
      <i class="fa fa-times icon-border"></i>
      <i class="fa fa-home icon-border"></i>
      <i class="fa fa-book icon-border"></i>
      <i class="fa fa-lock icon-border"></i>
    </>
  );
};

export const Icon3 = () => {
  return (
    <>
      <i class="fa fa-book icon-spin"></i>
      <i class="fa fa-user icon-spin"></i>
      <i class="fa fa-check icon-spin"></i>
      <i class="fa fa-times icon-spin"></i>
      <i class="fa fa-home icon-spin"></i>
      <i class="fa fa-book icon-spin"></i>
      <i class="fa fa-lock icon-spin"></i>
    </>
  );
};

export const Icon4 = () => {
  return (
    <>
      <i class="fa fa-book icon-pull"></i>
    </>
  );
};

export const Icon5 = () => {
  return (
    <>
      <i class="fa fa-book icon-pulse"></i>
      <i class="fa fa-user icon-pulse"></i>
      <i class="fa fa-check icon-pulse"></i>
      <i class="fa fa-times icon-pulse"></i>
      <i class="fa fa-home icon-pulse"></i>
      <i class="fa fa-book icon-pulse"></i>
      <i class="fa fa-lock icon-pulse"></i>
    </>
  );
};

export const Icon6 = () => {
  return (
    <>
      <i class="fa fa-user icon-rotate"></i> <i class="fa fa-check icon-rotate"></i>
    </>
  );
};

export const Icon7 = () => {
  return (
    <>
      <i class="fa fa-user icon-lg"></i>
      <i class="fa fa-user icon"></i>
      <i class="fa fa-user icon-sm"></i>
    </>
  );
};
