import React from 'react';

export const Iframe1 = () => {
  return (
    <>
      <div className="container">
        <iframe
          id="cdb-iframe1"
          className="cdb-iframe w-100 h-100"
          src="https://www.youtube.com/embed/LebxiFwmYBc"
          title="main video"
          allowFullScreen
          frameBorder="10"
          ratio="4x3"
        ></iframe>
      </div>
    </>
  );
};
