import React from 'react';
import { CDBProgress, CDBContainer } from 'cdbreact-pro';

export const Progress2 = () => {
  return (
    <CDBContainer>
      <CDBProgress value={10} text={`${10}%`} colors="primary" />
      <CDBProgress value={20} text={`${20}%`} colors="secondary" />
      <CDBProgress value={70} text={`${70}%`} colors="success" />
      <CDBProgress value={40} text={`${40}%`} colors="danger" />
      <CDBProgress value={90} text={`${90}%`} colors="info" />
      <CDBProgress value={60} text={`${60}%`} colors="warning" />
    </CDBContainer>
  );
};

export const Progress1 = () => {
  return (
    <>
      <CDBContainer>
        <CDBProgress value={69} text={`${69}%`} colors="dark" />
      </CDBContainer>
    </>
  );
};

export const Progress3 = () => {
  return (
    <>
      <CDBContainer>
        <CDBProgress value={45} text={`${45}%`} colors="dark" height={30} />

        <CDBProgress value={60} text={`${60}%`} colors="success" height={20} />
        <CDBProgress value={80} text={`${80}%`} colors="danger" height={40} />
      </CDBContainer>
    </>
  );
};
