import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { MDXProvider } from '@mdx-js/react';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import Link from '../components/link';
import Advert from '../components/Advert';
import { Layout } from '../components/Layout/Layout';
import { lightTheme } from './../components/theme/index';
import blogMdx from './../components/mdxComponents/blogMdx';
import { StyledMainWrapperBlog } from '../components/styles/Docs';
import ShareButtons from '../components/ShareButtons/share.component';
import { StyledNextPrevious } from '../components/styles/PageNavigationButtons';
import './post.css';
import './../components/adstyle.css';
import Img from "gatsby-image/withIEPolyfill"

const Post = props => {
  const { mdx, adImage } = props.data;

  // meta tags
  const metaTitle = mdx.frontmatter.metaTitle;

  const metaDescription = mdx.frontmatter.metaDescription;

  // let location = { pathname: '/blog' + mdx.fields.slug };
  let fulllocation = { pathname: 'https://devwares.com/blog' + mdx.fields.slug };

  // let disqusConfig = {
  //   url: `https://www.devwares.com${location.pathname}`,
  //   identifier: mdx.fields.slug,
  //   title: mdx.frontmatter.title,
  // };

  return (
    <Layout>
      <Helmet>
        {metaTitle ? <title>{metaTitle}</title> : null}
        {metaTitle ? <meta name="title" content={metaTitle} /> : null}
        {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
        {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
        <meta name="og:image" content={`https://www.devwares.com${mdx.frontmatter?.image}`} />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@devwares" />
        <meta name="twitter:creator" content="@devwares" />
        {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
        {metaDescription ? <meta property="twitter:description" content={metaDescription} /> : null}
        <meta name="twitter:image" content={`https://www.devwares.com${mdx.frontmatter?.image}`} />
      </Helmet>
      <EmotionThemeProvider theme={lightTheme}>
        <MDXProvider components={blogMdx}>
          {/* <Notification /> */}
          {/* <div className="w-full h-10 px-6 flex items-center justify-center bg-orange-500 text-white">
            <p className="text-xl">
              We are live on product hunt today 🚀 and would appreciate your support!
            </p> */}
          {/* <a href="https://www.producthunt.com/posts/windframe-2-0-2" className="">
                <img
                  src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c534.png"
                  alt="ad-banner"
                  className="rounded-full w-10 h-10"
                />
              </a> */}
          {/* <a
              href="https://www.producthunt.com/posts/windframe-2-0-2"
              className="px-3 text-white underline text-xl font-bold !underline"
              style={{ textDecoration: 'underline' }}
            >
              Support on Product Hunt
            </a>
            </div> */}
          {/* <PopUp show={show} setShow={setShow} /> */}
          <div className="post">
            <div className="page-body">
              {
                mdx.frontmatter?.imageExternal ?
                  <img fetchPriority="low" loading="lazy" alt="postImage" src={mdx.frontmatter?.imageExternal} className="aspect-video object-contain w-full h-[15rem] sm:h-[20rem] md:[20rem] lg:h-[70vh] xl:h-[80vh]" />
                  :
                  mdx.frontmatter?.image && mdx.frontmatter?.image?.childImageSharp?.fluid ? <Img
                    fetchPriority="high"
                    fluid={mdx.frontmatter.image.childImageSharp.fluid}
                    alt="postImage"
                    loading="eager"
                  // className="aspect-video object-contain w-full h-[15rem] sm:h-[20rem] md:[20rem] lg:h-[70vh] xl:h-[80vh]"
                  /> : null
              }
              <p className="page-title">{mdx.frontmatter.title}</p>
              <div className="border bg-white p-4 mt-3" style={{ display: 'flex' }}>
                <img
                  fetchPriority="low"
                  src={mdx.frontmatter?.authorImage}
                  className="my-auto position-relative"
                  alt="blogImage"
                  loading="lazy"
                  style={{ height: '40px', width: "40px", borderRadius: '50%' }}
                />
                <div className="ml-4">
                  <h4 className="h6 fw-600 my-0">By {mdx.frontmatter.author}</h4>
                  <p className="mb-0">{mdx.frontmatter.role}</p>
                </div>
              </div>
              {mdx.frontmatter.lastUpdated && < div className='pt-3 inline-flex items-center gap-1'><span className='font-semibold'>Last updated:</span> <span>{mdx.frontmatter.lastUpdated}</span></div>}
              <section className="section1">
                <main className="main">
                  <StyledMainWrapperBlog>
                    <MDXRenderer>{mdx.body}</MDXRenderer>
                    <Advert packageName={mdx.frontmatter.blogtype} />
                  </StyledMainWrapperBlog>
                  <p className="h4 fw-600 aside-title">Related Posts</p>
                  <aside className="aside gap-x-6">
                    {/* <div className="top_sample">
                      <h3>All your affiliate revenue in one place.</h3>
                      <img src="https://www.devwares.com/static/heroImage-d764053bac0f535532783a5a52a06799.png" />
                      <p>Use Affilimate to aggregate, optimize, and grow your website's affiliate revenue with smarter tracking.</p>
                      <p>Create your affiliate dashboard in 5 minutes or less:</p>
                      <button>Try it free</button>
                    </div> */}

                    {mdx.frontmatter.previousTitle && (
                      <Link to={`/blog/${mdx.frontmatter.previousPath}`} className="aside-card">
                        <div className="d-flex flex-column p-0">
                          {
                            mdx.frontmatter?.imageExternal ?
                              <img fetchPriority="low" loading="lazy" alt="postImage" src={mdx.frontmatter?.imageExternal} className="img-fluid w-full aspect-video" />
                              :
                              mdx.frontmatter?.image && mdx.frontmatter?.image?.childImageSharp?.fluid ?
                                <Img
                                  fetchPriority="low"
                                  loading="eager"
                                  fluid={mdx.frontmatter?.image?.childImageSharp?.fluid}
                                  alt="postImage"
                                // className="aspect-video object-contain w-full h-[15rem] sm:h-[20rem] md:[20rem] lg:h-[70vh] xl:h-[80vh]"
                                /> : null
                          }
                          <h5 className="fw-600 h5">{mdx.frontmatter.previousTitle}</h5>
                          <p style={{ cursor: 'pointer' }} className="fw-600 text-right mt-auto">
                            See More
                            <i className="fas fa-arrow-right ml-1" />
                          </p>
                        </div>
                      </Link>
                    )}
                    {mdx.frontmatter.nextTitle && (
                      <Link to={`/blog/${mdx.frontmatter.nextPath}`} className="aside-card">
                        <div className="d-flex flex-column p-0">
                          {
                            mdx.frontmatter?.imageExternal ?
                              <img fetchPriority="low" loading='lazy' alt="postImage" src={mdx.frontmatter?.imageExternal} className="img-fluid w-full aspect-video" />
                              :
                              mdx.frontmatter?.image && mdx.frontmatter?.image?.childImageSharp?.fluid ?
                                <Img
                                  fetchPriority="high"
                                  fluid={mdx.frontmatter?.image?.childImageSharp?.fluid}
                                  alt="postImage"
                                // className="aspect-video object-contain w-full h-[15rem] sm:h-[20rem] md:[20rem] lg:h-[70vh] xl:h-[80vh]"
                                /> : null
                          }
                          <h5 className="fw-600 h5">{mdx.frontmatter.nextTitle}</h5>
                          <p style={{ cursor: 'pointer' }} className="fw-600 text-right mt-auto">
                            See More
                            <i className="fas fa-arrow-right ml-1" />
                          </p>
                        </div>
                      </Link>
                    )}
                  </aside>
                </main>
                <aside className="aside1">
                  <div className="top_sample">
                    <div className="max-h-28">
                      <script
                        async
                        type="text/javascript"
                        src="//cdn.carbonads.com/carbon.js?serve=CESIEKQI&placement=wwwdevwarescom"
                        id="_carbonads_js"
                      ></script>
                    </div>
                    <br />
                    <h4 className="font-bold text-lg">Ship Beautiful UIs 10x Faster Using AI.</h4>
                    <a href="https://windframe.dev">
                      {adImage && adImage.childImageSharp?.fluid ?
                        <Img
                          fetchPriority="low"
                          fluid={adImage.childImageSharp.fluid}
                          alt="postImage"
                          loading="lazy"
                        /> : null
                      }
                    </a>
                    <p>Use Windframe to visually build webpages in minutes using tailwind css.</p>
                    <p>
                      Generate HTML, Angular, React, Vue code for your websites on the fly and spend
                      less time centering divs 🙂
                    </p>
                    <a href="https://windframe.dev">
                      <button className=" hover:bg-indigo-700 text-white font-bold py-2 mt-2 px-3 rounded" style={{ backgroundColor: '#3329e0' }}>Try it</button>
                    </a>
                  </div>
                </aside>
              </section>
              {/* <div>
                  <img src={adImage} alt="ad-banner"/>
                </div>
              */}

              <div className="share-buttons">
                <ShareButtons
                  url={fulllocation.pathname}
                  title={mdx.frontmatter.title}
                  via={'Devwares'}
                  hashtags={'#devwares'}
                />
              </div>

              <StyledNextPrevious>
                {mdx.frontmatter.previousTitle ? (
                  <Link to={'/blog/' + mdx.frontmatter.previousPath} className={'previousBtn'}>
                    <div className={'leftArrow'}>
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        height="1em"
                        width="1em"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="currentColor"
                        className="_13gjrqj"
                      >
                        <g>
                          <line x1="19" y1="12" x2="5" y2="12" />
                          <polyline points="12 19 5 12 12 5" />
                        </g>
                      </svg>
                    </div>
                    <div className={'preRightWrapper'}>
                      <div className={'smallContent'}>
                        <span>Previous</span>
                      </div>
                      <div className={'nextPreviousTitle'}>
                        <span>{mdx.frontmatter.previousTitle}</span>
                      </div>
                    </div>
                  </Link>
                ) : null}
                {mdx.frontmatter.nextTitle ? (
                  <Link to={'/blog/' + mdx.frontmatter.nextPath} className={'nextBtn'}>
                    <div className={'nextRightWrapper'}>
                      <div className={'smallContent'}>
                        <span>Next</span>
                      </div>
                      <div className={'nextPreviousTitle'}>
                        <span>{mdx.frontmatter.nextTitle}</span>
                      </div>
                    </div>
                    <div className={'rightArrow'}>
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        height="1em"
                        width="1em"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="currentColor"
                        className="_13gjrqj"
                      >
                        <g>
                          <line x1="5" y1="12" x2="19" y2="12" />
                          <polyline points="12 5 19 12 12 19" />
                        </g>
                      </svg>
                    </div>
                  </Link>
                ) : null}
              </StyledNextPrevious>

              {/* <section className="section2">
                <p className="h4 fw-600">Comments</p>
                <CommentCount config={disqusConfig} placeholder={'...'} />
                <Disqus config={disqusConfig} />
              </section> */}
            </div>
          </div>
        </MDXProvider>
      </EmotionThemeProvider>
    </Layout>
  );
};

export default Post;

export const blogPostQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        title
        image {
          childImageSharp {
            fluid(
              quality: 80,
              maxWidth: 1000
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageExternal
        lastUpdated
        author
        role
        authorImage
        metaTitle
        metaDescription
        previousTitle
        previousPath
        nextTitle
        nextPath
        blogtype
      }
      fields {
        slug
      }
    }
    adImage: file(relativePath: {eq: "windframe-poster-image.png"}) {
      childImageSharp {
        fluid(
          quality: 80,
          maxWidth: 200,
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
   
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        imageExternal
        image {
          childImageSharp {
            fluid(
              quality: 80,
              maxWidth: 1000
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        imageExternal
        image {
          childImageSharp {
            fluid(
              quality: 80,
              maxWidth: 1000
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
