import styled from "styled-components";

export const PageWrapper = styled.main`
  min-height: 100vh;
  .active-navbar {
    .navbar {
      transition: 1s;
      background: white;
      box-shadow: 0px 3px 1px 0px rgba(43, 83, 135, 0.08);
    }
  }
`;
