import React from 'react';

export const Select1 = () => {
  return (
    <>
      <select class="select select-success">
        <option>
          <div>Select..</div>
        </option>
        <option value="1">
          <div>One</div>
        </option>
        <option value="2">
          <div>Two</div>
        </option>
        <option value="3">
          <div>Three</div>
        </option>
      </select>
    </>
  );
};
