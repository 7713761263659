import React from 'react';

export const Listgroup1 = () => {
  return (
    <>
      <ul class="list-group">
        <li class="list-group-item">List Group</li>
        <li class="list-group-item">List Group</li>
        <li class="list-group-item">List Group</li>
        <li class="list-group-item">List Group</li>
        <li class="list-group-item">List Group</li>
      </ul>
    </>
  );
};

export const Listgroup2 = () => {
  return (
    <>
      <div class="list-group">
        <a href="#" class="list-group-item list-group-item-action active">
          List Group
        </a>
        <a class="list-group-item list-group-item-action" href="#">
          List Group
        </a>
        <a class="list-group-item list-group-item-action" href="#">
          List Group
        </a>
        <a class="list-group-item list-group-item-action" href="#">
          List Group
        </a>
        <a class="list-group-item list-group-item-action" href="#">
          List Group
        </a>
      </div>
    </>
  );
};

export const Listgroup3 = () => {
  return (
    <>
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          List Group
          <div class="badge bg-primary">19</div>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          List Group
          <div class="badge bg-secondary">19</div>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          List Group
          <div class="badge bg-success">19</div>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          List Group
          <div class="badge bg-danger">19</div>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          List Group
          <div class="badge bg-warning">19</div>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          List Group
          <div class="badge bg-info">19</div>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          List Group
          <div class="badge bg-dark">19</div>
        </div>
      </div>
    </>
  );
};

export const Listgroup4 = () => {
  return (
    <>
      <ul class="list-group">
        <li class="list-group-item list-group-item-primary">List Group</li>
        <li class="list-group-item list-group-item-secondary">List Group</li>
        <li class="list-group-item list-group-item-success">List Group</li>
        <li class="list-group-item list-group-item-danger">List Group</li>
        <li class="list-group-item list-group-item-warning">List Group</li>
        <li class="list-group-item list-group-item-info">List Group</li>
        <li class="list-group-item list-group-item-light">List Group</li>
        <li class="list-group-item list-group-item-dark">List Group</li>
        <li class="list-group-item">List Group</li>
      </ul>
    </>
  );
};
