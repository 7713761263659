import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import { StyledRightNav } from "./RightNav.styles";

export const RightNav = ({ open, user }) => {

  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);

  return (
    <StyledRightNav open={open} className="right-nav">
      <Link to="/windframe/" className="link">
        <li>Windframe</li>
      </Link>
      <Link to="/windframe/pricing/" className="link">
        <li>Pricing</li>
      </Link>
      <Link to="/resources/" className="link">
        <li>Resources</li>
      </Link>
      <li className="dropdown">
        <span
          onClick={() => setDropdown(!dropdown)}
          className="dropdown-toggle d-flex align-items-center"
          style={{ cursor: 'pointer' }}
        >
          Products
        </span>
        {dropdown && (
          <ul className="dropdown-menu d-block" role="menu">
            <h5 className="dropdown-header">
              <i className="fab fa-react mr-2 text-primary"></i>
              React
            </h5>
            <div className="dropdown-divider"></div>
            <li>
              <Link to="/product/contrast/" className="link text-dark">
                CDBReact
              </Link>
            </li>
            <li>
              <Link to="/product/contrast-pro/" className="link text-dark">
                CDBReact Pro
              </Link>
            </li>
            <li>
              <Link to="/product/admin-contrast/" className="link text-dark">
                CDBReact Admin Template
              </Link>
            </li>
            <div className="dropdown-divider"></div>
            <h5 className="dropdown-header">
              <i className="fab fa-angular mr-2 text-danger"></i>
              Angular
            </h5>
            <div className="dropdown-divider"></div>
            <li>
              <Link to="/product/angular-contrast/" className="link text-dark">
                CDBAngular
              </Link>
            </li>
            <li>
              <Link to="/product/angular-contrast-pro/" className="link text-dark">
                CDBAngular Pro
              </Link>
            </li>
            <li>
              <Link
                to="/product/angular-admin-contrast/"
                className="link text-dark"
              >
                CDBAngular Admin Template
              </Link>
            </li>
            <div className="dropdown-divider"></div>
            <h5 className="dropdown-header">
              <i className="fab fa-js mr-2 text-warning"></i>
              JS: Bootstrap 5
            </h5>
            <div className="dropdown-divider"></div>
            <li>
              <Link to="/product/bootstrap-contrast/" className="link text-dark">
                CDBootstrap
              </Link>
            </li>
            <li>
              <Link to="/product/bootstrap-contrast-pro/" className="link text-dark">
                CDBootstrap Pro
              </Link>
            </li>
            <li>
              <Link
                to="/product/bootstrap-admin-contrast/"
                className="link text-dark"
              >
                CDBootstrap Admin Template
              </Link>
            </li>
            <div className="dropdown-divider"></div>
            <h5 className="dropdown-header">
              <i className="fas fa-infinity mr-2 text-primary"></i>
              Loop
            </h5>
            <div className="dropdown-divider"></div>
            <li>
              <Link to="/product/loop/" className="link text-dark">
              Loop
              </Link>
            </li>
            <li>
              <Link to="/product/loopv2/" className="link text-dark">
              Loop v2
              </Link>
            </li>
            <li>
              <Link to="/product/loopprox/" className="link text-dark">
              Loop pro X
              </Link>
            </li>
          </ul>
        )}
      </li>
      <li className="dropdown">
        <span
          onClick={() => setDropdown2(!dropdown2)}
          className="dropdown-toggle d-flex align-items-center"
          style={{ cursor: 'pointer' }}
        >
          Docs
        </span>
        {dropdown2 && (
          <ul className="dropdown-menu d-block" role="menu">
            <li>
              <Link to="/docs/contrast/react/index/" className="link text-dark">
                <i className="fab fa-react mr-2 text-primary"></i>
                React
              </Link>
            </li>
            <li>
              <Link to="/docs/contrast/angular/index/" className="link text-dark">
                <i className="fab fa-angular mr-2 text-danger"></i>
                Angular
              </Link>
            </li>
            <li>
              <Link to="/docs/contrast/javascript/index/" className="link text-dark">
                <i className="fab fa-js mr-2 text-warning"></i>
                Js: Bootstrap 5
              </Link>
            </li>
          </ul>
        )}
      </li>
      <Link to="/blog/" className="link">
        <li>Blog</li>
      </Link>

      {user.loggedIn
      ? ( <Fragment>
            <Link to="/app/downloads/" className="link">
              <li>Downloads</li>
            </Link>
            <Link to="/app/account-settings/" className="link">
              <li>Account Settings</li>
            </Link>
            <Link to="/app/change-password/" className="link">
              <li>Change Password</li>
            </Link>
          </Fragment>
        )
      : ( <Fragment>
            <Link to="/app2/signin/" className="link">
              <li>Sign In</li>
            </Link>
            <Link to="/app2/signup/" className="link">
              <li>Sign Up</li>
            </Link>
          </Fragment>
        )
      }

    </StyledRightNav>
  );
};
