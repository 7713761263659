import React from "react";
import { Link } from "gatsby";
import imgUrl from "../../../../content/contrast/react/components/images/multiselect1.png";
import imgUrl2 from "../../../../content/contrast/react/components/images/multiselect2.png";
import imgUrl3 from "../../../../content/contrast/react/components/images/multiselect3.png";
import imgUrl4 from "../../../../content/contrast/react/components/images/multiselect4.png";
export default {
  React,
  Link,
  imgUrl,
  imgUrl2,
  imgUrl3,
  imgUrl4
};