import React from 'react';

export const Collapse1 = () => {
  return (
    <>
      <div class="container container-collapse">
        <div>
          <div class="header ">Collapse</div>
          <div class="example2">
            <button
              class="btn btn-light w-fit"
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Collapse
            </button>
            <div>
              <div class="collapse" id="collapseExample">
                <div class="card card-body">
                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                  richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                  cred nesciunt sapiente ea proident.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Collapse2 = () => {
  return (
    <>
      <div class="container container-collapse">
        <div class="blockcode">
          <div class="header">Multiple Targets</div>
          <div class="example2">
            <p class="d-flex flex-wrap">
              <button
                class="btn btn-light me-0 mb-2"
                data-bs-toggle="collapse"
                href="#multiCollapseExample1"
                role="button"
                aria-expanded="false"
                aria-controls="multiCollapseExample1"
              >
                Toggle first element
              </button>
              <button
                class="btn btn-light me-0 mb-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#multiCollapseExample2"
                aria-expanded="false"
                aria-controls="multiCollapseExample2"
              >
                Toggle second element
              </button>
              <button
                class="btn btn-light me-0 mb-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target=".multi-collapse"
                aria-expanded="false"
                aria-controls="multiCollapseExample1 multiCollapseExample2"
              >
                Toggle both elements
              </button>
            </p>
            <div class="row">
              <div class="col">
                <div class="collapse multi-collapse" id="multiCollapseExample1">
                  <div class="card card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident.
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="collapse multi-collapse" id="multiCollapseExample2">
                  <div class="card card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
