import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin: 10px;
  }
`;

const Container2 = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  & > * {
    margin: 10px;
  }
`;

export const GridExample = () => {
  return (
    <Container2>

      <div class="bg-yellow-500 p-8">
        <div class="grid grid-row-3 gap-4 md:grid-row-2 lg:grid-row-4">
          <div class="bg-blue-700 w-26 h-12 flex items-center justify-center rounded-md">1</div>
          <div class="bg-blue-500 w-26  flex h-12 items-center justify-center rounded-md">2</div>
          <div class="bg-blue-300 w-26 flex h-12 items-center justify-center rounded-md">3</div>
          <div class="bg-blue-500 w-26 flex h-12 items-center justify-center rounded-md">4</div>
          <div class="bg-blue-400 w-26 flex h-12 items-center justify-center rounded-md">5</div>
          <div class="bg-blue-300 w-26 flex h-12 items-center justify-center rounded-md">6</div>
        </div>
      </div>

    </Container2>
  )
}



export const GridExample1 = () => {
  return (
    <Container2>

      <div class="bg-yellow-500 p-8">
        <div class="grid grid-cols-4">
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-700">1</div>
          <div class="w-26  flex h-12 items-center justify-center rounded-md bg-blue-500">2</div>
          <div class="w-26  flex h-12 items-center justify-center rounded-md bg-blue-300">3</div>
          <div class="w-26  flex h-12 items-center justify-center rounded-md bg-blue-500">4</div>
          <div class="w-26  flex h-12  items-center justify-center rounded-md bg-blue-400">5</div>
          <div class="w-26  flex h-12 items-center justify-center rounded-md bg-blue-200">6</div>
          <div class="w-26  flex h-12 items-center justify-center rounded-md bg-blue-600">7</div>
          <div class="w-26  flex h-12 items-center justify-center rounded-md bg-blue-100">8</div>
        </div>
      </div>

    </Container2>
  )
}



export const GridExample2 = () => {
  return (
    <Container2>

      <div class="bg-yellow-500 p-8">
        <div class="grid grid-cols-4 gap-3">
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-700">1</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-500">2</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-300">3</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-500">4</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-400">5</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-200">6</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-600">7</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-100">8</div>
        </div>
      </div>

    </Container2>
  )
}




export const GridExample3 = () => {
  return (
    <Container2>
      <div class="bg-yellow-500 p-8">
        <div class="grid grid-cols-4 gap-3 md:grid-col-4 lg:grid-col-4">
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-700">1</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-500">2</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-300">3</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-500">4</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-400">5</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-200">6</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-600">7</div>
          <div class="w-26 flex h-12 items-center justify-center rounded-md bg-blue-100">8</div>
        </div>
      </div>


    </Container2>
  )
}