import React from 'react';

export const Timepicker1 = () => {
  return (
    <>
      <div class="cs-form">
        <input type="time" class="form-control" value="10:05 AM" />
      </div>
    </>
  );
};
