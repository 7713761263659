import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'lazysizes';
import './src/styles/global.css';
import React from 'react';
import AppContext from './src/components/AppContext';
// import 'contrast-bootstrap-pro/css/bootstrap.min.css';
import 'contrast-bootstrap-pro/js/bootstrap.min.js';
// import 'contrast-bootstrap-pro/css/cdb.css';
import 'contrast-bootstrap-pro/js/cdb.js';
import './examples/contrast/javascript/script.js';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This tutorial has been updated. ` + `Reload to display the latest version?`
  );

  if (answer === true) {
    window.location.reload();
  }
};

const redirectPaths = {
  "^/windframe/?$": "https://windframe.dev",
  "^/windframe/docs/index/?$": "https://windframe.dev/docs",
  "^/windframe/docs/(.*)$": "https://windframe.dev/docs/:splat",
  "^/windframe/blocks/?$": "https://windframe.dev/blocks",
  "^/windframe/full-pages/?$": "https://windframe.dev/full-pages",
  "^/windframe/contact-us-page/?$": "https://windframe.dev/contact",
  "^/windframe/form-builder/?$": "https://windframe.dev/form-builder",
  "^/windframe/import/?$": "https://windframe.dev/import",
  "^/windframe/pricing/?$": "https://windframe.dev/pricing",
  "^/windframe/thankyou/?$": "https://windframe.dev/thank-you",
  "^/tailwindcss/classes/(.*)$": "https://windframe.dev/tailwind/classes/:splat",
  "^/blog/how-to-add-tailwind-css-colors-and-fonts-to-your-project/?$": "https://windframe.dev/blog/how-to-add-tailwind-css-colors-and-fonts-to-your-project",
  "^/blog/how-to-add-tailwind-css-grid-to-your-project/?$": "https://windframe.dev/blog/how-to-add-tailwind-css-grid-to-your-project",
  "^/blog/how-to-build-tailwindcss-progressbar/?$": "https://windframe.dev/blog/how-to-build-tailwindcss-progressbar",
  "^/blog/how-to-build-tailwindcss-timepicker-with-tailwind-element/?$": "https://windframe.dev/blog/how-to-build-tailwindcss-timepicker-with-tailwind-element",
  "^/blog/how-to-create-a-beautiful-responsive-navbar-using-tailwind-css/?$": "https://windframe.dev/blog/how-to-create-a-beautiful-responsive-navbar-using-tailwind-css",
  "^/blog/how-to-create-a-react-modal-using-tailwind-css/?$": "https://windframe.dev/blog/how-to-create-a-react-modal-using-tailwind-css",
  "^/blog/how-to-create-a-responsive-react-sidebar-design-using-tailwind-css/?$": "https://windframe.dev/blog/how-to-create-a-responsive-react-sidebar-design-using-tailwind-css",
  "^/blog/how-to-create-and-style-a-responsive-form-using-tailwindcss/?$": "https://windframe.dev/blog/how-to-create-and-style-a-responsive-form-using-tailwindcss",
  "^/blog/how-to-create-react-forms-using-tailwind-css/?$": "https://windframe.dev/blog/how-to-create-react-forms-using-tailwind-css",
  "^/blog/how-to-create-react-tables-using-tailwind-css/?$": "https://windframe.dev/blog/how-to-create-react-tables-using-tailwind-css",
  "^/blog/how-to-create-react-tabs-with-tailwind-css/?$": "https://windframe.dev/blog/how-to-create-react-tabs-with-tailwind-css",
  "^/blog/how-to-create-tailwind-css-components-for-your-website/?$": "https://windframe.dev/blog/how-to-create-tailwind-css-components-for-your-website",
  "^/blog/how-to-create-tailwind-css-dropdown/?$": "https://windframe.dev/blog/how-to-create-tailwind-css-dropdown",
  "^/blog/how-to-create-tailwind-css-modal/?$": "https://windframe.dev/blog/how-to-create-tailwind-css-modal",
  "^/blog/how-to-create-tailwind-css-tables/?$": "https://windframe.dev/blog/how-to-create-tailwind-css-tables",
  "^/blog/how-to-implement-a-react-step-progress-bar-using-tailwind-css/?$": "https://windframe.dev/blog/how-to-implement-a-react-step-progress-bar-using-tailwind-css",
  "^/blog/how-to-implement-dark-mode-in-tailwind-css/?$": "https://windframe.dev/blog/how-to-implement-dark-mode-in-tailwind-css",
  "^/blog/how-to-install-tailwind-css-in-laravel/?$": "https://windframe.dev/blog/how-to-install-tailwind-css-in-laravel",
  "^/blog/how-to-install-tailwind-css-in-vuejs/?$": "https://windframe.dev/blog/how-to-install-tailwind-css-in-vuejs",
  "^/blog/how-to-use-tailwind-css-flex/?$": "https://windframe.dev/blog/how-to-use-tailwind-css-flex",
  "^/blog/how-to-use-tailwind-css-in-HTML/?$": "https://windframe.dev/blog/how-to-use-tailwind-css-in-HTML",
  "^/blog/how-to-use-tailwind-css-in-react/?$": "https://windframe.dev/blog/how-to-use-tailwind-css-in-react",
  "^/blog/how-to-use-tailwind-css-padding-margin-and-border-in-your-project/?$": "https://windframe.dev/blog/how-to-use-tailwind-css-padding-margin-and-border-in-your-project",
  "^/blog/how-to-use-the-tailwind-css-JIT-CDN/?$": "https://windframe.dev/blog/how-to-use-the-tailwind-css-JIT-CDN",
  "^/blog/setting-up-your-first-project-using-tailwind-css/?$": "https://windframe.dev/blog/setting-up-your-first-project-using-tailwind-css",
  "^/blog/tailwind-3.0-is-finally-out/?$": "https://windframe.dev/blog/tailwind-3.0-is-finally-out",
  "^/blog/tailwind-css-10-templates-and-themes/?$": "https://windframe.dev/blog/tailwind-css-10-templates-and-themes",
  "^/blog/tailwind-css-in-nextjs/?$": "https://windframe.dev/blog/tailwind-css-in-nextjs",
  "^/blog/why-tailwind-css-is-good/?$": "https://windframe.dev/blog/why-tailwind-css-is-good",
}


export const wrapRootElement = ({ element }) => {
  const currentPath = window.location.pathname
  for (const path in redirectPaths) {
    const match = currentPath.match(new RegExp(path));

    if (match) {
      let redirectUrl = redirectPaths[path];
      if (redirectUrl.includes(':splat')) {
        redirectUrl = redirectUrl.replace(':splat', match[1]);
      }

      window.location.href = redirectUrl;
      return null;
    }
  }

  return <AppContext>{element}</AppContext>;
};
