const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://www.devwares.com',
    gaTrackingId: '',
    trailingSlash: true,
  },
  header: {
    logo: 'https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/brand.svg',
    logoLink: 'https://devwares.com',
    title:
      "<a href='https://hasura.io/learn/'><img class='img-responsive' src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/learn-logo.svg' alt='Learn logo' /></a>",
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: ``,
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      // '/introduction', // add trailing slash if enabled above
      '/contrast/react/Installation/',
      '/contrast/react/index/',
      '/contrast/react/components/',
      '/contrast/react/forms/',
      '/contrast/react/navigation/',
      '/contrast/react/widgets/',
      '/contrast/react/table/',
      '/contrast/react/sections/',
    ],
    angularForcedNavOrder: [
      '/contrast/angular/Installation/',
      '/contrast/angular/index/',
      '/contrast/angular/components/',
      '/contrast/angular/forms/',
      '/contrast/angular/navigation/',
      '/contrast/angular/widgets/',
      '/contrast/angular/table/',
      '/contrast/angular/sections/',
    ],
    javascriptForcedNavOrder: [
      '/contrast/javascript/Installation/',
      '/contrast/javascript/index/',
      '/contrast/javascript/components/',
      '/contrast/javascript/navigation/',
      '/contrast/javascript/widgets/',
      '/contrast/javascript/table/',
      '/contrast/javascript/sections/',
    ],
    windframeDocForcedNavOrder: [
      '/docs/index/',
      '/docs/creating-new-project/',
      '/docs/editing-existing-project/',
      '/docs/windframe-ai/',
      '/docs/ai-experiments/',
      '/docs/canvas-toolbar/',
      '/docs/built-in-templates/',
      '/docs/editing-templates/',
      '/docs/importing-templates/',
      '/docs/import-external-css/',
      '/docs/import-tailwind-config/',
      '/docs/importing-component/'
    ],
     componentForcedNavOrder: [
      'windframe/component/index/',
      'windframe/component/tailwind-card/'
    ],

    collapsedNav: [
      // add trailing slash if enabled above
    ],

    links: [
      { text: 'React Contrast Pro', link: 'https://devwares.com/product/contrast-pro' },
      { text: 'React Free Admin Template', link: 'https://devwares.com/product/admin-contrast' },
    ],
    angularLinks: [
      { text: 'Angular Contrast Pro', link: 'https://devwares.com/product/contrast-pro' },
      { text: 'Angular Free Admin Template', link: 'https://devwares.com/product/admin-contrast' },
    ],
    javascriptLinks: [
      { text: 'Contrast Pro', link: 'https://devwares.com/product/bootstrap-contrast-pro' },
      {
        text: 'Free Admin Template',
        link: 'https://devwares.com/product/bootstrap-admin-contrast',
      },
    ],
    windframeLinks: [
      {
        text: 'Getting Started',
        link: 'https://devwares.com/windframe/docs/index/',
      },
    ],
    componentLinks: [
      {
        text: 'Getting Started',
        link: 'https://devwares.com/windframe/components/index/',
      },
    ],


    frontline: false,
    ignoreIndex: true,
    title:
      "<a href='https://hasura.io/learn/'>graphql </a><div class='greenCircle'></div><a href='https://hasura.io/learn/graphql/react/introduction/'>react</a>",
  },
  siteMetadata: {
    title: 'Resources to help developers | Contrast | Devwares',
    description:
      'Creating high quality Resources and tools to aid developers during the developmemt of their projects',
    ogImage: null,
    docsLocation: '',
    favicon: 'src/favicon.ico',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Devwares',
      short_name: 'Devwares',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icon: 'src/pwaimage.png',
    },
  },
};

module.exports = config;
