import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

export const ProAlertJavaScript = () => {
    return (
      <div
        className="alert d-flex text-primary flex-wrap align-items-center border-primary rounded"
        style={{ borderLeftWidth: '5px', backgroundColor: '#cce5ff' }}
        role="alert"
      >
        <span>Some features in this component requires CDBootstrap Pro package.</span> 
        <span>Click
        <Link
        to="/product/bootstrap-contrast-pro"
          className="d-inline mx-1"
          style={{ fontWeight: 'bold' }}
        >
          here
        </Link>
        to try it out.</span>
      </div>
    );
  };
  
  export const ProAlertReact = () => {
    return (
        <div
          className="alert d-flex text-primary flex-wrap align-items-center border-primary rounded"
          style={{ borderLeftWidth: '5px', backgroundColor: '#cce5ff' }}
          role="alert"
        >
          <span>Some features in this component requires CDB React Pro package.</span> 
          <span>Click
          <Link
          to="/product/contrast-pro"
            className="d-inline mx-1"
            style={{ fontWeight: 'bold' }}
          >
            here
          </Link>
          to try it out.</span>
        </div>
      );
  }
  
  export const ProAlertAngular = () => {
    return (
        <div
          className="alert d-flex text-primary flex-wrap align-items-center border-primary rounded"
          style={{ borderLeftWidth: '5px', backgroundColor: '#cce5ff' }}
          role="alert"
        >
         <span> Some features in this component requires CDB Angular Pro package.</span> 
         <span>Click
          <Link
            to="/product/angular-contrast-pro"
            className="d-inline mx-1"
            style={{ fontWeight: 'bold' }}
          >
            here
          </Link>
          to try it out.</span>
        </div>
      );
  }
  