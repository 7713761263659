import React from 'react';
import styled from '@emotion/styled';

import { CDBInput, CDBBox as Box } from 'cdbreact-pro';

const FlexRowContainer = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const FlexColumnContainer = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const ContainerB = styled('div')`
  width: 50%;
  padding: 0 5px;
  display: inline-flex;
  box-sizing: border-box;
  border-radius: "15px",
`;
export const InputExample1 = () => {
  return (
    <FlexColumnContainer>
      <CDBInput type="text" placeholder="Text" color="primary" />
      <CDBInput type="number" placeholder="Numbers" />
      <CDBInput type="email" placeholder="Email" />
      <CDBInput type="password" placeholder="Password" />
      <CDBInput type="date" placeholder="Date" />
      <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
        <CDBInput type="checkbox" id="checkbox" />
        <span style={{ marginLeft: '5px' }}>Checkbox</span>
      </Box>
      <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
        <CDBInput type="radio" id="radio" />
        <span style={{ marginLeft: '5px' }}>Radio</span>
      </Box>
      <CDBInput type="textarea" placeholder="TextArea" />
    </FlexColumnContainer>
  );
};

export const InputExample2 = () => {
  return (
    <FlexColumnContainer>
      <CDBInput placeholder="Primary" background color="primary" />
      <CDBInput placeholder="Secondary" background color="secondary" />
      <CDBInput placeholder="Success" background color="success" />
      <CDBInput placeholder="Danger" background color="danger" />
      <CDBInput placeholder="Warning" background color="warning" />
      <CDBInput placeholder="Info" background color="info" />
      <CDBInput placeholder="Dark" background color="dark" />
      <Box style={{ width: '100%', flexWrap: 'wrap' }}>
        <ContainerB>
          <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
            <CDBInput type="checkbox" id="checkboxPrimary" checked color="primary" />
            <span style={{ marginLeft: '5px' }}>Primary</span>
          </Box>
        </ContainerB>
        <ContainerB>
          <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
            <CDBInput type="radio" id="radioPrimary" color="primary" />
            <span style={{ marginLeft: '5px' }}>Primary</span>
          </Box>
        </ContainerB>
        <ContainerB>
          <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
            <CDBInput type="checkbox" id="checkboxSecondary" checked color="secondary" />
            <span style={{ marginLeft: '5px' }}>Secondary</span>
          </Box>
        </ContainerB>
        <ContainerB>
          <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
            <CDBInput type="radio" id="radioSecondary" color="secondary" />
            <span style={{ marginLeft: '5px' }}>Secondary</span>
          </Box>
        </ContainerB>
        <ContainerB>
          <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
            <CDBInput type="checkbox" id="checkboxDanger" checked color="danger" />
            <span style={{ marginLeft: '5px' }}>Danger</span>
          </Box>
        </ContainerB>
        <ContainerB>
          <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
            <CDBInput type="radio" id="radioDanger" color="danger" />
            <span style={{ marginLeft: '5px' }}>Danger</span>
          </Box>
        </ContainerB>
        <ContainerB>
          <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
            <CDBInput type="checkbox" id="checkboxSuccess" checked color="success" />
            <span style={{ marginLeft: '5px' }}>Success</span>
          </Box>
        </ContainerB>
        <ContainerB>
          <Box display="flex" alignItems="center" style={{ margin: '10px 0' }}>
            <CDBInput type="radio" id="radioSuccess" color="success" />
            <span style={{ marginLeft: '5px' }}>Success</span>
          </Box>
        </ContainerB>
      </Box>
    </FlexColumnContainer>
  );
};

export const InputExample3 = () => {
  return (
    <FlexColumnContainer>
      <CDBInput fontSize={16} size="lg" placeholder="Primary" />
      <CDBInput placeholder="Primary" />
      <CDBInput fontSize={12} size="sm" placeholder="Primary" />
    </FlexColumnContainer>
  );
};
export const InputExample4 = () => {
  return (
    <FlexColumnContainer>
      <CDBInput placeholder="Username" icon={<i className="fa fa-user text-dark"></i>} />
      <CDBInput placeholder="Email" type="email" icon={<i className="fa fa-at text-dark"></i>} />
    </FlexColumnContainer>
  );
};

export const InputExample5 = () => {
  return (
    <FlexColumnContainer>
      <CDBInput disabled={true} type="email" placeholder="Your E-mail" />
    </FlexColumnContainer>
  );
};
