import * as React from 'react';
import styled from '@emotion/styled';

const TableWrapper = styled('div')`
  table {
    min-width: 100%;
    border-collapse: collapse;
    border-color: inherit;
    text-indent: 0;
    thead {
      background: ${props => props.theme.codeBlocks.headerBackground} !important;
      tr {
        th {
          text-transform: uppercase;
          color: ${props => props.theme.colors.tableColor} !important;
          letter-spacing: 0.05em;
          font-size: 14px;
        }
      }
    }
    tbody {
      background: ${props => props.theme.codeBlocks.background} !important;
      tr {
        border-bottom: 2px solid ${props => props.theme.codeBlocks.headerBackground};
        * {
          font-size: 13px;
          font-weight: 300;
          color: ${props => props.theme.colors.tableColor} !important;
        }
        th {
          letter-spacing: 0.05em;
        }
        code {
          color: #e91e63 !important;
        }
      }
    }
  }
`;

const Table = ({ children }) => {
  return (
    <TableWrapper>
      <table>{children}</table>
    </TableWrapper>
  );
};

export default Table;
