const baseTheme = {
  fonts: {
    mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
  },
  baseColors: {
    primary: '#3b82f6',
    secondary: '#6366f1',
    success: '#22c55e',
    warning: '#ffe975',
    danger: '#ef4444',
    dark: '#111827',
  },
};

const lightTheme = {
  ...baseTheme,
  colors: {
    background: '#fff',
    heading: '#000',
    text: '#3B454E',
    preFormattedText: 'rgb(245, 247, 249)',
    // link: '#1000EE',
    contentSidebarBackground: '#ede7f3',
    tableColor: 'rgb(55 65 81)',
  },
  codeBlocks: {
    background: '#fbfbfb',
    headerBackground: '#f4f4f4',
    scrollbarThumbBackground: '#b4b4b4',
    scrollbarBorderColor: '#f4f4f4',
    keyword: '#438357',
    punctuation: '#363636',
    imports: '#d938d4',
    plain: '#e91e63',
    function: '#5f3edf',
    string: '#fb7719',
    'attr-value': '#0daffd',
    'attr-name': '#725aef',
    editOnGithub: {
      color: '#333',
    },
    copyToClipboard: {
      color: '#333',
    },
  },
};

const darkTheme = {
  ...baseTheme,
  colors: {
    background: '#001933',
    heading: '#fff',
    text: '#fff',
    preFormattedText: '#000',
    link: '#1ED3C6',
    contentSidebarBackground: '#061528',
    tableColor: '#d7d7d7',
  },
  codeBlocks: {
    background: '#001428',
    headerBackground: '#000f1e',
    scrollbarThumbBackground: '#9cd6d9',
    scrollbarBorderColor: '#111b24',
    keyword: '#35fb72',
    punctuation: '#d4d4d4',
    imports: ' #d938d4',
    plain: '#e91e63',
    function: '#5f3edf',
    string: '#fb7719',
    'attr-value': '#0daffd',
    'attr-name': '#725aef',
    editOnGithub: {
      color: '#f4f4f4',
    },
    copyToClipboard: {
      color: '#f4f4f4',
    },
  },
};

export { lightTheme, darkTheme };
