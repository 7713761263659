// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-2-forgot-password-index-js": () => import("./../../../src/pages/app2/forgot-password/index.js" /* webpackChunkName: "component---src-pages-app-2-forgot-password-index-js" */),
  "component---src-pages-app-2-index-js": () => import("./../../../src/pages/app2/index.js" /* webpackChunkName: "component---src-pages-app-2-index-js" */),
  "component---src-pages-app-2-signin-index-js": () => import("./../../../src/pages/app2/signin/index.js" /* webpackChunkName: "component---src-pages-app-2-signin-index-js" */),
  "component---src-pages-app-2-signup-index-js": () => import("./../../../src/pages/app2/signup/index.js" /* webpackChunkName: "component---src-pages-app-2-signup-index-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bundle-index-js": () => import("./../../../src/pages/bundle/index.js" /* webpackChunkName: "component---src-pages-bundle-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-new-page-js": () => import("./../../../src/pages/new-page.js" /* webpackChunkName: "component---src-pages-new-page-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-aboutsample-js": () => import("./../../../src/pages/product/aboutsample.js" /* webpackChunkName: "component---src-pages-product-aboutsample-js" */),
  "component---src-pages-product-aboutsamplepro-js": () => import("./../../../src/pages/product/aboutsamplepro.js" /* webpackChunkName: "component---src-pages-product-aboutsamplepro-js" */),
  "component---src-pages-product-admin-contrast-js": () => import("./../../../src/pages/product/admin-contrast.js" /* webpackChunkName: "component---src-pages-product-admin-contrast-js" */),
  "component---src-pages-product-admin-contrast-pro-js": () => import("./../../../src/pages/product/admin-contrast-pro.js" /* webpackChunkName: "component---src-pages-product-admin-contrast-pro-js" */),
  "component---src-pages-product-angular-admin-contrast-js": () => import("./../../../src/pages/product/angular-admin-contrast.js" /* webpackChunkName: "component---src-pages-product-angular-admin-contrast-js" */),
  "component---src-pages-product-angular-admin-contrast-pro-js": () => import("./../../../src/pages/product/angular-admin-contrast-pro.js" /* webpackChunkName: "component---src-pages-product-angular-admin-contrast-pro-js" */),
  "component---src-pages-product-angular-contrast-js": () => import("./../../../src/pages/product/angular-contrast.js" /* webpackChunkName: "component---src-pages-product-angular-contrast-js" */),
  "component---src-pages-product-angular-contrast-pro-js": () => import("./../../../src/pages/product/angular-contrast-pro.js" /* webpackChunkName: "component---src-pages-product-angular-contrast-pro-js" */),
  "component---src-pages-product-bootstrap-admin-contrast-js": () => import("./../../../src/pages/product/bootstrap-admin-contrast.js" /* webpackChunkName: "component---src-pages-product-bootstrap-admin-contrast-js" */),
  "component---src-pages-product-bootstrap-admin-contrast-pro-js": () => import("./../../../src/pages/product/bootstrap-admin-contrast-pro.js" /* webpackChunkName: "component---src-pages-product-bootstrap-admin-contrast-pro-js" */),
  "component---src-pages-product-bootstrap-contrast-js": () => import("./../../../src/pages/product/bootstrap-contrast.js" /* webpackChunkName: "component---src-pages-product-bootstrap-contrast-js" */),
  "component---src-pages-product-bootstrap-contrast-pro-js": () => import("./../../../src/pages/product/bootstrap-contrast-pro.js" /* webpackChunkName: "component---src-pages-product-bootstrap-contrast-pro-js" */),
  "component---src-pages-product-contrast-js": () => import("./../../../src/pages/product/contrast.js" /* webpackChunkName: "component---src-pages-product-contrast-js" */),
  "component---src-pages-product-contrast-pro-js": () => import("./../../../src/pages/product/contrast-pro.js" /* webpackChunkName: "component---src-pages-product-contrast-pro-js" */),
  "component---src-pages-product-loop-js": () => import("./../../../src/pages/product/loop.js" /* webpackChunkName: "component---src-pages-product-loop-js" */),
  "component---src-pages-product-loopprox-js": () => import("./../../../src/pages/product/loopprox.js" /* webpackChunkName: "component---src-pages-product-loopprox-js" */),
  "component---src-pages-product-loopsample-js": () => import("./../../../src/pages/product/loopsample.js" /* webpackChunkName: "component---src-pages-product-loopsample-js" */),
  "component---src-pages-product-loopv-2-js": () => import("./../../../src/pages/product/loopv2.js" /* webpackChunkName: "component---src-pages-product-loopv-2-js" */),
  "component---src-pages-product-windframe-js": () => import("./../../../src/pages/product/windframe.js" /* webpackChunkName: "component---src-pages-product-windframe-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sales-index-js": () => import("./../../../src/pages/sales/index.js" /* webpackChunkName: "component---src-pages-sales-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-windframe-about-us-page-index-js": () => import("./../../../src/pages/windframe/about-us-page/index.js" /* webpackChunkName: "component---src-pages-windframe-about-us-page-index-js" */),
  "component---src-pages-windframe-blocks-index-js": () => import("./../../../src/pages/windframe/blocks/index.js" /* webpackChunkName: "component---src-pages-windframe-blocks-index-js" */),
  "component---src-pages-windframe-changelog-js": () => import("./../../../src/pages/windframe/changelog.js" /* webpackChunkName: "component---src-pages-windframe-changelog-js" */),
  "component---src-pages-windframe-contact-us-page-index-js": () => import("./../../../src/pages/windframe/contact-us-page/index.js" /* webpackChunkName: "component---src-pages-windframe-contact-us-page-index-js" */),
  "component---src-pages-windframe-form-builder-index-js": () => import("./../../../src/pages/windframe/form-builder/index.js" /* webpackChunkName: "component---src-pages-windframe-form-builder-index-js" */),
  "component---src-pages-windframe-form-builder-template-1-js": () => import("./../../../src/pages/windframe/form-builder/template1.js" /* webpackChunkName: "component---src-pages-windframe-form-builder-template-1-js" */),
  "component---src-pages-windframe-full-pages-index-js": () => import("./../../../src/pages/windframe/full-pages/index.js" /* webpackChunkName: "component---src-pages-windframe-full-pages-index-js" */),
  "component---src-pages-windframe-import-index-js": () => import("./../../../src/pages/windframe/import/index.js" /* webpackChunkName: "component---src-pages-windframe-import-index-js" */),
  "component---src-pages-windframe-import-template-1-js": () => import("./../../../src/pages/windframe/import/template1.js" /* webpackChunkName: "component---src-pages-windframe-import-template-1-js" */),
  "component---src-pages-windframe-index-js": () => import("./../../../src/pages/windframe/index.js" /* webpackChunkName: "component---src-pages-windframe-index-js" */),
  "component---src-pages-windframe-portfolio-website-index-js": () => import("./../../../src/pages/windframe/portfolio-website/index.js" /* webpackChunkName: "component---src-pages-windframe-portfolio-website-index-js" */),
  "component---src-pages-windframe-pricing-index-js": () => import("./../../../src/pages/windframe/pricing/index.js" /* webpackChunkName: "component---src-pages-windframe-pricing-index-js" */),
  "component---src-pages-windframe-subscription-index-js": () => import("./../../../src/pages/windframe/subscription/index.js" /* webpackChunkName: "component---src-pages-windframe-subscription-index-js" */),
  "component---src-pages-windframe-subscription-styles-js": () => import("./../../../src/pages/windframe/subscription/styles.js" /* webpackChunkName: "component---src-pages-windframe-subscription-styles-js" */),
  "component---src-pages-windframe-thankyou-index-js": () => import("./../../../src/pages/windframe/thankyou/index.js" /* webpackChunkName: "component---src-pages-windframe-thankyou-index-js" */),
  "component---src-templates-angulardocs-js": () => import("./../../../src/templates/angulardocs.js" /* webpackChunkName: "component---src-templates-angulardocs-js" */),
  "component---src-templates-blocks-js": () => import("./../../../src/templates/blocks.js" /* webpackChunkName: "component---src-templates-blocks-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-componentdocs-js": () => import("./../../../src/templates/componentdocs.js" /* webpackChunkName: "component---src-templates-componentdocs-js" */),
  "component---src-templates-docs-js": () => import("./../../../src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */),
  "component---src-templates-javascriptdocs-js": () => import("./../../../src/templates/javascriptdocs.js" /* webpackChunkName: "component---src-templates-javascriptdocs-js" */),
  "component---src-templates-tailwindclass-js": () => import("./../../../src/templates/tailwindclass.js" /* webpackChunkName: "component---src-templates-tailwindclass-js" */),
  "component---src-templates-windframedocs-js": () => import("./../../../src/templates/windframedocs.js" /* webpackChunkName: "component---src-templates-windframedocs-js" */)
}

