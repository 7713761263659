import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  ModalSearchWrapper,
  ModalWrapper,
  ResultItem,
  Results,
  SearchIcon,
  SearchIconWrapper,
} from './DocSearch.styles';
import { StaticQuery, graphql} from 'gatsby';
import Link from '../link';
import * as JsSearch from 'js-search';

function useOutsideAlerter(ref, set) {
  useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              //something here
              set(false);
          }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref]);
}


const DocSearch = ({ location }) => {
  const [openSearchModal, setSearchModalOpen] = useState(false);
  const [docPath, setDocPath] = useState(null);


  useEffect(() => {
    if (!location) return;

    const path = location.pathname.split('/')[3];
    setDocPath(path);
  }, [location]);

  return (
    <>
      <SearchIconWrapper onClick={() => setSearchModalOpen(true)}>
        <SearchIcon />
        <span>Search...</span>
      </SearchIconWrapper>
     {
      openSearchModal &&  <SearchModal docPath={docPath} setSearchModalOpen={setSearchModalOpen}/>
     }
    </>
  );
};

export default DocSearch;

const SearchModal = ({ docPath, setSearchModalOpen }) => {
  const wrapperRef = useRef()
  useOutsideAlerter(wrapperRef, setSearchModalOpen)
  return (
    <StaticQuery
      query={graphql`
        query allMdxPagesForSearch {
          allMdx {
            edges {
              node {
                fields {
                  id
                  slug
                }
                frontmatter {
                  metaDescription
                  metaTitle
                  title
                }
                rawBody
              }
            }
          }
        }
      `}
      render={({ allMdx }) => {
        const [allMdxDocs, setAllMdxDocs] = useState([]);
        const [search, setSearch] = useState([]);
        const [searchResults, setSearchResults] = useState([]);
        const [searchQuery, setSearchQuery] = useState('');

        useEffect(() => {

          if (!docPath) return;

          if (allMdx.edges === undefined || allMdx.edges.length <= 0) {
            return;
          }

          const docSearchItems = allMdx.edges
            .filter(mdx => mdx?.node?.fields?.slug?.includes('contrast') && mdx?.node?.fields?.slug?.includes(docPath))
            .map(mdx => {
              return {
                id: mdx?.node?.fields?.id,
                slug: mdx?.node?.fields?.slug,
                body: mdx?.node?.rawBody,
                metaTitle: mdx?.node?.frontmatter?.metaTitle,
                metaDescription: mdx?.node?.frontmatter?.metaDescription,
                title: mdx?.node?.frontmatter?.title,
              }
            });

          setAllMdxDocs([...docSearchItems]);
        }, [allMdx, docPath]);

        useEffect(() => {
          if (!allMdxDocs) {
            return;
          }
          rebuildIndex();
        }, [allMdxDocs]);

        const rebuildIndex = () => {

          const dataSearch = new JsSearch.Search('id');

          dataSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()

          dataSearch.sanitizer = new JsSearch.LowerCaseSanitizer()

          dataSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id")

          dataSearch.addIndex('title');
          dataSearch.addIndex('metaTitle');
          dataSearch.addIndex('metaDescription');
          dataSearch.addIndex('body');
          dataSearch.addDocuments(allMdxDocs);

          setSearch(dataSearch);
        };

        const searchData = e => {
          const queryResult = search.search(e.target.value);

          setSearchQuery(e.target.value);
          setSearchResults(queryResult);
        };

        return (
          <>
            <ModalWrapper>
              <Modal ref={wrapperRef}>
                <ModalSearchWrapper>
                  <span> 🔎 </span>
                  <input
                    type="text"
                    autoFocus={true}
                    value={searchQuery}
                    onChange={searchData}
                    placeholder="Search documentation"
                  />
                </ModalSearchWrapper>

                <Results>
                  {searchQuery !== '' ? (
                    searchResults && searchResults.length > 0 ? (
                      searchResults.map(res => (
                        <Link to={'/docs' + res?.slug}>
                          <ResultItem onClick={() => setSearchModalOpen(false)}>
                            <h6>{res?.title}</h6>
                            <p>{ res?.metaDescription}</p>
                          </ResultItem>
                        </Link>
                      ))
                    ) : (
                      <>
                        <p
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          No search results
                        </p>
                      </>
                    )
                  ) : allMdxDocs && allMdxDocs.length > 0 ? (
                    allMdxDocs.slice(0, 15).map(res => (
                      <Link to={'/docs' + res?.slug}>
                        <ResultItem onClick={() => setSearchModalOpen(false)}>
                          <h6>{res?.title}</h6>
                          <p>{res?.metaDescription}</p>
                        </ResultItem>
                      </Link>
                    ))
                  ) : (
                    <>
                    </>
                  )}
                </Results>
              </Modal>
            </ModalWrapper>
          </>
        );
      }}
    />
  );
};
