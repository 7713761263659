import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin: 10px;
  }
`;

const Container2 = styled('div')`
  border: 1px solid ${props => props.theme.codeBlocks.scrollbarThumbBackground};
  padding: 10px;
  & > * {
    margin: 10px;
  }
`;

export const ButtonGroupExample1 = () => {
  return (
    <Container>
      <div className="btn-group">
        <button type="button" className="btn btn-primary">
          Left
        </button>
        <button type="button" className="btn btn-primary">
          Middle
        </button>
        <button type="button" className="btn btn-primary">
          Right
        </button>
      </div>
    </Container>
  );
};

export const ButtonGroupExample2 = () => {
  return (
    <Container>
      <div className="btn-group">
        <button type="button" className="btn btn-primary btn-rounded">
          Left
        </button>
        <button type="button" className="btn btn-primary btn-rounded">
          Middle
        </button>
        <button type="button" className="btn btn-primary btn-rounded">
          Right
        </button>
      </div>
    </Container>
  );
};

export const ButtonGroupExample3 = () => {
  return (
    <Container>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-warning">
          Left
        </button>
        <button type="button" className="btn btn-success">
          Middle
        </button>
        <button type="button" className="btn btn-secondary">
          Right
        </button>
      </div>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-info">
          Left
        </button>
        <button type="button" className="btn btn-dark">
          Middle
        </button>
        <button type="button" className="btn btn-danger">
          Right
        </button>
      </div>
    </Container>
  );
};

export const ButtonGroupExample4 = () => {
  return (
    <Container>
      <div className="btn-group-vertical" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-primary">
          I am Vertical
        </button>
        <button type="button" className="btn btn-secondary">
          I am Vertical
        </button>
        <button type="button" className="btn btn-info">
          I am Vertical
        </button>
        <button type="button" className="btn btn-success">
          I am Vertical
        </button>
        <button type="button" className="btn btn-danger">
          I am Vertical
        </button>
        <button type="button" className="btn btn-warning">
          I am Vertical
        </button>
        <button type="button" className="btn btn-dark">
          I am Vertical
        </button>
      </div>
    </Container>
  );
};

export const ButtonGroupExample5 = () => {
  return (
    <Container2>
      <div className="btn-group btn-group-lg" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-dark">
          button
        </button>
        <button type="button" className="btn btn-dark">
          button
        </button>
        <button type="button" className="btn btn-dark">
          button
        </button>
      </div>

      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-warning">
          button
        </button>
        <button type="button" className="btn btn-warning">
          button
        </button>
        <button type="button" className="btn btn-warning">
          button
        </button>
      </div>

      <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-dark">
          button
        </button>
        <button type="button" className="btn btn-dark">
          button
        </button>
        <button type="button" className="btn btn-dark">
          button
        </button>
      </div>
    </Container2>
  );
};

export const ButtonGroupExample6 = () => {
  return (
    <Container2>
      <div className="mb-2 btn-group">
        <button type="button" className="btn m-0 btn-primary">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-secondary">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-success">
          Click me
        </button>
      </div>
      <div className="btn-group">
        <button type="button" className="btn m-0 btn-danger">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-dark">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-warning">
          Click me
        </button>
      </div>
    </Container2>
  );
};

export const ButtonGroupExample7 = () => {
  return (
    <Container>
      <div className="btn-group">
        <button type="button" className="btn btn-sm m-0 btn-primary">
          Click me
        </button>
        <button type="button" className="btn btn-sm m-0 btn-secondary">
          Click me
        </button>
        <button type="button" className="btn btn-sm m-0 btn-success">
          Click me
        </button>
        <button type="button" className="btn btn-sm m-0 btn-danger">
          Click me
        </button>
        <button type="button" className="btn btn-sm m-0 btn-dark">
          Click me
        </button>
        <button type="button" className="btn btn-sm m-0 btn-warning">
          Click me
        </button>
      </div>
    </Container>
  );
};
export const ButtonGroupExample8 = () => {
  return (
    <Container>
      <div className="container btn-group-vertical">
        <button type="button" className="btn btn-lg btn-primary">
          Click me
        </button>
        <button type="button" className="btn btn-lg btn-secondary">
          Click me
        </button>
        <button type="button" className="btn btn-lg btn-success">
          Click me
        </button>
        <button type="button" className="btn btn-lg btn-danger">
          Click me
        </button>
        <button type="button" className="btn btn-lg btn-dark">
          Click me
        </button>
        <button type="button" className="btn btn-lg btn-warning">
          Click me
        </button>
      </div>
    </Container>
  );
};

export const ButtonGroupExample9 = () => {
  return (
    <Container>
      <div className="container btn-group-vertical">
        <button type="button" className="btn btn-md btn-primary">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-secondary">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-success">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-danger">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-dark">
          Click me
        </button>
        <button type="button" className="btn btn-md btn-warning">
          Click me
        </button>
      </div>
    </Container>
  );
};
export const ButtonGroupExample10 = () => {
  return (
    <Container>
      <div className="container btn-group-vertical">
        <button type="button" className="btn btn-sm btn-primary">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-secondary">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-success">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-danger">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-dark">
          Click me
        </button>
        <button type="button" className="btn btn-sm btn-warning">
          Click me
        </button>
      </div>
    </Container>
  );
};
export const ButtonGroupExample11 = () => {
  return (
    <Container>
      <div className="container">
        <button type="button" className="btn m-0 btn-sm btn-primary">
          <i className="fab fa-twitter"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-secondary">
          <i className="fab fa-facebook-f"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-success">
          <i className="fab fa-reddit"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-danger">
          <i className="fab fa-instagram"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-dark">
          <i className="fab fa-imdb"></i>
        </button>
        <button type="button" className="btn m-0 btn-sm btn-warning">
          <i className="fab fa-stack-overflow"></i>
        </button>
      </div>
    </Container>
  );
};
export const ButtonGroupExample12 = () => {
  return (
    <Container>
      <div className="container d-flex">
        <button type="button" className="btn m-0 btn-md btn-primary">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-md btn-secondary">
          Click me
        </button>
        <button type="button" className="btn m-0 btn-md btn-success">
          Click me
        </button>
        <div className="dropdown">
          <button
            className="btn m-0 btn-warning text-dark dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <a className="dropdown-item" href="/">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/">
                Another action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/">
                Something else here
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};
