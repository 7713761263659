import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import Layout from '../components/tailwindLayout';
// import config from '../../config';
import { StyledHeading, StyledMainWrapper } from '../components/styles/Docs';
import RightSidebar from '../components/rightSidebarWindframe';
import Advert from '../components/Advert';


export default class MDXRuntimeTestTailwind extends Component {
  render() {
    const { data } = this.props;

    if (!data.mdx) {
      return null;
    }
    const {
      // allMdx,
      mdx,
    } = data;

    const metaTitle = mdx.frontmatter.metaTitle;
    const metaDescription = mdx.frontmatter.metaDescription;

    let location2 = {
      pathname: 'tailwindcss/classes' + mdx.fields.slug,
    };

    return (
      <div>
        {/* <Banner /> */}
        {/* <Ad /> */}
        <Layout location={this.props.location}>
          <Helmet>
            {metaTitle ? <title>{metaTitle}</title> : null}
            {metaTitle ? <meta name="title" content={metaTitle} /> : null}
            {metaDescription ? <meta name="description" content={metaDescription} /> : null}
            {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
            {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
            <meta property="og:site_name" content="Devwares" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@devwares" />
            <meta name="twitter:creator" content="@devwares" />
            {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
            {metaDescription ? (
              <meta property="twitter:description" content={metaDescription} />
            ) : null}
            <meta
              property="og:image"
              content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg"
            />
            <meta
              name="twitter:image"
              content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg"
            />
          </Helmet>
          <div style={{ display: 'flex' }}>
            <div className="main-doc">
              {/* <Notification /> */}
              {mdx.frontmatter.lastUpdated && < div className='pb-3 inline-flex items-center gap-1'><span className='font-semibold'>Last updated:</span> <span>{mdx.frontmatter.lastUpdated}</span></div>}
              <div className={'titleWrapper'}>
                <StyledHeading>{mdx.fields.title}</StyledHeading>
              </div>
              <StyledMainWrapper>
                {/* <a href="/product/contrast-pro" className="p-2">
                                  <img src={image} alt="ad-banner" />
                                </a> */}
                <MDXRenderer>{mdx.body}</MDXRenderer>
                <Advert packageName="tailwind" />
              </StyledMainWrapper>
            </div>
            <div className={'hiddenMobile'} style={{ width: '25%' }}>
              <RightSidebar location={this.props.location ? this.props.location : location2} />
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        githubUrl
        metaTitle
        lastUpdated
        metaDescription
      }
    }
  }
`;
